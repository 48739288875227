<template>
  <div v-if="institutions">
    <app-table-list>
      <table class="table-list text-center">
        <thead>
        <tr>
          <th>Tipo Estabelecimento</th>
          <th>Nome</th>
          <th>Tipo Número</th>
          <th>Número</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr class="text-center" v-for="(item, index) in institutions.data" :key="index">
          <td class="text-center"><span
            class="badge badge-dark">{{ InstitutionTypeEnum.props[item.institution_type_id].name }}</span>
          </td>
          <td class="text-center">{{ item.name }}</td>
          <td class="text-center"><span
            class="badge badge-dark">{{ NumberTypeEnum.props[item.number_type_id].name }}</span></td>
          <td class="text-center">{{ item.number }}</td>
          <td class="td-action">
            <div class="btn-action">
              <router-link :to="{name: 'settings-institution-edit', params:{id: item.id}}">
                <span class="icon-more_horiz"/>
              </router-link>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </app-table-list>
  </div>
</template>

<script>
import AppTableList from '/src/components/table-list'
import NumberTypeEnum from '/src/enums/number-type'
import InstitutionTypeEnum from '/src/enums/institution-type'

export default {
  props: ['institutions'],

  components: {
    AppTableList
  },

  data() {
    return {
      NumberTypeEnum: NumberTypeEnum,
      InstitutionTypeEnum: InstitutionTypeEnum
    }
  }
}
</script>
