<template>
  <div class="transaction-card mx-auto t-card2" @click="detailLink()">
    <div class="header">
      <div class="options">
        <router-link :to="{name: 'transactions-details', params:{origin:'strongbox', id: transaction.id}}"
                     class="btn-card btn-show">
          <span class="icon-keyboard_arrow_right" title="Detalhes"></span>
        </router-link>
      </div>
    </div>
    <div class="conciliation ml-auto">
      <app-conciliation-percentage :percents="transaction.conciliation_percent"
                                   class="ml-auto"></app-conciliation-percentage>
    </div>
    <div>
      <div class="dl-item-group ">
        <div class="dl-title">Valor</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Origem">valor</div>
          <div class="dl-col dl-col-val destaque">{{ transaction.value | money }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Tipo de valor">tipo</div>
          <div class="dl-col dl-col-val ">
            {{ CashTypeEnum.props[1].name }}
          </div>
        </div>
      </div>
      <div class="dl-item-group ">
        <div class="dl-title">Detalhes</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Data da ocorrência da transação">data de ocorrência</div>
          <div class="dl-col dl-col-val destaque">{{ transaction.date | formatDate }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Origem">origem</div>
          <div class="dl-col dl-col-val destaque">Cofre</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Código do cupom">código do cupom</div>
          <div class="dl-col dl-col-val">{{ transaction.coupon_code }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Tipo de evento">tipo de evento</div>
          <div class="dl-col dl-col-val">
            {{ TransactionTypeEnum.props[transaction.transactions_strongboxes_type].name }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Código da loja">origem loja cod.</div>
          <div class="dl-col dl-col-val" :title="transaction.institution_code">
            {{ transaction.institution_code }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Nome da loja">loja nome</div>
          <div class="dl-col dl-col-val" :title="transaction.institution_name">
            {{ transaction.institution_name }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Código do loja">cofre cod.</div>
          <div class="dl-col dl-col-val" :title="transaction.strongbox_code">
            {{ transaction.strongbox_code }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Nome da transportadora">trans. nome</div>
          <div class="dl-col dl-col-val" :title="transaction.transporter_institution_name">
            {{ transaction.transporter_institution_name }}
          </div>
        </div>
      </div>
      <!--<router-link :to="detailLink()" class="dl-more">mais</router-link>-->
      <div class="id" title="Identificador">#{{ transaction.id }}</div>
    </div>
  </div>
</template>

<script>
import AppConciliationPercentage from '/src/components/conciliation-percent'
import InstitutionTypeEnum from '/src/enums/institution-type'
import TransactionTypeEnum from '/src/enums/strongbox/type'
import CashTypeEnum from '/src/enums/cash-type'

export default {
  props: ['transaction'],

  components: {
    AppConciliationPercentage
  },

  data() {
    return {
      InstitutionTypeEnum: InstitutionTypeEnum,
      TransactionTypeEnum: TransactionTypeEnum,
      CashTypeEnum: CashTypeEnum,
    }
  },

  methods: {
    direction(transaction) {
      // todo: fazer uso do enumerador. 1-deposito 2-coleta
      return transaction.transactions_strongboxes_type === 1 ? 'down' : 'up'
    },
    directionClass(transaction) {
      return 'direction ' + this.direction(transaction)
    },
    directionArrowClass(transaction) {
      return 'icon-arrow-' + this.direction(transaction)
    },
    directionTitle(transaction) {
      let type = transaction.transactions_strongboxes_type;
      if (type === 1) {
        return 'Depósito'
      }
      if (type === 2) {
        return 'Coleta';
      }
      if (type === 3) {
        return 'Corte';
      }
      return null
    }
  }
}
</script>
