<template>
  <div>
    <div class="card5-item-header">
      <!--<div class="title"><span class="icon-domain"></span>Estabelecimento</div>-->
      <div class="subtitle">
        <span class="icon-domain"></span>
        {{ subtitle }}
      </div>
    </div>
    <div class="card5-item-body">
      <div class="row">
        <div class="item col-sm-6">
          <div class="title">Nome</div>
          <div class="value">
            <span class="badge badge-dark">{{ InstitutionTypeEnum.props[institution.institution_type_id].name }}</span>
            {{ printf(institution.name) }}
          </div>
        </div>
        <div class="item col-sm-6">
          <div class="title">Codigo</div>
          <div class="value">{{ printf(institution.code) }}</div>
        </div>
        <div class="item col-sm-6">
          <div class="title">Nº de Inscrição</div>
          <div class="value">
                        <span
                          class="badge badge-dark">{{ NumberTypeEnum.props[institution.number_type_id].name }}</span>
            {{ numberFormat(institution) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NumberTypeEnum from '/src/enums/number-type'
import InstitutionTypeEnum from '/src/enums/institution-type'

export default {
  props: ['institution', 'subtitle'],
  methods: {
    printf(value) {
      if (!value || '') return '-'
      return value
    },
    numberFormat(institution) {
      if (institution.number_type_id === 3) {
        return this.$options.filters.cnpj(institution.number)
      }
      return institution.number
    }
  },
  data() {
    return {
      NumberTypeEnum: NumberTypeEnum,
      InstitutionTypeEnum: InstitutionTypeEnum
    }
  }
}
</script>
