<template>
  <div class="filter-select">
    <div class="radio-groups">
      <div class="group">
        <div class="title">Identificador</div>
        <div class="value-box">
          <input class="form-control" type="text" v-model="form.id.value"/>
        </div>
      </div>
    </div>
    <div :class="{ 'radio-groups':true, 'group-opacity': form.id.value }">
      <div class="group">
        <div class="title">Valor</div>
        <div class="value-box">
          <app-input-money v-model="form.value.value" :show-nullable="true"></app-input-money>
        </div>
      </div>
      <div class="group">
        <div class="title">Código do Cupom</div>
        <div class="value-box">
          <input class="form-control" type="text" v-model="form.couponCode.value"/>
        </div>
      </div>
    </div>

    <div :class="{ 'radio-groups':true, 'group-opacity': form.id.value }">
      <div class="group">
        <div class="title">Ordenação</div>
        <div class="radios">
          <label class="container">Nenhuma
            <input type="radio" name="orderBy" value="empty" v-model="form.orderBy.field"
                   @click="changeOrder(null)">
            <span class="checkmark"></span>
          </label>
          <label class="container">Data
            <input type="radio" name="orderBy" value="date" v-model="form.orderBy.field"
                   @click="changeOrderDefault('desc')">
            <span class="checkmark"></span>
            <div class="sort-direction" v-if="form.orderBy.field==='date'">
              <div class="direction" :class="{'active':form.orderBy.direction === 'asc'}"
                   @click="changeOrder('asc')"><span
                class="icon-arrow-up2"></span></div>
              <div class="direction" :class="{'active':form.orderBy.direction === 'desc'}"
                   @click="changeOrder('desc')"><span
                class="icon-arrow-down2"></span></div>
            </div>
          </label>
          <label class="container">Valor
            <input type="radio" name="orderBy" value="value" v-model="form.orderBy.field"
                   @click="changeOrderDefault('asc')">
            <span class="checkmark"></span>
            <div class="sort-direction" v-if="form.orderBy.field==='value'">
              <div class="direction" :class="{'active':form.orderBy.direction === 'asc'}"
                   @click="changeOrder('asc')"><span
                class="icon-arrow-up2"></span></div>
              <div class="direction" :class="{'active':form.orderBy.direction === 'desc'}"
                   @click="changeOrder('desc')"><span
                class="icon-arrow-down2"></span></div>
            </div>
          </label>
        </div>
      </div>
      <div class="group">
        <div class="title">Conciliação com Origem</div>
        <div class="radios">
          <label class="container">Todos
            <input type="radio" name="conciliationOrigin" :value="null"
                   v-model="form.conciliationOrigin.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">Conciliado
            <input type="radio" name="conciliationOrigin" :value="1"
                   v-model="form.conciliationOrigin.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">Não Conciliado
            <input type="radio" name="conciliationOrigin" :value="0"
                   v-model="form.conciliationOrigin.value">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="group">
        <div class="title">Conciliação com Destino</div>
        <div class="radios">
          <label class="container">Todos
            <input type="radio" name="conciliationDestination" :value="null"
                   v-model="form.conciliationDestination.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">Conciliado
            <input type="radio" name="conciliationDestination" :value="1"
                   v-model="form.conciliationDestination.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">Não Conciliado
            <input type="radio" name="conciliationDestination" :value="0"
                   v-model="form.conciliationDestination.value">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="group">
        <div class="title">Data Ocorrência</div>
        <div class="radios">
          <label class="container">Todos
            <input type="radio" name="date" :value="null" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <label class="container">Ontem
            <input type="radio" name="date" value="yesterday" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <label class="container">Semana Passada
            <input type="radio" name="date" value="week" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <label class="container">Mês Passado
            <input type="radio" name="date" value="month" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <label class="container">Personalizado
            <input type="radio" name="date" value="custom" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <div class="custom-date" v-if="form.date.custom===true">
            <div>
              <span>De</span>
              <input type="date" class="input-text" v-model="form.customDate.from">
            </div>
            <div>
              <span>até</span>
              <input type="date" class="input-text" v-model="form.customDate.to">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import _ from 'lodash'
import filterSelectMixin from './mixins/filter-select'
import moment from 'moment'
import AppInputMoney from '/src/components/input-money/component'

export default {
  mixins: [
    filterSelectMixin
  ],
  components: {
    AppInputMoney
  },
  mounted() {
    this.loadState()
  },

  data() {
    return {
      form: {
        conciliationOrigin: {
          field: 'origin_is_conciliated',
          type: 'bool',
          operator: 'eq',
          value: null
        },
        conciliationDestination: {
          field: 'destination_is_conciliated',
          type: 'bool',
          operator: 'eq',
          value: null
        },
        destination: {
          field: 'destination_type_id',
          type: 'int',
          operator: 'in',
          value: []
        },
        transactionType: {
          field: 'transactions_strongboxes_type',
          type: 'int',
          operator: 'in',
          value: null
        },
        couponCode: {
          field: 'coupon_code',
          type: 'string',
          operator: 'eq',
          value: null
        },
        id: {
          field: 'id',
          type: 'string',
          operator: 'eq',
          value: null
        },
        date: {
          field: 'date',
          type: 'date',
          operator: 'bt',
          value: this.getDate('week'),
          custom: false,
          period: 'week'
        },
        customDate: {
          from: null,
          to: null
        },
        orderBy: {
          field: 'empty',
          direction: null
        },
        value: {
          field: 'value',
          type: 'float',
          operator: 'eq',
          value: null
        },
        strongboxCode: {
          field: 'strongbox_code',
          type: 'string',
          operator: 'eq',
          value: null
        }
      }
    }
  },

  computed: {
    ...mapState('filters', ['filterSelectInstitution'])
  },

  methods: {
    ...mapActions('filters', ['updateFilterSelectInstitution']),
    saveState() {
      let queryObject = {
        where: [],
        orderBy: []
      }
      if (!(this.form.id.value === null || this.form.id.value === '')) {
        queryObject.where.push(this.form.id)
        delete queryObject['orderBy']
      } else {
        // Destination Object
        if (this.form.destination.value.length > 0) {
          queryObject.where.push(this.form.destination)
        }
        // ConciliationOrigin Object
        if (this.form.conciliationOrigin.value != null) {
          queryObject.where.push(this.form.conciliationOrigin)
        }
        // conciliationDestination Object
        if (this.form.conciliationDestination.value != null) {
          queryObject.where.push(this.form.conciliationDestination)
        }
        // TransactionType Object
        if (this.form.transactionType.value != null) {
          queryObject.where.push(this.form.transactionType)
        }

        if (!(this.form.couponCode.value === null || this.form.couponCode.value === '')) {
          queryObject.where.push(this.form.couponCode)
        }

        // Date Object
        if (this.form.date.period !== null && this.form.date.period !== 'custom') {
          queryObject.where.push(this.form.date)
        } else if (this.form.date.custom === true && this.form.customDate.from && this.form.customDate.to) {
          let dateClone = _.clone(this.form.date, true)
          dateClone.value = []
          dateClone.value.push(this.form.customDate.from)
          dateClone.value.push(this.form.customDate.to)
          queryObject.where.push(dateClone)
        }
        // Value Object
        if (this.form.value.value != null && this.form.value.value) {
          queryObject.where.push(this.form.value)
        }
        // Sort/OrderBy Object
        queryObject.orderBy.push(this.form.orderBy)
        // Check if queryObject has 'where'
        if (queryObject.where.length < 1) {
          delete queryObject.where
        }

        // remove orderBy if empty
        if (queryObject.orderBy[0] !== undefined && queryObject.orderBy[0].field === 'empty') {
          delete queryObject['orderBy']
        }
      }
      // Save the filter on state
      this.updateFilterSelectInstitution(queryObject)
    },
    loadState() {
      if (this.filterSelectInstitution.where && this.filterSelectInstitution.where.length > 0) {
        let newForm = _.cloneDeep(this.form)
        for (let item of this.filterSelectInstitution.where) {
          switch (item.field) {
            case 'origin_is_conciliated':
              newForm.conciliationOrigin.value = item.value
              break
            case 'destination_is_conciliated':
              newForm.conciliationDestination.value = item.value
              break
            case 'destination_type_id':
              newForm.destination.value = item.value
              break
            case 'transactions_strongboxes_type':
              newForm.transactionType.value = item.value
              break
            case 'value':
              newForm.value.value = item.value
              break
            case 'date':
              if (item.custom) {
                newForm.date = item
                newForm.customDate.from = moment(item.value[0]).format('YYYY-MM-DD')
                newForm.customDate.to = moment(item.value[1]).format('YYYY-MM-DD')
              } else {
                newForm.date = item
              }
              break
          }
        }
        this.form = newForm
      }
      if (this.filterSelectInstitution.orderBy && this.filterSelectInstitution.orderBy.length > 0) {
        // Load Order
        this.form.orderBy = this.filterSelectInstitution.orderBy[0]
      }

      this.loadRouteFilter()
    }
  }
}
</script>
