import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import transactions from '/src/features/transactions/store'
import settingsRegister from '/src/features/settings/components/register/store'
import filters from '/src/features/transactions/components/list-components/filter-components/store'

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: false,
  modules: {
    transactions,
    settingsRegister,
    filters,
  }
})
