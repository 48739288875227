import moment from 'moment'

export default {
  methods: {
    changeOrderDefault(newOrder) {
      if (this.form.orderBy.direction === null) {
        this.form.orderBy.direction = newOrder
      }
    },
    changeOrder(newOrder) {
      this.form.orderBy.direction = newOrder
    },
    getDate(period) {
      let formatDate = 'YYYY-MM-DD'
      switch (period) {
        case 'today': {
          let today = moment().format(formatDate)
          return [today, today]
        }
        case 'yesterday': {
          let yesterday = moment().subtract(1, 'days').format(formatDate)
          return [yesterday, yesterday]
        }
        case 'week': {
          let weekInit = moment().subtract(1, 'week').startOf('week').format(formatDate)
          let weekFinal = moment().subtract(1, 'week').endOf('week').format(formatDate)
          return [weekInit, weekFinal]
        }
        case 'month': {
          let monthInit = moment().subtract(1, 'month').startOf('month').format(formatDate)
          let monthFinal = moment().subtract(1, 'month').endOf('month').format(formatDate)
          return [monthInit, monthFinal]
        }
      }
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.saveState()
    },
    loadRouteFilter() {
      // console.log('loadRouteFilter')
      if (this.$route.query.filter) {
        // console.log(this.$route.query.filter)
        let filters = this.$route.query.filter
        // console.log('filter-select:filters', JSON.parse(JSON.stringify(this.$route.query.filter)))
        for (let filter in filters) {
          this.form[filter] = filters[filter]
        }

        // console.log('filter-select:form', JSON.parse(JSON.stringify(this.form)))

        this.saveState()
      }
    }
    // convertDate
  },

  // TODO: verificar essa questao de assistir a propriedade 2x
  watch: {
    'form.date.period': {
      handler: function (period) {
        this.form.date.custom = false
        this.form.date.value = this.getDate(this.form.date.period)
        if (period === null) this.form.date.value = null
        else if (period === 'custom') this.form.date.custom = true
      },
      deep: false
    },
    'form': {
      handler: function (v1, v2) {
        console.log('watch.form.handler', JSON.parse(JSON.stringify(v1)), JSON.parse(JSON.stringify(v2)))
        this.saveState()
      },
      deep: true
    }
  }
}
