import Index from './components/Index'
import ErpMoneyBank from './components/ErpMoneyBank'
import ErpPixBank from './components/ErpPixBank'
import ErpBankCheckPredatedInstitution from './components/ErpBankCheckPredatedInstitution'
import ErpBankCheckBank from './components/ErpBankCheckBank'
import ErpMoneyStrongbox from './components/ErpMoneyStrongbox'
import UserModified from './components/UserModified';
import ConciliationManual from './components/ConciliationManual'

export default [
  {
    path: '/visions',
    name: 'visions-index',
    component: Index
  },
  {
    path: '/visions/erp/money/bank',
    name: 'visions-erp-money-bank',
    component: ErpMoneyBank
  },
  {
    path: '/visions/erp/pix/bank',
    name: 'visions-erp-pix-bank',
    component: ErpPixBank
  },
  {
    path: '/visions/erp/bank-check/bank',
    name: 'visions-erp-bank-check-bank',
    component: ErpBankCheckBank
  },
  {
    path: '/visions/erp/bank-check-predated/institution',
    name: 'visions-erp-bank-check-predated-institution',
    component: ErpBankCheckPredatedInstitution
  },
  {
    path: '/visions/erp/money/strongbox',
    name: 'visions-erp-money-strongbox',
    component: ErpMoneyStrongbox
  },
  {
    path: '/visions/control/user-modified',
    name: 'visions-user-modified',
    component: UserModified
  },
  {
    path: '/conciliations/manual',
    name: 'visions-conciliations-manual',
    component: ConciliationManual
  }
]
