<template>
  <div class="settings-others">
    <section>
      <div class="title">Visualização de Transações</div>
      <div class="form-group">
        <div class="group">
          <label class="mx-auto">Modo {{ viewMode }}</label>
          <app-toggle v-model="form.viewMode"></app-toggle>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AppToggle from '/src/components/toggle'
import Settings from '/src/utils/settings'

export default {
  components: {
    AppToggle
  },

  created() {
    this.settings = Settings.getSettings()
    this.form.viewMode = this.settings.general.transactions.viewMode
  },

  data: function () {
    return {
      settings: null,
      form: {
        viewMode: true
      }
    }
  },

  computed: {
    viewMode: function () {
      if (this.form.viewMode) return 'Cartões'
      return 'Tabela'
    }
  },

  watch: {
    'form': {
      handler: function () {
        this.settings.general.transactions = this.form
        Settings.setSettings(this.settings)
      },
      deep: true
    }
  }

}
</script>
