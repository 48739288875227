<template>
  <div class="settings-others">
    <section v-bind="pagination = form.pagination">
      <div class="title">Paginação</div>
      <div class="form-group">
        <div class="group">
          <label class="mx-auto">Itens por página</label>
          <input v-model="pagination.limit" class="page-items form-control mx-auto" type="number" value="0">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Settings from '/src/utils/settings'

export default {
  created() {
    this.settings = Settings.getSettings()
    this.form.pagination = this.settings.general.others.pagination
  },

  data: function () {
    return {
      settings: null,
      form: {
        pagination: {
          limit: null,
        }
      }
    }
  },

  computed: {
    transactionVision: function () {
      if (this.form.transactionVision) return 'Cartões'
      return 'Tabela'
    }
  },

  watch: {
    'form': {
      handler: function () {
        this.settings.general.others = this.form
        Settings.setSettings(this.settings)
      },
      deep: true
    }
  }

}
</script>
