<template>
  <app-layout-site>
    <app-loader :loading="loading">
      <div class="card4">
        <div class="container">
          <div class="transacao-header">
            <div class="back">
              <a href="" @click.prevent="back()">
                <span class="icon-keyboard_arrow_left"></span>
              </a>
            </div>
            <div v-if="response" class="items">
              <div class="item1">
                <div class="title">Transação</div>
                <div class="subtitle">#{{ response.id }}</div>
              </div>
              <div class="item2">
                <div class="title">R$ {{ response.value | money }}</div>
                <div class="subtitle">Efetuada em <strong>{{ response.date | formatDate }}</strong> às
                  <strong>{{ response.date | formatTime }}</strong></div>
              </div>
              <div class="item3">
                <div class="title">{{ TransactionTypeEnum.props[response.transaction_type_id].name }}
                </div>
                <div class="subtitle">Origem</div>
              </div>
            </div>
            <div v-if="response && this.$route.params.origin === 'erp'">
              <div class="actions">
                <div @click="editLink()" class="btn-icon" title="editar">
                  <span class="icon-mode_edit"></span>
                </div>
                <div @click="onChargeback()" class="btn-icon" title="marcar como estornado">
                  <span class="icon-grain"></span>
                </div>
                <div @click="onDelete()" class="btn-icon" title="excluir">
                  <span class="icon-delete"></span>
                </div>

                <div @click="onSearchBank()" class="btn-icon" title="procurar no banco">
                  <span class="icon-search"></span>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="container">
        <div v-if="showConciliationForce">
          <div class="alert alert-warning">
            Você deseja forçar a conciliação com a transação do ERP
            <router-link
              :to="{name: 'transactions-details', params:{ origin:'erp', id: ctid}}"
              class="btn-card btn-show">{{ this.ctid }}
            </router-link>
            ?
            <div @click="onConciliationForceCancel()" class="btn btn-sm btn-link">Cancelar</div>
            <div @click="onConciliationForce(ctid, response.id)" class="btn btn-sm btn-primary">Aplicar</div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <app-details-strongbox
            v-if="response && isOrigin('strongbox')"
            :transaction="response"
          />
          <app-details-bank
            v-if="response && isOrigin('bank')"
            :transaction="response"
          />
          <app-details-erp
            v-if="response && isOrigin('erp')"
            :transaction="response"
          />
          <app-details-institution
            v-if="response && isOrigin('institution')"
            :transaction="response"
          />
        </div>
      </div>
    </app-loader>
  </app-layout-site>
</template>

<script>
import AppLoader from '/src/components/loader'
import AppLayoutSite from '/src/components/layouts/site'
// import AppTransactionTimeline from '/src/features/transactions/partials/timeline'
import AppDetailsStrongbox from '/src/features/transactions/components/details-components/transaction-strongbox'
import AppDetailsInstitution from '/src/features/transactions/components/details-components/transaction-institution'
import AppDetailsBank from '/src/features/transactions/components/details-components/transaction-bank'
import AppDetailsErp from '/src/features/transactions/components/details-components/transaction-erp'
import DetailsProvider from '/src/features/transactions/providers/transaction-details'
import TransactionTypeEnum from '/src/enums/transaction-type'
import {mapActions} from 'vuex'
// import DestinationTypeEnum from "../../../enums/destinations-type";
// import CashTypeEnum from "../../../enums/cash-type";
import moment from "moment/moment";
// import _ from 'lodash'

export default {
  name: 'transacao-list',

  components: {
    AppLoader,
    AppLayoutSite,
    // AppTransactionTimeline,
    AppDetailsStrongbox,
    AppDetailsInstitution,
    AppDetailsBank,
    AppDetailsErp
  },

  mounted() {
    this.responseParam()
    if (this.$route.query.ctid !== undefined) {
      this.ctid = parseInt(this.$route.query.ctid)
    }


  },
  data() {
    return {
      showConciliationForce: false,
      ctid: null,
      loading: false,
      response: null,
      TransactionTypeEnum: TransactionTypeEnum
    }
  },
  methods: {
    ...mapActions('transactions', ['clearErp']),
    // currentPath() {
    //   const items = [
    //     {
    //       origin: 'strongbox',
    //       path: '/transactions/strongbox',
    //     },
    //     {
    //       origin: 'bank',
    //       path: '/transactions/bank',
    //     },
    //     {
    //       origin: 'institution',
    //       path: '/transactions/institution',
    //     },
    //     {
    //       origin: 'erp',
    //       path: '/transactions/erp',
    //     },
    //   ]
    //
    //   let origin = this.$route.params.origin
    //   let result = _.find(items, function (item) {
    //     return item.origin === origin;
    //   })
    //
    //   return result === undefined ? null : result.path;
    // },
    isOrigin(value) {
      return this.$route.params.origin === value;
    },
    back() {
      let origin = this.$route.params.origin;
      this.$router.push({name: `transactions-${origin}-index`});
    },
    responseParam() {
      let self = this
      this.loading = true

      switch (this.$route.params.origin) {
        case 'strongbox':
          DetailsProvider.getTransactionStrongbox(this.$route.params.id)
            .then((response) => {
              self.response = response.data
            })
            .finally(() => {
              this.loading = false
            })
          break
        case 'bank':
          DetailsProvider.getTransactionBank(this.$route.params.id)
            .then((response) => {
              self.response = response.data

              this.showConciliationForce = this.isConciliationForce()
            })
            .finally(() => {
              this.loading = false
            })
          break
        case 'institution':
          DetailsProvider.getTransactionInstitution(this.$route.params.id)
            .then((response) => {
              self.response = response.data
            })
            .finally(() => {
              this.loading = false
            })
          break
        case 'erp':
          DetailsProvider.getTransactionErp(this.$route.params.id)
            .then((response) => {
              self.response = response.data
            })
            .finally(() => {
              this.loading = false
            })
          break
      }
    },
    editLink() {
      this.$router.push({name: 'transactions-erp-edit', params: {id: this.response.id}})
    },
    onSearchBank() {
      const customDateFormatted = moment(this.response.date).format('YYYY-MM-DD')
      const couponCode = this.response.transaction_erp.data.coupon_code
      const valueX = this.response.value
      const bankAccountId = this.response.transaction_erp.data.bank_account_id

      let filter = {
        couponCode: {
          field: 'coupon_code',
          type: 'string',
          operator: 'eq',
          value: couponCode
        },
        date: {
          field: 'date',
          type: 'date',
          operator: 'bt',
          value: null,
          custom: true,
          period: 'custom'
        },
        value: { //bug
          field: 'value',
          type: 'float',
          operator: 'eq',
          value: valueX,
        },
        customDate: {
          from: customDateFormatted,
          to: customDateFormatted
        },
        bankAccount: {
          field: 'bank_account_id',
          type: 'int',
          operator: 'in',
          value: [bankAccountId]
        },
        conciliation: {
          field: 'origin_is_conciliated',
          type: 'bool',
          operator: 'eq',
          value: 0
        },
      }

      this.$router.push({
        name: 'transactions-bank-index',
        query: {
          ctid: this.response.id,
          filter,
        }
      })
    },

    onConciliationForceCancel() {
      this.showConciliationForce = false;
    },

    isConciliationForce() {
      return this.response
        && this.response.conciliation_percent.origin.length === 0
        && this.$route.params.origin === 'bank'
        && this.ctid !== null
    },

    async onConciliationForce(origin_id, destination_id) {
      console.log('force conciliation: ', origin_id, destination_id)

      const self = this
      self.loading = true;
      const payload = {
        relationship: '1:1-2',
        origin: origin_id,
        destination: destination_id,
      }

      try {
        await DetailsProvider.conciliationManual(payload)
      } finally {
        self.loading = false;
      }

      window.location.reload()
      // this.$router.push({name: 'transactions-details', params: {origin: 'bank', id: destination_id}})

    },
    onDelete() {
      let self = this

      this.$swal({
        title: 'Você tem certeza?',
        text: 'Uma vez deletado, essa transação também será desvinculada com qualquer conciliação já realizada.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, deletar isto!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (!result.value) {
          return
        }

        DetailsProvider.softTransactionErpDelete(self.$route.params.id)
          .then(() => {
            this.$swal({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 1500,
              type: 'success',
              title: 'Deletado',
              text: ''
            });

            self.clearErp()
            self.$router.push({name: 'transactions-erp-index'})
          })
          .catch(() => {
            this.$swal({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 1500,
              type: 'error',
              title: 'Ops!',
              text: 'algo inesperado aconteceu'
            });
          })
      });
    },
    onChargeback() {
      let self = this

      this.$swal({
        title: 'Você tem certeza?',
        text: 'Uma vez marcado como estornado, essa transação será desvinculada com qualquer conciliação já realizada.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, estornar isto!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (!result.value) {
          return
        }

        DetailsProvider.updateTransactionErpChargeback(self.$route.params.id)
          .then(() => {
            this.$swal({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 1500,
              type: 'success',
              title: 'Estornado',
              text: ''
            });

            self.clearErp()
            self.$router.push({name: 'transactions-erp-index'})
          })
          .catch(() => {

            this.$swal({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 1500,
              type: 'error',
              title: 'Ops!',
              text: 'algo inesperado aconteceu'
            });
          })
      });
    }
  }
}
</script>
