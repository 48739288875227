export default {
  SET_FILTER_BY(state, payload) {
    state.filterBy = payload
  },
  SET_FILTER_SELECT_ERP(state, payload) {
    state.filterSelectErp = payload
  },
  SET_FILTER_SELECT_STRONGBOX(state, payload) {
    state.filterSelectStrongbox = payload
  },
  SET_FILTER_SELECT_BANK(state, payload) {
    state.filterSelectBank = payload
  },
  SET_FILTER_SELECT_INSTITUTION(state, payload) {
    state.filterSelectInstitution = payload
  },
  SET_FILTER_INSTITUTIONS(state, payload) {
    state.institutions = payload
  }
}
