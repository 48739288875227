import banks from '/src/enums/bank/bank-list'
import _ from 'lodash'

const items = {
  1: 'banco-do-brasil-official',
  33: 'santander',
  41: 'banrisul',
  104: 'caixa',
  // 133: 'cresol',
  237: 'bradesco',
  341: 'itau',
  // 399: 'hsbc',
  // 422: 'safra',
  // 707: 'daycoval',
  748: 'sicredi',
  756: 'sicoob',
}

const bankIcon = (bankCode) => {
  let item = items[bankCode]

  if (item === undefined) {
    item = 'outro-banco'
  }

  return require('@/assets/banks/' + item + '.svg')
}

const getName = (bankCode) => {
  let item = _.find(banks, (e) => e.id === bankCode)
  return item !== undefined ? item.name : 'Indefinido'
}

export default {
  bankIcon,
  getName
}
