<template>
  <div v-if="items.length > 0">
    <div class="grid">
      <div v-for="(item,index) of items" :key="index" class="inst-block-container"
           :title="item.institution_name">
        <div class="inst-block"
             :class="getStatus(item)"
             @click="$emit('itemClick', item)"
        ></div>
      </div>
    </div>
    <div class="legend">
      <div>
        <span class="legend-item ok"></span>
        100% das transações do dia estão conciliadas
      </div>
      <div>
        <span class="legend-item warning"></span>
        entre 1% a 99% das transações do dia estão conciliadas
      </div>
      <div>
        <span class="legend-item error"></span>
        0% das transações do dia estão conciliadas
      </div>
      <div>
        <span class="legend-item empty"></span>
        Nenhuma transação registrada nesse dia
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'app-grid-conciliated',
  props: ['items'],
  methods: {
    getStatus(item) {
      if (item.transactions.count > 0) {
        if (item.transactions.count === item.conciliations.count) {
          return 'status-ok'
        } else if ((this.conciliationPercent(item) * 100) > 0) {
          return 'status-warning'
        } else {
          return 'status-error'
        }
      } else {
        return 'status-empty'
      }
    },
    conciliationPercent(item) {
      let conciliated = item.conciliations.count
      let total = item.transactions.count

      if (total === 0) {
        return 0
      }

      let result = conciliated / total
      return parseFloat(result)
    },

  },
}
</script>
