<script>
/**
 * @see https://github.com/chronotruck/vue-ctk-date-time-picker
 */
import VueCtkDatetimePicker from 'vue-ctk-date-time-picker'

export default {
  name: 'AppDateTimePicker',
  extends: VueCtkDatetimePicker,
  props: {
    buttonNowTranslation: {
      type: String,
      default: 'Agora'
    },
    label: {
      type: String,
      default: 'Selecione uma data e hora'
    },
  }
}
</script>
