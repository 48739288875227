<template lang="html">
  <div>
    <div class="input-group mb-3">
      <div class="input-group-prepend" v-if="showNullable">
        <div class="input-group-text input-group-text-toggle">
          <!--<input type="checkbox" v-model="inputNullable" aria-label="Checkbox for following text input">-->
          <app-toggle v-model="inputNullable" :sync="true"></app-toggle>
        </div>
      </div>
      <input type="tel"
             :value="formattedValue"
             @change="change"
             v-money="{precision, decimal, thousands, prefix, suffix}"
             class="form-control" :disabled="disabled()"
             aria-label="Text input with checkbox"/>
    </div>
  </div>
</template>

<script>
import AppToggle from '/src/components/toggle'
import money from './directive'
import defaults from './options'
import {format, unformat} from './utils'

export default {
  name: 'InputMoney',
  components: {
    AppToggle
  },
  props: {
    showNullable: {
      type: Boolean,
      default: false
    },
    value: {
      required: false,
      type: [Number, String],
      default: 0
    },
    masked: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: () => defaults.precision
    },
    decimal: {
      type: String,
      default: () => defaults.decimal
    },
    thousands: {
      type: String,
      default: () => defaults.thousands
    },
    prefix: {
      type: String,
      default: () => defaults.prefix
    },
    suffix: {
      type: String,
      default: () => defaults.suffix
    },
    // inputNullable: {
    //   type: Boolean,
    //   default: false
    // },
  },
  directives: {money},
  data() {
    return {
      formattedValue: 0,
      inputNullable: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        // console.log('handler watch input money: ' + newValue)

        let formatted = format(newValue, this.$props)
        if (formatted !== this.formattedValue) {
          this.formattedValue = formatted
        }

        // console.log('formattedValue: ' + formatted)
      }
    }
  },
  methods: {
    change(evt) {
      // console.log('change evt: ' + evt.target.value)
      let result = this.masked ? evt.target.value : unformat(evt.target.value, this.precision)
      // console.log('change result 1: ' + result)

      if (result !== 0) {
        // console.log('input nullable = true')
        this.inputNullable = true;
      }

      if (this.showNullable === true) {
        result = this.inputNullable === false ? null : result
      }

      // console.log('change result 2: ' + result)

      this.$emit('input', result)
    },
    disabled() {
      if (this.showNullable === false) {
        return false
      }

      return !this.inputNullable
    }
  }
}
</script>
