<template>
  <app-layout-site>
    <div class="card5">
      <app-loader :loading="loading">
        <h1>Upload</h1>

        <div class="container">
          <div class="card-form">
            <div class="header-destination-container">
              <form @submit.prevent="onUpload">
                <div class="form-group">
                  <select class="form-control" v-model="originType">
                    <option :value="2">Extrato Depósito - Caixa Economica</option>
                    <option :value="3">Extrato Pix - Santander</option>
                  </select>
                </div>

                <div class="form-group">
                  <input type="file" class="form-control" :key="fileInputKey" multiple @change="onChange">
                </div>

                <div class="form-group">
                  <button class="btn btn-primary btn-block">enviar</button>
                </div>
              </form>

              <div v-if="showMessage">
                <div class="alert alert-warning">Arquivos enviados</div>
              </div>
            </div>
          </div>
        </div>
      </app-loader>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'
import UploadProvider from '../providers/upload'
import AppLoader from '/src/components/loader'

export default {
  name: 'upload-index',
  components: {
    AppLayoutSite,
    AppLoader,
  },
  data() {
    return {
      loading: false,
      files: null,
      inputFile: null,
      originType: null,
      showMessage: false,
      fileInputKey: 0,
    }
  },
  methods: {
    onChange(event) {
      this.files = event.target.files
    },
    fileInputClear() {
      this.fileInputKey++;
    },
    async onUpload() {
      this.showMessage = false
      this.loading = true

      const formData = new FormData();
      formData.set('origin_type', this.originType)

      for (const i of Object.keys(this.files)) {
        formData.append('files[]', this.files[i])
      }

      try {
        await UploadProvider.multipleUpload(formData)
        this.showMessage = true

        this.fileInputClear()

      } catch (err) {
        this.loading = false
      } finally {
        this.loading = false
      }


    }
  }
}
</script>
