import Vue from 'vue'
import VueRouter from 'vue-router'

import {routes as auth} from '/src/features/auth'
import {routes as bankCheck} from '/src/features/bankCheck'
import {routes as dashboard} from '/src/features/dashboard'
import {routes as dashboard2} from '/src/features/dashboard2'
import {routes as transactions} from '/src/features/transactions'
import {routes as settings} from '/src/features/settings'
import {routes as settings2} from '/src/features/settings2'
import {routes as uploads} from '/src/features/uploads'
import {routes as reports} from '/src/features/reports'
import {routes as visions} from '/src/features/visions'
import {routes as transactionsPix} from '/src/features/transactions-pix'

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {path: '/', redirect: '/login'},
    ...auth,
    ...dashboard,
    ...dashboard2,
    ...bankCheck,
    ...transactions,
    ...settings,
    ...settings2,
    ...reports,
    ...visions,
    ...uploads,
    ...transactionsPix,
  ]
})
