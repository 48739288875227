<template>
  <div class="transaction-card t-card2 mx-auto" :class="{'selected':this.selected}">
    <div class="header">
      <div class="options">
        <router-link :to="{name: 'transactions-erp-edit', params: {id: transaction.id}}"
                     class="btn-card btn-edit">
          <span class="icon-mode_edit" title="Editar"></span>
        </router-link>
        <router-link :to="{name: 'transactions-details', params: {origin: 'erp', id: this.transaction.id}}"
                     class="btn-card btn-show">
          <span class="icon-keyboard_arrow_right" title="Detalhes"></span>
        </router-link>
      </div>
    </div>
    <div class="conciliation ml-auto">
      <app-conciliation-percentage :percents="transaction.conciliation_percent"
                                   class="ml-auto">
      </app-conciliation-percentage>
    </div>
    <div class="body">
      <div class="dl-item-group ">
        <div class="dl-title">Valor</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Origem">valor</div>
          <div class="dl-col dl-col-val destaque">{{ transaction.value | money }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Tipo de valor">tipo</div>
          <div class="dl-col dl-col-val ">
            {{ CashTypeEnum.props[transaction.cash_type_id].name }}
          </div>
        </div>

        <div v-if="transaction.cash_type_id === 3">
          <div class="dl-item">
            <div class="dl-col dl-col-field">chq. numero</div>
            <div class="dl-col dl-col-val" title="Número do cheque">
              {{ transaction.check_predated_number }}
            </div>
          </div>
          <div class="dl-item">
            <div class="dl-col dl-col-field">chq. cliente</div>
            <div class="dl-col dl-col-val" title="Cliente responsável pelo cheque">
              {{ transaction.check_predated_client }}
            </div>
          </div>
          <div class="dl-item">
            <div class="dl-col dl-col-field">chq. status</div>
            <div class="dl-col dl-col-val" title="Status do cheque">
              {{ transaction.check_predated_status }}
            </div>
          </div>
          <div class="dl-item">
            <div class="dl-col dl-col-field">chq. dt. venc.</div>
            <div class="dl-col dl-col-val" title="Data vencimento do cheque">
              {{ transaction.check_predated_due_date | formatDate }}
            </div>
          </div>
          <div class="dl-item">
            <div class="dl-col dl-col-field">chq. dt. emissão.</div>
            <div class="dl-col dl-col-val" title="Data de emissão do cheque">
              {{ transaction.check_predated_issue_date | formatDate }}
            </div>
          </div>
        </div>
      </div>
      <div class="dl-item-group">
        <div class="dl-title">Detalhes</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Data da ocorrência da transação">data de ocorrência</div>
          <div class="dl-col dl-col-val destaque">{{ transaction.date | formatDate }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Data da criação no sistema de origem">data de criação</div>
          <div class="dl-col dl-col-val">{{ transaction.processed_at | formatDate }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field"
               title="Diferença entre data da ocorrência e data de criação no sistema de origem">tempo de criação
          </div>
          <div class="dl-col dl-col-val">{{ transaction.time_created }} dia(s)</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Origem">origem</div>
          <div class="dl-col dl-col-val destaque">ERP</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">destino</div>
          <div class="dl-col dl-col-val">
            {{ DestinationTypeEnum.props[transaction.destination_type_id].name }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Código do cupom">código do cupom</div>
          <div class="dl-col dl-col-val">{{ transaction.coupon_code }}</div>
        </div>
      </div>
      <div class="dl-item-group" v-if="isDestinationInstitution()">
        <div class="dl-title">Instituição - Origem</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Código da loja">tipo</div>
          <div class="dl-col dl-col-val">
            {{ InstitutionTypeEnum.props[transaction.institution_origin_type_id].name }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Código da loja">código</div>
          <div class="dl-col dl-col-val">
            {{ transaction.institution_origin_code }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Código da loja">nome</div>
          <div class="dl-col dl-col-val">{{ transaction.institution_origin_name }}</div>
        </div>
      </div>


      <div class="dl-item-group" v-if="isDestinationBank()">
        <div class="dl-title">Banco - Destino</div>
        <div class="dl-item" v-if="isDestinationBank()">
          <div class="dl-col dl-col-field">nome</div>
          <div class="dl-col dl-col-val" :title="transaction.bank_name">
            <div class="bank-icon">
              <img :src="BankIconUtil.bankIcon(transaction.bank_code)">
            </div>
            <span>
             {{ transaction.bank_name }}
            </span>
            <span>
              - {{ transaction.bank_code | pad(3) }}
            </span>
          </div>
        </div>

        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Banco - Agência">agência</div>
          <div class="dl-col dl-col-val" :title="bankAgency(transaction)">{{ bankAgency(transaction) }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Banco - Conta Corrente">conta corrente</div>
          <div class="dl-col dl-col-val" :title="bankAccount(transaction)">{{ bankAccount(transaction) }}
          </div>
        </div>
      </div>
      <div class="dl-item-group" v-if="isDestinationStrongbox()">
        <div class="dl-title">Cofre - Destino</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">código</div>
          <div class="dl-col dl-col-val">{{ transaction.strongbox_code }}</div>
        </div>
      </div>
      <div class="dl-item-group" v-if="isDestinationInstitution()">
        <div class="dl-title">Instituição - Destino</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">tipo</div>
          <div class="dl-col dl-col-val">
            {{ InstitutionTypeEnum.props[transaction.institution_type_id].name }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">código.</div>
          <div class="dl-col dl-col-val">{{ transaction.institution_code }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">nome</div>
          <div class="dl-col dl-col-val">{{ transaction.institution_name }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">numero</div>
          <div class="dl-col dl-col-val">{{ transaction.institution_number }}</div>
        </div>
      </div>
      <!--<router-link :to="detailLink()" class="dl-more">mais</router-link>-->
    </div>
    <div class="id" title="Identificador">#{{ transaction.id }}</div>
  </div>
</template>

<script>
import CashTypeEnum from '/src/enums/cash-type'
import DestinationTypeEnum from '/src/enums/destinations-type'
import InstitutionTypeEnum from '/src/enums/institution-type'
import AppConciliationPercentage from '/src/components/conciliation-percent'
import BankIconUtil from '/src/utils/icon-banks'

export default {
  props: ['transaction', 'select'],

  components: {
    AppConciliationPercentage
  },

  data() {
    return {
      selected: false,
      CashTypeEnum: CashTypeEnum,
      DestinationTypeEnum: DestinationTypeEnum,
      InstitutionTypeEnum: InstitutionTypeEnum,
      BankIconUtil: BankIconUtil
    }
  },

  methods: {

    bankAccount(transaction) {
      return transaction.bank_account + (transaction.bank_account_check ? '-' + transaction.bank_account_check : '')
    },
    bankAgency(transaction) {
      return transaction.bank_agency + (transaction.bank_agency_check ? '-' + transaction.bank_agency_check : '')
    },

    isDestinationBank() {
      return this.transaction.destination_type_id === 1
    },
    isDestinationStrongbox() {
      return this.transaction.destination_type_id === 2
    },
    isDestinationInstitution() {
      return this.transaction.destination_type_id === 3 && this.transaction.institution_type_id !== null
    }
  }
}
</script>
