import view from './page/setting-page.vue'
import users from "./components/users.vue";
import general from "./components/general.vue";
import companies from "./components/companies.vue";
import stores from "./components/stores.vue";
import groups from "./components/groups.vue";
import bankAccounts from "./components/bank-accounts.vue";

export default [
  {
    path: '/settings2',
    name: 'settings2',
    component: view,
    children: [
      {
        path: 'general',
        name: 'settings2-general',
        component: general
      },
      {
        path: 'users',
        name: 'settings2-users',
        component: users
      },

      {
        path: 'groups',
        name: 'settings2-groups',
        component: groups
      },

      {
        path: 'companies',
        name: 'settings2-companies',
        component: companies
      },
      {
        path: 'stores',
        name: 'settings2-stores',
        component: stores
      },

      {
        path: 'bank-accounts',
        name: 'settings2-bank-accounts',
        component: bankAccounts
      },
    ]
  }
]
