import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/pt-br'

Vue.filter('moment', (value, format) => {
  if (!value) {
    return value;
  }
  return moment(value).format(format)
})

Vue.filter('formatDate', (value) => {
  if (!value) {
    return value;
  }
  return moment(String(value)).format('DD/MM/YYYY')
})

Vue.filter('formatDateTime', (value) => {
  if (!value) {
    return value;
  }
  return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
})

Vue.filter('formatTime', (value) => {
  if (!value) {
    return value;
  }
  return moment(String(value)).format('HH:mm:ss')
})
