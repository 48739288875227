<template>
  <div class="col-md-12" v-if="transaction != null">
    <div class="card5" v-bind="transactionErp = transaction.transaction_erp.data">
      <app-detail-transaction :transaction="transaction"></app-detail-transaction>
      <section>
        <div class="card5-item-header">
          <!--<div class="title"><span class="icon-signpost"></span>Detalhes da Transação</div>-->
          <!--<div class="subtitle">características</div>-->
        </div>
        <div class="card5-item-body">
          <div class="row">
            <div class="item col-sm-3">
              <div class="title">Código do Cupom</div>
              <div class="value">
                {{ transactionErp.coupon_code | strEmpty }}
              </div>
            </div>
            <div class="item col-sm-3">
              <div class="title">Destino</div>
              <div class="value">
                {{ DestinationTypeEnum.props[transactionErp.destination_type_id].name }}
              </div>
            </div>
            <div class="item col-sm-3">
              <div class="title">Tipo de Numerário</div>
              <div class="value">
                {{ CashTypeEnum.props[transactionErp.cash_type_id].name }}
              </div>
            </div>
          </div>

          <div v-if="transactionErp.cash_type_id === CashTypeEnum.Pix">
            <div class="row">
              <div class="item col-sm-6">
                <div class="title">Chave PIX</div>
                <div class="value">
                  {{ transactionErp.pix_code | strEmpty }}
                </div>
              </div>
              <div class="item col-sm-6">
                <div class="title">TXID</div>
                <div class="value">
                  {{ transactionErp.txid | strEmpty }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="item col-sm-3">
                <div class="title">TXID Parcial</div>
                <div class="value">
                  {{ transactionErp.txid_partial | strEmpty }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-bind="institutionData = transactionErp.institution_origin.data">
        <hr class="divider">
        <div>
          <app-institution :institution="institutionData" subtitle="Estabelecimento de Origem"></app-institution>
        </div>
      </section>

      <div>
        <!--BANK-->
        <section v-if="isDestinationBank()">
          <hr class="divider">
          <app-bank-account :bank-account="transactionErp.bank_account"></app-bank-account>
        </section>

        <!--STRONGBOX-->
        <section v-if="isDestinationStrongbox()"
                 v-bind="strongboxData = transactionErp.strongbox.data">
          <hr class="divider">

          <div>
            <div class="card5-item-header">
              <!--<div class="title"><span class="icon-grain"></span>Cofre</div>-->
              <div class="subtitle">
                <!--<span class="icon-grain"></span>-->
                Cofre
              </div>
            </div>
            <div class="card5-item-body">
              <div class="row">
                <div class="item col-sm-6">
                  <div class="title">Código</div>
                  <div class="value">{{ strongboxData.code }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="divider">
          <div
            v-for="(transporterInstitution, transporterInstitutionIndex) in strongboxData.transporters_institutions.data"
            :key="'transporter-institution-' + transporterInstitutionIndex">
            <app-transporter-institution
              :transporterInstitution="transporterInstitution"></app-transporter-institution>
          </div>
        </section>

        <!--INSTITUTION-->
        <section v-if="isDestinationInstitution()"
                 v-bind="institutionData = transactionErp.institution.data">
          <hr class="divider">
          <app-check-predated :checkPredated="transactionErp.check_predated.data"></app-check-predated>

          <hr class="divider">
          <div>
            <app-institution :institution="institutionData" subtitle="Estabelecimento de Destino"></app-institution>
          </div>


        </section>
      </div>


      <section>
        <hr class="divider">
        <div class="card5-item-header">
          <div class="title"><span class="icon-signpost"></span>Conciliação</div>
        </div>
        <div class="card5-item-body">
          <div class="section-reconciliation-percent">
            <app-conciliation-item-detail :response="conciliationCollection"></app-conciliation-item-detail>
          </div>
        </div>
      </section>
      <!--<hr class="divider">-->
      <!--<app-activities></app-activities>-->
      <!--<app-transaction-timeline></app-transaction-timeline>-->
    </div>
  </div>
</template>
<script>
import AppTransporterInstitution from '/src/features/transactions/components/details-components/transporter-institution'
import AppConciliationItemDetail from '/src/components/conciliation-item-detail'
import AppInstitution from '/src/features/transactions/components/details-components/institution'
import AppBankAccount from '/src/features/transactions/components/details-components/bank-account'
import AppDetailTransaction from '/src/features/transactions/components/details-components/detail-transaction'
import DestinationTypeEnum from '/src/enums/destinations-type'
import CashTypeEnum from '/src/enums/cash-type'
import ConciliationProvider from '/src/features/transactions/providers/conciliation'
import AppCheckPredated from '/src/features/transactions/components/details-components/check-predated'

export default {
  mounted() {
    this.loadConciliationCollection()
  },
  components: {
    AppBankAccount,
    AppInstitution,
    AppDetailTransaction,
    AppTransporterInstitution,
    AppConciliationItemDetail,
    AppCheckPredated
  },
  props: ['transaction'],
  data() {
    return {
      conciliationCollection: null,
      DestinationTypeEnum: DestinationTypeEnum,
      CashTypeEnum: CashTypeEnum
    }
  },
  methods: {
    loadConciliationCollection() {
      let self = this
      let id = this.$route.params.id;

      ConciliationProvider
        .findById(id)
        .then((response) => {
          self.conciliationCollection = response.data
        })
    },
    isDestinationBank() {
      return this.transaction.transaction_erp.data.destination_type_id === 1
    },
    isDestinationStrongbox() {
      return this.transaction.transaction_erp.data.destination_type_id === 2
    },
    isDestinationInstitution() {
      return this.transaction.transaction_erp.data.destination_type_id === 3
    }
  }
}
</script>
