const items = {
  1: 'money',
  2: 'bankCheckCash',
  3: 'bankCheckPreDated',
  4: 'pix'
}

const names = {
  1: 'Dinheiro',
  2: 'Cheque à vista',
  3: 'Cheque pré-datado',
  4: 'Pix',
}

const cashTypeIcon = (cashTypeId) => {
  let item = items[cashTypeId]

  if (item === undefined) {
    item = 'undefined'
  }

  return require('@/assets/cash_types/' + item + '.svg')
}

const cashTypeName = (cashTypeId) => {
  let item = names[cashTypeId]

  if (item === undefined) {
    item = 'nenhum'
  }

  return item
}

export default {
  cashTypeIcon,
  cashTypeName
}
