import Vue from 'vue'

Vue.filter('pad', (value, size = 3) => {
  value = value.toString()

  while (value.length < (size || 2)) {
    value = '0' + value
  }

  return value
})
