<template>
  <app-layout-site>
    <div class="transacao-list">
      <div class="card5 dashboard-10">
        <h1>Dashboard</h1>


        <div>
          Transações (quantidade)
          (Não conciliado) pendente
          (Conciliado)
        </div>

        <div>
          Transações (valor)
          (Não conciliado) pendente
          (Conciliado)
        </div>

        <div>
          Conciliação (manual/automatico)
          manual
          automatico
        </div>

        <div>
          Transações
        </div>

      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site';

export default {
  name: 'dashboard',
  components: {
    AppLayoutSite,
  },
}
</script>
