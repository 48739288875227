/* eslint-disable no-template-curly-in-string */
import {Provider} from '/src/parents/provider'

class VisionRepository extends Provider {
  getErpPixBank(payload) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/visions/erp-pix-bank/${payload.date}`)
  }

  getErpMoneyBank(payload) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/visions/erp-money-bank/${payload.date}`)
  }

  getErpBankCheckBank(payload) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/visions/erp-bank-check-bank/${payload.date}`)
  }

  getErpBankCheckPredatedInstitution(payload) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/visions/erp-bank-check-predated-institution/${payload.date}`)
  }

  getErpMoneyStrongbox(payload) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/visions/erp-money-strongbox/${payload.date}`)
  }

  getUserModified(payload) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/visions/user-modified/${payload.date}`)
  }
}

export default new VisionRepository()
