import Settings from '/src/utils/settings'

export default {
  data() {
    return {
      cardsMode: Settings.getSettings().general.transactions.viewMode
    }
  },
  methods: {
    toggleCards() {
      this.cardsMode = !this.cardsMode
    }
  }
}
