<template>
  <div>
    <div>
      <h1 class="menux-title">Contas Bancárias</h1>
    </div>

    <div>
      <app-loader :loading="loading">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Banco</th>
            <th scope="col">Agência</th>
            <th scope="col">Conta</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item) of items" :key="item.id">
            <td>{{ bankValue(item) }}</td>
            <td>{{ agencyValue(item) }}</td>
            <td>{{ accountValue(item) }}</td>
          </tr>
          </tbody>
        </table>
      </app-loader>
    </div>
  </div>
</template>

<script>
import BankAccountProvider from "../../settings/components/register/providers/bank-accounts";
import AppLoader from "../../../components/loader.vue";

export default {
  components: {AppLoader},
  async created() {
    this.loading = true
    try {
      const data = await BankAccountProvider.get2();
      this.items = data.data
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  },
  data() {
    return {
      loading: false,
      items: []
    }
  },
  methods: {
    bankValue(item) {
      let code = this.$options.filters.bankCode(item.bank_code)
      return code + '-' + item.bank_name_short
    },
    agencyValue(item) {
      let agencyCheck = item.agency_check ? '-' + item.agency_check : ''
      return item.agency + agencyCheck
    },
    accountValue(item) {
      let accountCheck = item.account_check ? '-' + item.account_check : ''
      return item.account + accountCheck
    }
  }
}
</script>
