<template>
  <apex-charts
    type="pie"
    :options="chartOptions"
    :series="this.values"
    :height="height"
  ></apex-charts>
</template>

<script>
import ApexCharts from 'vue-apexcharts';

export default {
  components: {
    ApexCharts,
  },
  props: {
    data: {},
    series: {},
    height: {},
    showLegend: {
      default: false,
    },
  },
  data() {
    return {
      values: [],
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
          },
        },
        labels: [],
        colors: [],
        height: this.height,
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: this.showLegend,
          position: "right",
          horizontalAlign: "center",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
        },
        grid: {
          show: false,
        },
        tooltip: {
          enabled: false
        }
      },
    };
  },
  created() {
    this.data.forEach((element) => {
      this.values.push(element.value);
      this.chartOptions.labels.push(element.name);

      if (element.color) {
        this.chartOptions.colors.push(element.color);
      } else {
        this.chartOptions.colors = undefined;
      }
    });
  },
};
</script>
