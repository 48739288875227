const items = {
  DEPOSITO: 1,
  COLETA: 2,
  props: {
    1: {name: 'Depósito'},
    2: {name: 'Coleta'},
    3: {name: 'Corte'}
  }
}

export default Object.freeze(items)
