import Index from './components/Index'
import Format01ErpMoneyBank from './components/format-01/ErpMoneyBank'
import Format01ErpPixBank from './components/format-01/ErpPixBank'
import Format01ErpBankCheckBank from './components/format-01/ErpBankCheckBank';
import Format02ErpMoneyBank from './components/format-02/ErpMoneyBank'

export default [
  {
    path: '/reports/',
    name: 'reports-index',
    component: Index
  },
  {
    path: '/reports/format-01/erp-money-bank',
    name: 'reports-format-01-erp-money-bank',
    component: Format01ErpMoneyBank
  },
  {
    path: '/reports/format-01/erp-pix-bank',
    name: 'reports-format-01-erp-pix-bank',
    component: Format01ErpPixBank
  },
  {
    path: '/reports/format-01/erp-bank-check-bank',
    name: 'reports-format-01-erp-bank-check-bank',
    component: Format01ErpBankCheckBank
  },
  {
    path: '/reports/format-02/erp-money-bank',
    name: 'reports-format-02-erp-money-bank',
    component: Format02ErpMoneyBank
  },
]
