<template>
  <register title="Estabelecimentos">
    <router-view/>
  </register>
</template>

<script>
import Register from '../register'

export default {
  components: {
    Register
  }
}
</script>
