<template>
  <app-layout-site>
    <div class="card5">
      <h1>Cheque Pré datado</h1>
      <div class="check-predated-option">
        <router-link :to="{name:'bank-check-cmc7'}">Vincular CMC7</router-link>
        <router-link :to="{name:'bank-check-file'}">Gerar arquivo</router-link>
      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'

export default {
  name: 'cheque',
  components: {
    AppLayoutSite
  }
}
</script>
