const items = {
  Group: 1,
  Company: 2,
  MATRIZ: 3,
  Store: 4,
  INVESTIMENTO: 5,
  props: {
    1: {name: 'Grupo'},
    2: {name: 'Empresa'},
    3: {name: 'Matriz'},
    4: {name: 'Loja'},
    5: {name: 'Fundo de Investimento'}
  }
}

export default Object.freeze(items)
