export default {
  methods: {
    applyFilter() {
      this.paginateLoading()
      this.closeFilter()
      this.refresh()
    },
    toggleFilter() {
      this.filter = !this.filter
    },
    closeFilter() {
      this.filter = false
    },
    openFilter() {
      this.filter = true
    }
  }
}
