<template>
  <app-layout-site>
    <div class="transacao-list">
      <div class="card5 dashboard-10">
        <h1>Dashboard</h1>


        <div class="container">
          <div class="row mb-3">
            <app-datetime-picker
              id="ipt-date"
              v-model="date"
              v-on:input="getValues()"
              style="max-width: 260px"
              output-format="YYYYMM"
              format="YYYYMM"
              formatted="YYYY-MM"
              label="Selecione uma mês"
              :auto-close="true"
              :only-date="true"

              :min-date="datepicker.minDate"
              :max-date="datepicker.maxDate"
            >
            </app-datetime-picker>
          </div>
        </div>

        <div v-if="loading">loading</div>
        <div v-else class="container">
          <div class="cached-at-container">
            <div class="cached-at">atualizado em {{ monthDateCachedAt }}</div>
            <button class="btn-cached-refresh" @click="getMonthData(true)">
              <span class="icon-sync"></span>
            </button>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="col-sm-12 col-lg-3">
              <TotalCard
                title="Movimentado"
                subtitle=""
                :total="valueTotal | money"
                total-prefix="R$ "
                :total-letter="true"
              />
            </div>
            <div class="col-sm-12 col-lg-3">
              <TotalCard
                title="Em Trânsito"
                subtitle=""
                :total="valueToConciliate | money"
                total-prefix="R$ "
                :total-letter="true"
              />
            </div>
            <div class="col-sm-12 col-lg-3">
              <TotalCard
                title="Transações"
                subtitle=""
                :total="transactions"
                total-prefix=""
                :total-letter="true"
              />
            </div>
            <div class="col-sm-12 col-lg-3">
              <TotalCard
                title="Conciliações Manual/Auto"
                subtitle=""
                :total="conciliationsManual + ' / ' + conciliationsAuto"
                total-prefix=""
                :total-letter="true"
              />
            </div>
          </div>

          <!--          <hr>-->
          <div class="row justify-content-center mb-3">
            <div v-if="this.valueTotal > 0" class="col-sm-12 col-md-6">
              <ConciliationPieCard
                :data="conciliationStatus"
                title="Status conciliações"
                tooltipText="Total conciliado vs não conciliado"
              />
            </div>
            <div v-if="this.conciliatedByType.length > 0" class="col-sm-12 col-md-6">
              <ConciliationPieCard
                :data="conciliatedByType"
                title="Conciliado por tipo"
                tooltipText="Total conciliado por tipo de valor"
              />
            </div>
          </div>

          <div class="row ">
            <div
              v-for="value in this.valuesTypeConciliation"
              :key="value.valueName"
              class="col-sm-12 col-md-6 mb-3"
            >
              <ConciliationPieCard
                :data="value.series"
                :title="value.valueName"
                :tooltipText="value.name + 'conciliado'"
              />
            </div>
          </div>

          <section v-if="this.loadingHistory == false">
            <div class="dashboard-subtitle">últimos 3 meses</div>
            <div class="cached-at-container">
              <div class="cached-at">atualizado em {{ monthHistoryDateCachedAt }}</div>
              <button class="btn-cached-refresh" @click="getHistoryData(true)">
                <span class="icon-sync"></span>
              </button>
            </div>
            <div class="row justify-content-center">
              <div v-if="this.transactionHistory.transactionsByDestination.length > 0" class="col-md-6 mb-3">
                <LineChart
                  :series="transactionHistory.transactionsByDestination"
                  title="Transações por destino"
                  height="200px"
                />
              </div>

              <div v-if="this.transactionHistory.transactionsTotalByDestination.length > 0"
                   class="col-md-6 mb-3">
                <BarChart
                  :series="transactionHistory.transactionsTotalByDestination"
                  title="Valor por destino"
                  height="200px"
                />
              </div>

              <div v-if="this.transactionHistory.totalConciliatedInBank.length > 0" class="col-md-6 mb-3">
                <BarChart
                  :series="transactionHistory.totalConciliatedInBank"
                  title="Total conciliado banco"
                  height="200px"
                />
              </div>

              <div v-if="this.transactionHistory.valuelByType.length > 0" class="col-md-6 mb-3">
                <LineChart
                  :series="transactionHistory.valuelByType"
                  title="Total por tipo"
                  height="200px"
                />
              </div>

              <div v-if="this.transactionHistory.transactionsByType.length > 0" class="col-md-6">
                <LineChart
                  :series="transactionHistory.transactionsByType"
                  title="Transações por tipo"
                  height="200px"
                />
              </div>

              <div v-if="this.transactionHistory.totalConciliated.length > 0" class="col-md-6">
                <LineChart
                  :series="transactionHistory.totalConciliated"
                  title="Total conciliado"
                  height="200px"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site';
import DashboardRepository from '/src/repositories/dashboard';
import TotalCard from '/src/features/dashboard/components/cards/total-card/TotalCard';
import LineChart from '/src/features/dashboard/components/charts/line-chart/LineChart';
import BarChart from '/src/features/dashboard/components/charts/bar-chart/BarChart';
import ConciliationPieCard from '/src/features/dashboard/components/cards/conciliation-pie-card/ConciliationPieCard';
import AppDatetimePicker from '/src/components/date-time-picker';

import moment from 'moment';
import CashTypeEnum from '/src/enums/cash-type'

const minDate = moment('20180101', 'YYYYMMDD').format('YYYY-MM-DD')
const maxDate = moment().format('YYYY-MM-DD')

export default {
  name: 'dashboard',
  components: {
    AppLayoutSite,
    TotalCard,
    LineChart,
    BarChart,
    ConciliationPieCard,
    AppDatetimePicker,
  },
  props: {},
  data() {
    return {
      datepicker: {
        minDate: minDate,
        maxDate: maxDate
      },
      monthDateCachedAt: null,
      monthHistoryDateCachedAt: null,
      date: moment().format('YYYYMM'),
      loading: true,
      loadingHistory: true,
      valueTotal: 0,
      valueToConciliate: 0,
      conciliationsManual: 0,
      conciliationsAuto: 0,
      transactions: 0,
      values: [],
      conciliationStatus: [],
      conciliatedByType: [],
      valuesTypeConciliation: [],
      transactionHistory: {
        transactionsByDestination: [],
        transactionsTotalByDestination: [],
        totalConciliatedInBank: [],
        valuelByType: [],
        transactionsByType: [],
        totalConciliated: []
      },
    };
  },
  mounted() {
    this.getValues();
  },
  methods: {
    getValues() {
      this.getMonthData(false);
      this.getHistoryData(false);
    },
    getMonthData(noCache) {
      this.loading = true;
      DashboardRepository.getMonthData(this.date, noCache).then((response) => {
        this.prepareMonthDate(response);
      });
    },
    prepareMonthDate(responseData) {
      this.monthDateCachedAt = moment(responseData.cached_at).format('DD/MM/YYYY HH:mm:ss');

      const data = responseData.data;

      // console.log(data);
      this.valueTotal = data.valueTotal;
      this.valueToConciliate = data.valueToConciliate;
      this.conciliationsAuto = data.conciliationsAuto;
      this.conciliationsManual = data.conciliationsManual;
      this.transactions = data.transactions;
      this.conciliationStatus = [
        {name: 'Conciliado', value: (data.valueTotal - data.valueToConciliate), color: '#1867a3'},
        {name: 'Não conciliado', value: data.valueToConciliate, color: '#d9574c'},
      ];

      this.conciliatedByType = [];
      for (const index in data.values) {
        const object = data.values[index];


        this.conciliatedByType.push({
          name: CashTypeEnum.props[object.cash_type_id].name,
          value: object.valueConciliated,
          color: object.color,
        });
      }

      this.valuesTypeConciliation = [];
      for (const index in data.values) {
        const object = data.values[index];

        if (object.cash_type_id === 3) {
          continue;
        }

        let item = {
          valueName: CashTypeEnum.props[object.cash_type_id].name,
          series: [
            {
              name: 'Conciliado',
              value: object.valueConciliated,
              color: object.color
            },
            {
              name: 'Não conciliado',
              value: object.total - object.valueConciliated,
              color: '#707a76',
            },
          ],
        };

        this.valuesTypeConciliation.push(item);
      }

      // console.log('aa');

      this.loading = false;
    },
    getHistoryData(noCache) {
      this.loadingHistory = true;
      DashboardRepository.getHistoryData(noCache)
        .then((response) => {
          this.prepareHistoryData(response);
        })
        .finally(() => {
          this.loadingHistory = false;
        });
    },
    prepareHistoryData(responseData) {
      this.monthHistoryDateCachedAt = moment(responseData.cached_at).format('DD/MM/YYYY HH:mm:ss');

      const data = responseData.data;

      this.prepareTransactionsByDestination(data.transactionsByDestination);
      this.prepareTransactionsValueByDestination(data.transactionsByDestination);
      this.prepareConciliatedInBank(data.totalConciliatedInBank);
      this.prepareValueByType(data.totalValueByType);
      this.prepareTransactionsByType(data.totalValueByType);
      this.prepareTotalConciliated(data.totalConciliated);
    },
    prepareTransactionsByDestination(data) {
      if (!data.Banco || !data.Cofre) {
        this.transactionHistory.transactionsByDestination = [];
        return
      }

      let banco = {
        name: 'Banco',
        data: data.Banco.map((el) => {
          return {
            x: el.date,
            y: el.transactions,
          };
        }),
      };
      let cofre = {
        name: 'Cofre',
        data: data.Cofre.map((el) => {
          return {
            x: el.date,
            y: el.transactions,
          };
        }),
      };

      this.transactionHistory.transactionsByDestination = [banco, cofre];
    },
    prepareTransactionsValueByDestination(data) {

      if (!data.Banco) {
        this.transactionHistory.transactionsTotalByDestination = [];
        return
      }

      if (!data.Cofre) {
        this.transactionHistory.transactionsTotalByDestination = [];
        return
      }

      let banco = {
        name: 'Banco',
        data: data.Banco.map((el) => {
          return {
            x: el.date,
            y: el.total,
          };
        }),
      };
      let cofre = {
        name: 'Cofre',
        data: data.Cofre.map((el) => {
          return {
            x: el.date,
            y: el.total,
          };
        }),
      };

      this.transactionHistory.transactionsTotalByDestination = [banco, cofre];
    },
    prepareConciliatedInBank(data) {
      if (!data || data.length == 0) {
        this.transactionHistory.totalConciliatedInBank = [];
        return
      }

      let banco = {
        name: 'Banco',
        data: data.map((el) => {
          return {
            x: el.date,
            y: el.total,
          };
        }),
      };
      this.transactionHistory.totalConciliatedInBank = [banco];
    },
    prepareValueByType(data) {

      if (!data.Dinheiro) {
        this.transactionHistory.valuelByType = [];
        return
      }
      if (!data.Pix) {
        this.transactionHistory.valuelByType = [];
        return
      }
      if (!data.Cheque) {
        this.transactionHistory.valuelByType = [];
        return
      }

      let dinheiro = {
        name: 'Dinheiro',
        data: data.Dinheiro.map((el) => {
          return {
            x: el.date,
            y: el.total,
          };
        }),
      };
      let pix = {
        name: 'Pix',
        data: data.Pix.map((el) => {
          return {
            x: el.date,
            y: el.total,
          };
        }),
      };
      let cheque = {
        name: 'Cheque',
        data: data.Cheque.map((el) => {
          return {
            x: el.date,
            y: el.total,
          };
        }),
      };

      this.transactionHistory.valuelByType = [dinheiro, pix, cheque];
    },
    prepareTransactionsByType(data) {

      if (!data.Dinheiro) {
        this.transactionHistory.valuelByType = [];
        return
      }
      if (!data.Pix) {
        this.transactionHistory.valuelByType = [];
        return
      }
      if (!data.Cheque) {
        this.transactionHistory.valuelByType = [];
        return
      }

      let dinheiro = {
        name: 'Dinheiro',
        data: data.Dinheiro.map((el) => {
          return {
            x: el.date,
            y: el.transactions,
          };
        }),
      };
      let pix = {
        name: 'Pix',
        data: data.Pix.map((el) => {
          return {
            x: el.date,
            y: el.transactions,
          };
        }),
      };
      let cheque = {
        name: 'Cheque',
        data: data.Cheque.map((el) => {
          return {
            x: el.date,
            y: el.transactions,
          };
        }),
      };

      this.transactionHistory.transactionsByType = [dinheiro, pix, cheque];
    },
    prepareTotalConciliated(data) {
      if (!data || data.length == 0) {
        this.transactionHistory.totalConciliated = [];
        return
      }

      let total = {
        name: 'Conciliado',
        data: data.map((el) => {
          return {
            x: el.date,
            y: el.total,
          };
        }),
      };

      this.transactionHistory.totalConciliated = [total];
    },
  },
};
</script>
