import TransactionIndex from './components/Index'

import details from './components/details'
import erpEdit from './components/erp/edit'
import erpAdd from './components/erp/add'
import bankAdd from './components/bank/add'
import bankEdit from './components/bank/edit'
import erp from './components/list-components/erp'
import strongbox from './components/list-components/strongbox'
import institution from './components/list-components/institution'
import bank from './components/list-components/bank'

export default [
  {
    path: '/transactions',
    // name: 'transactions-index',
    component: TransactionIndex,
    children: [
      {
        path: '',
        name: 'transactions-index',
        redirect: {
          name: 'transactions-erp-index',
        }
      },
      {
        path: 'erp',
        component: erp,
        name: 'transactions-erp-index'
      },
      {
        path: 'strongbox',
        component: strongbox,
        name: 'transactions-strongbox-index'
      },
      {
        path: 'institution',
        component: institution,
        name: 'transactions-institution-index'
      },
      {
        path: 'bank',
        component: bank,
        name: 'transactions-bank-index'
      }
    ]
  },
  {
    path: '/transactions/erp/add',
    name: 'transactions-erp-add',
    component: erpAdd
  },

  {
    path: '/transactions/erp/:id/edit',
    name: 'transactions-erp-edit',
    component: erpEdit
  },
  {
    path: '/transactions/bank/add',
    name: 'transactions-bank-add',
    component: bankAdd
  },
  {
    path: '/transactions/bank/:id/edit',
    name: 'transactions-bank-edit',
    component: bankEdit
  },

  {
    path: '/transactions/:origin/:id',
    name: 'transactions-details',
    component: details
  },
]
