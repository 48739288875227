import {Provider} from '/src/parents/provider'

class TokenProvider extends Provider {
  get(payload) {
    return this.doRequest('POST', `${process.env.VUE_APP_API_URL}/v1/oauth/token`, payload)
  }

  getRefreshToken(payload) {
    return this.doRequest('POST', `${process.env.VUE_APP_API_URL}/v1/oauth/token`, payload)
  }
}

export default new TokenProvider()
