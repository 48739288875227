<template>
  <div class="transaction-card mx-auto t-card2">
    <div class="header">
      <div class="options">
        <router-link :to="{name: 'transactions-bank-edit', params: {id: transaction.id}}"
                     v-if="transaction.user_type === 2" class="btn-card btn-edit">
          <span class="icon-mode_edit" title="Editar"></span>
        </router-link>
        <router-link
          :to="{name: 'transactions-details', params:{ origin:'bank', id: transaction.id}, query: queryTransactionDetails()}"
          class="btn-card btn-show">
          <span class="icon-keyboard_arrow_right" title="Detalhes"></span>
        </router-link>
      </div>
    </div>
    <div class="conciliation ml-auto">
      <app-conciliation-percentage :percents="transaction.conciliation_percent"
                                   class="ml-auto"></app-conciliation-percentage>
    </div>
    <div class="body">
      <div class="dl-item-group ">
        <div class="dl-title">Valor</div>
        <!--        <div class="dl-item">-->
        <!--          <div class="dl-col dl-col-field" title="Tipo de valor">tipo</div>-->
        <!--          <div class="dl-col dl-col-val ">-->
        <!--            {{ CashTypeEnum.props[transaction.cash_type_id].name }}-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Origem">valor</div>
          <div class="dl-col dl-col-val destaque">{{ transaction.value | money }}</div>
        </div>
      </div>
      <div class="dl-item-group ">
        <div class="dl-title">Detalhes</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Data da ocorrência da transação">data de ocorrência</div>
          <div class="dl-col dl-col-val destaque">{{ transaction.date | formatDate }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Origem">origem</div>
          <div class="dl-col dl-col-val destaque">Banco</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Código do cupom">código do cupom</div>
          <div class="dl-col dl-col-val">{{ transaction.coupon_code }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Histórico de Lançamento">histórico de lançamento</div>
          <div class="dl-col dl-col-val">{{ transaction.bank_launch_history }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Numero de Lançamento">numero de lançamento</div>
          <div class="dl-col dl-col-val">{{ transaction.bank_launch_number }}</div>
        </div>
      </div>
      <div class="dl-item-group">
        <div class="dl-title">Banco - Origem</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">nome</div>
          <div class="dl-col dl-col-val" :title="transaction.bank_name">
            <div class="bank-icon">
              <img :src="BankIconUtil.bankIcon(transaction.bank_code)" alt="">
            </div>
            <span>
             {{ transaction.bank_name }}
            </span>
            <span>
              - {{ transaction.bank_code | pad(3) }}
            </span>
          </div>
        </div>

        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Banco - Agência">agência</div>
          <div class="dl-col dl-col-val" :title="bankAgency(transaction)">{{ bankAgency(transaction) }}
          </div>
        </div>

        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Banco - Conta Corrente">conta corrente</div>
          <div class="dl-col dl-col-val" :title="bankAccount(transaction)">{{ bankAccount(transaction) }}
          </div>
        </div>
      </div>

      <div class="id" title="Identificador">#{{ transaction.id }}</div>
    </div>
  </div>
</template>

<script>
import AppConciliationPercentage from '/src/components/conciliation-percent'
import BankIconUtil from '/src/utils/icon-banks'

export default {
  props: ['transaction', 'ctid'],

  components: {
    AppConciliationPercentage
  },

  data() {
    return {
      BankIconUtil: BankIconUtil
    }
  },

  methods: {
    direction(transaction) {
      // todo: fazer uso do enumerador. C-credito D-debito
      return transaction.bank_launch_category_type === 'C' ? 'down' : 'up'
    },
    directionClass(transaction) {
      return 'direction ' + this.direction(transaction)
    },
    directionArrowClass(transaction) {
      return 'icon-arrow-' + this.direction(transaction)
    },
    directionTitle(transaction) {
      return transaction.bank_launch_category_type === 'C' ? 'Crédito' : 'Débito'
    },
    bankAccount(transaction) {
      return transaction.bank_account + (transaction.bank_account_check ? '-' + transaction.bank_account_check : '')
    },
    bankAgency(transaction) {
      return transaction.bank_agency + (transaction.bank_agency_check ? '-' + transaction.bank_agency_check : '')
    },
    editLink() {
      this.$router.push({name: 'transactions-bank-edit', params: {id: this.transaction.id}})
    },
    queryTransactionDetails() {
      if (this.ctid !== null) {
        return {ctid: this.ctid}
      }

      return {}
    }
  }
}
</script>
