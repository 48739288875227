<template>
  <ul class="conciliation-percent2" v-if="percents != null">
    <li class="percent-up" v-bind="items = percents.origin" v-if="percents.origin.length > 0">
      <ul class="percent-progress-container">
        <!--        :style="{width: styleWidthProgressContainer(values) }"-->
        <li
          v-for="(item, index) in (items)" :key="'progress-'+index"
          :title="tooltip(item)">
          <div :class="{
          'percent-progress':true,
          'percent-progress-high': item.percent === 1.0,
          'percent-progress-medium': item.percent > 0.1 && item.percent < 1,
          'percent-progress-low': item.percent <= 0.1,
          }"
          ></div>
          <!--          <div class="percent-progress" :style="{width: percentFormat(item.percent)}"></div>-->
        </li>
      </ul>
      <!--          <div class="percent-level" v-for="(item, index) in (items.length-1)"-->
      <!--               :key="'level-'+index" :style="{left: styleLeftPercentLevel(items,index)}">-->
      <!--          </div>-->
    </li>

    <li class="percent-element" v-if="isShow()"></li>

    <li class="percent-down" v-bind="items = percents.destination" v-if="percents.destination.length > 0">
      <ul class="percent-progress-container">
        <!--          :style="{width: styleWidthProgressContainer(items) }"-->
        <li
          v-for="(item, index) in (items)" :key="'progress-'+index" :title="tooltip(item)">
          <div :class="{
          'percent-progress':true,
          'percent-progress-high': item.percent === 1.0,
          'percent-progress-medium': item.percent > 0.1 && item.percent < 1,
          'percent-progress-low': item.percent <= 0.1,
          }"
          ></div>
          <!--          <div class="percent-progress" :style="{width: percentFormat(item.percent)}"></div>-->
        </li>
      </ul>

      <!--      <div class="percent-level" v-for="(item, index) in (items.length-1)"-->
      <!--           :key="'level-'+index" :style="{left: styleLeftPercentLevel(items,index) }">-->
      <!--      </div>-->
    </li>
  </ul>
</template>
<script>
export default {
  name: 'app-conciliation-percent',
  props: ['percents'],
  methods: {
    percentFormat(value) {
      return (value * 100).toFixed(2) + '%'
    },
    tooltip(item) {
      let percent = item.percent.toFixed(4)
      // let direction = directionUp ? 'origem' : 'destino'
      // let description = 'conciliado ' + this.percentFormat(value) + ' no ' + (index + 1) + '° nivel de ' + direction;
      let conciliationRelationshipDescription = this.conciliationRelationshipDescription(item.conciliation_relationship_type_id)
      let description = conciliationRelationshipDescription + '. ' + this.percentFormat(percent) + ' conciliado.';
      return description
    },
    conciliationRelationshipDescription(value) {
      if (value === 1) {
        return 'Conciliação ERP - BANCO';
      }
      if (value === 2) {
        return 'Conciliação ERP - COFRE DEPOSITO';
      }
      if (value === 3) {
        return 'Conciliação COFRE DEPOSITO - COFRE COLETA';
      }
      if (value === 4) {
        return 'Conciliação COFRE DEPOSITO - COFRE CORTE';
      }
      if (value === 5) {
        return 'Conciliação COFRE COLETA - BANCO';
      }
      if (value === 6) {
        return 'Conciliação COFRE CORTE - BANCO';
      }
      if (value === 6) {
        return 'Conciliação ERP - INSTITUIÇÃO';
      }

      return 'INDEFINIDO';
    },
    isShow() {
      return this.percents.destination.length > 0 || this.percents.origin.length > 0;
    },
    styleWidthProgressContainer(values) {
      return this.percentFormat(1 / values.length)
    },
    styleLeftPercentLevel(values, index) {
      return this.percentFormat(1 / values.length * (index + 1))
    }
  }
}
</script>
