<template>
  <div class="page-layout">
    <app-header class="page-layout-nav"></app-header>
    <main class="page-layout-body">
      <slot></slot>
      <app-footer></app-footer>
    </main>
  </div>
</template>

<script>
import AppHeader from '/src/components/partials/header-left'
import AppFooter from '/src/components/partials/footer'

export default {
  name: 'layout-site',
  components: {
    AppHeader,
    AppFooter
  },
  beforeCreate: function () {
    document.body.className = 'bg-site'
  }
}
</script>
