import DestinationTypeEnum from '/src/enums/destinations-type'
import CashTypeEnum from '/src/enums/cash-type'

const items = {}

items[DestinationTypeEnum.Bank] = [
  {id: CashTypeEnum.Money, name: CashTypeEnum.props[CashTypeEnum.Money].name},
  {id: CashTypeEnum.BankCheck, name: CashTypeEnum.props[CashTypeEnum.BankCheck].name},
  {id: CashTypeEnum.Pix, name: CashTypeEnum.props[CashTypeEnum.Pix].name}
]

items[DestinationTypeEnum.Strongbox] = [
  {id: CashTypeEnum.Money, name: CashTypeEnum.props[CashTypeEnum.Money].name}
]

items[DestinationTypeEnum.Institution] = [
  {id: CashTypeEnum.BankCheckPreDated, name: CashTypeEnum.props[CashTypeEnum.BankCheckPreDated].name}
]

export default items
