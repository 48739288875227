<template>
  <div class="register">
    <div class="forms">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>
