import storage from '/src/utils/storage'
import TokenProvider from '/src/features/auth/providers/token'

/**
 * Get a new token from api
 * @param user
 */
function getToken(user) {
  let body = {
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    grant_type: 'password',
    username: user.email,
    password: user.password
  }

  return new Promise((resolve, reject) => {
    TokenProvider.get(body)
      .then((response) => {
        storage.set('token', response)
        resolve(response)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

function getRefreshToken() {
  let token = storage.get('token')
  let body = {
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    grant_type: 'refresh_token',
    refresh_token: token.refresh_token,
    scope: ''
  }

  return new Promise((resolve, reject) => {
    TokenProvider.getRefreshToken(body)
      .then((response) => {
        storage.set('token', response)
        resolve(response)
      }).catch((error) => {
      reject(error)
    })
  })
}

export default {
  getToken,
  getRefreshToken
}
