<template>
  <div class="no-results">
    <img src="../assets/no-results.png" alt="No result found!" class="image-empty">
    <div class="title">{{ title }}</div>
    <div class="description">{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: 'Nenhum item encontrado'
    },
    description: {
      default: 'Ajuste a pesquisa para obter outros resultados.'
    }
  }
}
</script>
