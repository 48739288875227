<template>
  <div class="filter-modal">
    <div class="header">
      <div class="actions ml-auto">
        <span class="icon-close close" @click="close()"></span>
      </div>
    </div>
    <div class="content">
      <div class="top">
        <filter-select-erp v-if="isCurrentRoute('erp')" ref="filter" @open="open" @apply="apply()"></filter-select-erp>
        <filter-select-strongbox v-if="isCurrentRoute('strongbox')" ref="filter"></filter-select-strongbox>
        <filter-select-institution v-if="isCurrentRoute('institution')" ref="filter"></filter-select-institution>
        <filter-select-bank v-if="isCurrentRoute('bank')" ref="filter" @open="open"
                            @apply="apply()"></filter-select-bank>
      </div>
    </div>
    <div class="actions">
      <div class="buttons">
        <button class="btn btn-link" @click="clear()">Limpar</button>
        <button class="btn btn-primary" @click="apply()">Aplicar</button>
      </div>
    </div>
  </div>
</template>

<script>
// import FilterBy from './filter-by'
import FilterSelectErp from './filter-select-erp'
import FilterSelectStrongbox from './filter-select-strongbox'
import FilterSelectInstitution from './filter-select-institution'
import FilterSelectBank from './filter-select-bank'
import _ from 'lodash'

export default {
  components: {
    // FilterBy,
    FilterSelectErp,
    FilterSelectStrongbox,
    FilterSelectInstitution,
    FilterSelectBank
  },

  methods: {
    close() {
      this.$emit('close')
    },
    open() {
      this.$emit('open')
    },
    apply() {
      console.log('filter-modal:apply')
      this.$emit('apply')
    },
    clear() {
      this.$refs.filter.reset()
    },
    currentRoute() {
      let items = [
        {
          path: '/transactions/erp',
          value: 'erp',
        },
        {
          path: '/transactions/strongbox',
          value: 'strongbox',
        },
        {
          path: '/transactions/bank',
          value: 'bank',
        },
        {
          path: '/transactions/institution',
          value: 'institution',
        },
      ];

      let path = this.$route.path
      let result = _.find(items, function (item) {
        return item.path === path;
      })

      return result === undefined ? null : result.value;
    },
    isCurrentRoute(value) {
      return this.currentRoute() === value;
    }
  }
}
</script>
