<template>
  <div class="filter-select">

    <div class="radio-groups">

      <div class="group">
        <div class="title">Identificador</div>
        <div class="value-box">
          <input class="form-control" type="text" v-model="form.id.value"/>
        </div>
      </div>
    </div>
    <div :class="{ 'radio-groups':true, 'group-opacity': form.id.value }">
      <div class="group">
        <div class="title">Valor</div>
        <div class="value-box">
          <app-input-money v-model="form.value.value" :show-nullable="true"></app-input-money>

          <!--          <input v-model="form.value.value" />-->
        </div>
      </div>


      <div class="group">
        <div class="title">Código do Cupom</div>
        <div class="value-box">
          <input class="form-control" type="text" v-model="form.couponCode.value"/>
        </div>
      </div>
      <div class="group">
        <div class="title">Conta Bancária</div>

        <app-multiselect
          id="ipt-bank_account_id"
          placeholder="Selecione a Conta Bancária"
          v-model="multiselect.bankAccount.selected"
          :options="multiselect.bankAccount.options"
          :searchable="true"
          :allow-empty="true"
          :close-on-select="true"
          track-by="id"
          :multiple="true"
          class="slt-w-max"
          :custom-label="(item)=> { return BankService.getName(item.bank_code) + '-' + item.account + '-' + item.agency }"
          @input="bankAccountInput"
        >
          <template slot="singleLabel" slot-scope="props">
            <div class="option__bank-account_bank-logo">
              <img :src="BankService.bankIcon(props.option.bank_code)">
            </div>
            <div class="option__bank-detail">
              <div class="option__bank-account_detail">
                <div class="option__bank-account_agency">
                  {{
                    props.option.agency +
                    (props.option.agency_check
                    !== null ? '-' +
                      props.option.agency_check : '')
                  }}
                </div>
                <div class="option__bank-account_account">{{
                    props.option.account +
                    (props.option.account_check !== null ? '-' +
                      props.option.account_check : '')
                  }}
                </div>
              </div>
              <div class="option__bank-bank_name">
                <div>{{ BankService.getName(props.option.bank_code) }}</div>
              </div>
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__bank-account_bank-logo">
              <img :src="BankService.bankIcon(props.option.bank_code)">
            </div>
            <div class="option__bank-detail">
              <div class="option__bank-account_detail">
                <div class="option__bank-account_agency">
                  {{
                    props.option.agency +
                    (props.option.agency_check
                    !== null ? '-' +
                      props.option.agency_check : '')
                  }}
                </div>
                <div class="option__bank-account_account">{{
                    props.option.account +
                    (props.option.account_check !== null ? '-' +
                      props.option.account_check : '')
                  }}
                </div>
              </div>
              <div class="option__bank-bank_name">
                <div>{{ BankService.getName(props.option.bank_code) }}</div>
              </div>
            </div>
            <span class="status"></span>
          </template>
        </app-multiselect>
      </div>
      <div class="group">
        <div class="title">Número Lançamento</div>

        <div class="value-box">
          <input class="form-control" type="text" v-model="form.bankLaunchNumber.value"/>
        </div>
      </div>
      <div class="group">
        <div class="title">Histórico de Lançamento</div>
        <div class="description">
          <div>
            Exemplos de Pesquisa:
            <div class="badge-code-container">
              <div class="badge-code">DEPOSITO</div>
              <div class="badge-code">DEPO$</div>
              <div class="badge-code">$EPOSITO</div>
            </div>
          </div>
        </div>
        <div class="value-box">
          <input class="form-control" type="text" v-model="form.bankLaunchHistory.value"/>
        </div>
      </div>
      <div class="group">
        <div class="title">TXID</div>
        <div class="description">
          <div>
            Exemplos de Pesquisa:
            <div class="badge-code-container">
              <div class="badge-code">001000000000311214005890192</div>
              <div class="badge-code">001000000000311214$</div>
              <div class="badge-code">$00100000000031121</div>
              <div class="badge-code">$31121$</div>
            </div>
          </div>
        </div>
        <div class="value-box">
          <input class="form-control" type="text" v-model="form.txid.value"/>
        </div>
      </div>
    </div>
    <div :class="{ 'radio-groups':true, 'group-opacity': form.id.value }">

      <div class="group">
        <div class="title">Ordenação</div>
        <div class="radios">
          <label class="container">Nenhuma
            <input type="radio" name="orderBy" value="empty" v-model="form.orderBy.field"
                   @click="changeOrder(null)">
            <span class="checkmark"></span>
          </label>
          <label class="container">Data
            <input type="radio" name="orderBy" value="date" v-model="form.orderBy.field"
                   @click="changeOrderDefault('desc')">
            <span class="checkmark"></span>
            <div class="sort-direction" v-if="form.orderBy.field==='date'">
              <div class="direction" :class="{'active':form.orderBy.direction === 'asc'}"
                   @click="changeOrder('asc')"><span
                class="icon-arrow-up2"></span></div>
              <div class="direction" :class="{'active':form.orderBy.direction === 'desc'}"
                   @click="changeOrder('desc')"><span
                class="icon-arrow-down2"></span></div>
            </div>
          </label>
          <label class="container">Valor
            <input type="radio" name="orderBy" value="value" v-model="form.orderBy.field"
                   @click="changeOrderDefault('asc')">
            <span class="checkmark"></span>
            <div class="sort-direction" v-if="form.orderBy.field==='value'">
              <div class="direction" :class="{'active':form.orderBy.direction === 'asc'}"
                   @click="changeOrder('asc')"><span
                class="icon-arrow-up2"></span></div>
              <div class="direction" :class="{'active':form.orderBy.direction === 'desc'}"
                   @click="changeOrder('desc')"><span
                class="icon-arrow-down2"></span></div>
            </div>
          </label>
        </div>
      </div>
      <div class="group">
        <div class="title">Conciliação com Origem</div>
        <div class="radios">
          <label class="container">Todos
            <input type="radio" name="conciliation" :value="null" v-model="form.conciliation.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">Conciliado
            <input type="radio" name="conciliation" :value="1" v-model="form.conciliation.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">Não Conciliado
            <input type="radio" name="conciliation" :value="0" v-model="form.conciliation.value">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="group">
        <div class="title">Tipo de Categoria</div>
        <div class="radios">
          <label class="container">Todos
            <input type="radio" name="bankLaunchCategoryType" :value="null"
                   v-model="form.bankLaunchCategoryType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">Débito
            <input type="radio" name="bankLaunchCategoryType" :value="'D'"
                   v-model="form.bankLaunchCategoryType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">Crédito
            <input type="radio" name="bankLaunchCategoryType" :value="'C'"
                   v-model="form.bankLaunchCategoryType.value">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="group">
        <div class="title">Tipo de Lançamento</div>
        <div class="radios">
          <label class="container">Todos
            <input type="radio" name="bankLaunchType" :value="null"
                   v-model="form.bankLaunchType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ BankLaunchTypeEnum.props[BankLaunchTypeEnum.DepositMoney].name }}
            <input type="radio" name="bankLaunchType" :value="BankLaunchTypeEnum.DepositMoney"
                   v-model="form.bankLaunchType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ BankLaunchTypeEnum.props[BankLaunchTypeEnum.DepositChargeback].name }}
            <input type="radio" name="bankLaunchType" :value="BankLaunchTypeEnum.DepositChargeback"
                   v-model="form.bankLaunchType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ BankLaunchTypeEnum.props[BankLaunchTypeEnum.DepositBankCheck].name }}
            <input type="radio" name="bankLaunchType" :value="BankLaunchTypeEnum.DepositBankCheck"
                   v-model="form.bankLaunchType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ BankLaunchTypeEnum.props[BankLaunchTypeEnum.PixReceived].name }}
            <input type="radio" name="bankLaunchType" :value="BankLaunchTypeEnum.PixReceived"
                   v-model="form.bankLaunchType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ BankLaunchTypeEnum.props[BankLaunchTypeEnum.Ted].name }}
            <input type="radio" name="bankLaunchType" :value="BankLaunchTypeEnum.Ted"
                   v-model="form.bankLaunchType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ BankLaunchTypeEnum.props[BankLaunchTypeEnum.Doc].name }}
            <input type="radio" name="bankLaunchType" :value="BankLaunchTypeEnum.Doc"
                   v-model="form.bankLaunchType.value">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="group">
        <div class="title">Tipo de importação</div>
        <div class="radios">
          <label class="container">Todos
            <input type="radio" name="originType" :value="null"
                   v-model="form.originType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ OriginTypeEnum.props[OriginTypeEnum.BankStatementFile].name }}
            <input type="radio" name="originType" :value="OriginTypeEnum.BankStatementFile"
                   v-model="form.originType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ OriginTypeEnum.props[OriginTypeEnum.DepositFile].name }}
            <input type="radio" name="originType" :value="OriginTypeEnum.DepositFile"
                   v-model="form.originType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ OriginTypeEnum.props[OriginTypeEnum.PixFile].name }}
            <input type="radio" name="originType" :value="OriginTypeEnum.PixFile"
                   v-model="form.originType.value">
            <span class="checkmark"></span>
          </label>
          <label class="container">{{ OriginTypeEnum.props[OriginTypeEnum.PixApi].name }}
            <input type="radio" name="originType" :value="OriginTypeEnum.PixApi"
                   v-model="form.originType.value">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="group">
        <div class="title">Data Ocorrência</div>
        <div class="radios">
          <label class="container">Todos
            <input type="radio" name="date" :value="null" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <label class="container">Ontem
            <input type="radio" name="date" value="yesterday" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <label class="container">Semana Passada
            <input type="radio" name="date" value="week" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <label class="container">Mês Passado
            <input type="radio" name="date" value="month" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <label class="container">Personalizado
            <input type="radio" name="date" value="custom" v-model="form.date.period">
            <span class="checkmark"></span>
          </label>
          <div class="custom-date" v-if="form.date.custom===true">
            <div>
              <span>De</span>
              <input type="date" class="input-text" v-model="form.customDate.from">
            </div>
            <div>
              <span>até</span>
              <input type="date" class="input-text" v-model="form.customDate.to">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import _ from 'lodash'
import filterSelectMixin from './mixins/filter-select'
import AppMultiselect from '/src/components/multiselect'
import moment from 'moment'
import AppInputMoney from '/src/components/input-money/component'
import BankAccountProvider from '/src/features/settings/components/register/providers/bank-accounts'
import BankService from '/src/utils/icon-banks'
import OriginTypeEnum from '/src/enums/origin-type'
import BankLaunchTypeEnum from '/src/enums/bank-launch-type'

export default {
  components: {
    AppMultiselect,
    AppInputMoney
  },

  mixins: [
    filterSelectMixin
  ],

  async mounted() {
    //console.log('mounted')
    // //console.log(this.filterSelectBank)

    await this.multiselectBankAccountsPreLoad()
    this.loadState()

    //console.log('form:after', JSON.parse(JSON.stringify(this.form)))
    if (this.form.bankAccount.value.length > 0) {
      this.multiselectBankAccountsByIdAndSelected(this.form.bankAccount.value[0])
      console.log('filter-select-bank:apply', JSON.parse(JSON.stringify(this.form)))
      this.$emit('apply')
    }
  },

  data() {
    return {
      BankService,
      OriginTypeEnum,
      BankLaunchTypeEnum,
      multiselect: {
        bankAccount: {
          loading: false,
          selected: null,
          options: []
        }
      },
      // form_filtered: false,
      form: {
        conciliation: {
          field: 'origin_is_conciliated',
          type: 'bool',
          operator: 'eq',
          value: null
        },
        bankLaunchCategoryType: {
          field: 'bank_launch_category_type',
          type: 'string',
          operator: 'eq',
          value: null
        },
        originType: {
          field: 'origin_type',
          type: 'int',
          operator: 'eq',
          value: null
        },
        bankLaunchType: {
          field: 'bank_launch_type_id',
          type: 'int',
          operator: 'eq',
          value: null
        },
        date: {
          field: 'date',
          type: 'date',
          operator: 'bt',
          value: this.getDate('week'),
          custom: false,
          period: 'week'
        },
        customDate: {
          from: null,
          to: null
        },
        orderBy: {
          field: 'empty',
          direction: null
        },
        value: {
          field: 'value',
          type: 'float',
          operator: 'eq',
          value: null
        },
        bankLaunchNumber: {
          field: 'bank_launch_number',
          type: 'string',
          operator: 'eq',
          value: null
        },
        bankLaunchHistory: {
          field: 'bank_launch_history',
          type: 'string',
          operator: 'like',
          value: null
        },
        txid: {
          field: 'txid',
          type: 'string',
          operator: 'like',
          value: null
        },
        couponCode: {
          field: 'coupon_code',
          type: 'string',
          operator: 'eq',
          value: null
        },
        id: {
          field: 'id',
          type: 'string',
          operator: 'eq',
          value: null
        },
        bankAccount: {
          field: 'bank_account_id',
          type: 'int',
          operator: 'in',
          value: []
        }
      }
    }
  },

  computed: {
    ...mapState('filters', ['filterSelectBank'])
  },

  methods: {
    ...mapActions('filters', ['updateFilterSelectBank']),
    saveState() {
      console.log('filter-select-bank:saveState:form', JSON.parse(JSON.stringify(this.form)))

      let queryObject = {
        where: [],
        orderBy: []
      }

      if (!(this.form.id.value === null || this.form.id.value === '')) {
        queryObject.where.push(this.form.id)
        delete queryObject['orderBy']
      } else {
        // Conciliation Object
        if (this.form.conciliation.value != null) {
          queryObject.where.push(this.form.conciliation)
        }
        // // BankId Object
        // if (this.form.bankCode.value != null && this.form.bankCode.value.length > 0) {
        //     queryObject.where.push(this.form.bankCode)
        // }
        // BankId Object
        if (this.form.bankAccount.value !== null && this.form.bankAccount.value.length > 0) {
          queryObject.where.push(this.form.bankAccount)
        }

        if (!(this.form.bankLaunchNumber.value === null || this.form.bankLaunchNumber.value === '')) {
          queryObject.where.push(this.form.bankLaunchNumber)
        }

        if (!(this.form.originType.value === null || this.form.originType.value === '')) {
          queryObject.where.push(this.form.originType)
        }

        if (!(this.form.bankLaunchType.value === null || this.form.bankLaunchType.value === '')) {
          queryObject.where.push(this.form.bankLaunchType)
        }

        if (!(this.form.bankLaunchHistory.value === null || this.form.bankLaunchHistory.value === '')) {
          queryObject.where.push(this.form.bankLaunchHistory)
        }

        if (!(this.form.txid.value === null || this.form.txid.value === '')) {
          queryObject.where.push(this.form.txid)
        }

        if (!(this.form.couponCode.value === null || this.form.couponCode.value === '')) {
          queryObject.where.push(this.form.couponCode)
        }

        // bankLaunchCategoryType Object
        if (this.form.bankLaunchCategoryType.value != null) {
          queryObject.where.push(this.form.bankLaunchCategoryType)
        }

        // Date Object
        if (this.form.date.period !== null && this.form.date.period !== 'custom') {
          queryObject.where.push(this.form.date)
        } else if (this.form.date.custom === true && this.form.customDate.from && this.form.customDate.to) {
          let dateClone = _.clone(this.form.date, true)
          dateClone.value = []
          dateClone.value.push(this.form.customDate.from)
          dateClone.value.push(this.form.customDate.to)
          queryObject.where.push(dateClone)
        }
        // Value Object
        //console.log('this.form.value.value', JSON.parse(JSON.stringify(this.form.value.value)))
        if (this.form.value.value != null && this.form.value.value) {
          //console.log('salvou: ', JSON.parse(JSON.stringify(this.form)))
          queryObject.where.push(this.form.value)
        }
        // Sort/OrderBy Object
        queryObject.orderBy.push(this.form.orderBy)
        // Check if queryObject has 'where'
        if (queryObject.where.length < 1) {
          delete queryObject.where
        }

        // remove orderBy if empty
        if (queryObject.orderBy[0] !== undefined && queryObject.orderBy[0].field === 'empty') {
          delete queryObject['orderBy']
        }
      }
      // Save the filter on state

      //console.log('updateFilterSelectBank', queryObject)
      this.updateFilterSelectBank(queryObject)
    },
    loadState() {
      //console.log('loadState')
      if (this.filterSelectBank.where && this.filterSelectBank.where.length > 0) {
        // //console.log('loadState')
        // //console.log(this.filterSelectBank.where)

        let newForm = _.cloneDeep(this.form)

        for (let item of this.filterSelectBank.where) {
          switch (item.field) {
            case 'origin_is_conciliated':
              newForm.conciliation.value = item.value
              break
            case 'destination_type_id':
              newForm.destination.value = item.value
              break
            case 'value':
              newForm.value.value = item.value
              break
            case 'bank_launch_category_type':
              newForm.bankLaunchCategoryType.value = item.value
              break
            // case 'bank_launch_number':
            //     newForm.bankLaunchNumber.value = item.value
            //     break
            // case 'bank_launch_history':
            //     newForm.bankLaunchHistory.value = item.value
            //     break
            case 'date':
              if (item.custom) {
                newForm.date = item
                newForm.customDate.from = moment(item.value[0]).format('YYYY-MM-DD')
                newForm.customDate.to = moment(item.value[1]).format('YYYY-MM-DD')
              } else {
                newForm.date = item
              }
              break
            case 'bank_account_id':
              newForm.bankAccount.value = item.value
              break
          }
        }
        this.form = newForm
      }

      if (this.filterSelectBank.orderBy && this.filterSelectBank.orderBy.length > 0) {
        // Load Order
        this.form.orderBy = this.filterSelectBank.orderBy[0]
      }

      this.loadRouteFilter()
    },
    async multiselectBankAccountsPreLoad() {
      let self = this
      const res = await BankAccountProvider.get()
      self.multiselect.bankAccount.options = _.orderBy(res.data, 'bank_code')
    },
    multiselectBankAccountsByIdAndSelected(id) {
      //console.log('multiselect');
      //console.log('this.multiselect.bankAccount.options', this.multiselect.bankAccount.options)
      if (this.multiselect.bankAccount.options.length === 0) {
        return
      }

      let item = _.find(this.multiselect.bankAccount.options, (e) => e.id === id)
      //console.log('multiselect:item', item)
      if (item !== undefined) {
        this.multiselect.bankAccount.selected = item
      }
    },
    bankAccountInput(selected) {
      this.form.bankAccount.value = selected.length > 0 ? _.map(selected, 'id') : []
    },

  }
}
</script>
