<template>
  <div class="settings-others">
    <!--<section>-->
    <!--<div class="title">Visualização de Lojas</div>-->
    <!--<div class="form-group">-->
    <!--<div class="group">-->
    <!--<label class="mx-auto">Modo {{viewMode}}</label>-->
    <!--<app-toggle v-model="form.viewMode"></app-toggle>-->
    <!--</div>-->
    <!--<div class="group">-->
    <!--<label class="mx-auto">Lojas sem transações</label>-->
    <!--<app-toggle v-model="form.showNoTransactions"></app-toggle>-->
    <!--</div>-->
    <!--</div>-->
    <!--</section>-->
    <section>
      <div class="title">Visualização de Lojas - Alerta</div>
      <div class="form-group">
        <div class="group">
          <label class="mx-auto">Transações conciliadas em %</label>
          <input v-model="form.conciliationPercent" class="page-items form-control mx-auto" type="number"
                 min="1">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import AppToggle from '/src/components/toggle'
import Settings from '/src/utils/settings'

export default {
  components: {
    // AppToggle
  },

  created() {
    this.settings = Settings.getSettings()
    this.form.viewMode = this.settings.general.instVision.viewMode
    this.form.showNoTransactions = this.settings.general.instVision.showNoTransactions
    this.form.conciliationPercent = this.settings.general.instVision.conciliationPercent
  },

  data: function () {
    return {
      settings: null,
      form: {
        viewMode: true,
        showNoTransactions: false,
        conciliationPercent: 50
      }
    }
  },

  computed: {
    viewMode: function () {
      if (this.form.viewMode) return 'Grid'
      return 'Lista'
    }
  },

  watch: {
    'form': {
      handler: function () {
        this.settings.general.instVision = this.form
        Settings.setSettings(this.settings)
      },
      deep: true
    }
  }

}
</script>
