<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submit">

      <div class="form-group">
        <label for="institution-name">Nome do Estabelecimento</label>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <input id="institution-name"
                 type="text"
                 v-model="institution.name"
                 class="form-control">
          <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="form-group">
        <label for="institution-type">Tipo de Estabelecimento</label>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <select id="institution-type" v-model="institution.institution_type" class="form-control">
            <option v-for="(item,index) in InstitutionTypeEnum.props" :key="index" :value="index">
              {{ item.name }}
            </option>
          </select>
          <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="form-group">
        <label for="number-type">Tipo de registro</label>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <select id="number-type" v-model="institution.number_type" class="form-control" @change="numberOptions()">
            <option v-for="(item,index) in NumberTypeEnum.props" :key="index" :value="index">
              {{ item.name }}
            </option>
          </select>
          <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div v-if="institution.number_type != null" class="form-group">
        <label for="number">Número</label>
        <ValidationProvider rules="required">
          <cleave v-model="institution.number" :options="numberOption" class="form-control"
                  placeholder="Número"></cleave>
        </ValidationProvider>
      </div>

      <div class="form-group">
        <label for="institution-code">Código</label>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <input id="institution-code"
                 type="text"
                 v-model="institution.code"
                 class="form-control">
          <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div v-if="isEdit && institution.parentObj != null" class="form-group">
        <label for="institution-code">Estabelecimento Pai</label>
        <input id="parent"
               type="text"
               v-model="institution.parentObj.name"
               class="form-control"
               readonly>
        <div class="text-right">
          <button type="button" @click="allowChangeParent()" class="btn btn-primary btn-sm">Alterar</button>
        </div>
      </div>

      <div v-if="institution.parentObj == null" class="form-group">
        <label for="institution-code">Estabelecimento Pai</label>
        <app-multiselect v-model="multiselect.institutions.selected"
                         :options="multiselect.institutions.options"
                         :loading="multiselect.institutions.loading"
                         @input="setInstitutionParent"
                         id="ajax"
                         label="name"
                         track-by="id"
                         placeholder="Pesquise o estabelecimento"
                         :multiple="false"
                         :searchable="true"
                         :clear-on-select="false"
                         :close-on-select="true"
                         :internal-search="true"
                         class="slt-w-max">
        </app-multiselect>

      </div>

      <button class="btn btn-primary btn-block" :disabled="invalid">salvar</button>
    </form>
  </ValidationObserver>
</template>

<script>
import InstitutionTypeEnum from '/src/enums/institution-type'
import NumberTypeEnum from '/src/enums/number-type'
import Cleave from 'vue-cleave-component'
import InstitutionsProvider from '/src/features/settings/components/register/providers/institutions.js'
import AppMultiselect from '/src/components/multiselect'

export default {
  name: 'app-institution-form',
  components: {
    Cleave,
    AppMultiselect
  },
  data() {
    return {
      isEdit: false,
      institution: {
        id: null,
        name: null,
        number_type: 3,
        number: null,
        institution_type: 4,
        code: null,
        parentId: null,
        parentObj: null
      },
      InstitutionTypeEnum: InstitutionTypeEnum,
      NumberTypeEnum: NumberTypeEnum,
      numberOption: {},
      multiselect: {
        institutions: {
          options: [],
          selected: [],
          debouncing: null,
          loading: false
        }
      }
    }
  },
  props: {
    institutionId: String
  },
  methods: {
    submit() {
      if (this.isEdit) {
        this.update()
      } else {
        this.create()
      }
    },

    numberOptions() {
      this.institution.number = null;
      switch (parseInt(this.institution.number_type)) {
        case 3:
          this.numberOption = {
            numericOnly: true,
            delimiters: ['.', '.', '/', '-'],
            blocks: [2, 3, 3, 4, 2]
          }
          break;
        default:
          this.numberOption = {
            numericOnly: true,
            blocks: [30]
          }
      }
    },

    getInstitutionById(id) {

      InstitutionsProvider.findById(id)
        .then((response) => {

          this.institution.id = response.data.id
          this.institution.name = response.data.name
          this.institution.number_type = response.data.number_type_id
          this.institution.number = response.data.number
          this.institution.institution_type = response.data.institution_type_id
          this.institution.code = response.data.code
          this.institution.parentId = response.data.parent_id
          this.institution.parentObj = response.data.parentObj

        })
        .finally(() => {

        })
    },

    create() {
      InstitutionsProvider.create(this.institution)
        .then((response) => {
          self.response = response.data
          alert("Registro inserido com sucesso")
        })
        .finally(() => {
          this.$router.push({name: 'settings-institution-index'})
        })
    },

    update() {
      InstitutionsProvider.update(this.institution)
        .then((response) => {
          self.response = response.data
          alert("Registro alterado com sucesso")
        })
        .finally(() => {
          this.$router.push({name: 'settings-institution-index'})
        })
    },

    multiselectInstitutionPreLoad() {
      let self = this
      InstitutionsProvider.get({pagination: {limit: 5, page: 1}})
        .then(res => {
          self.multiselect.institutions.options = res.data
        })
    },

    setInstitutionParent: function () {
      if (this.multiselect.institutions.selected == null) {
        this.institution.parentId = null
        return
      }

      if (this.multiselect.institutions.selected.id != this.institution.id) {
        this.institution.parentId = this.multiselect.institutions.selected.id
        return
      }

      this.multiselect.institutions.selected = null
      this.institution.parentId = null
    },

    allowChangeParent() {
      this.institution.parentId = null;
      this.institution.parentObj = null;
    }

  },

  mounted() {
    this.multiselectInstitutionPreLoad()
    this.numberOptions();
    if (this.institutionId !== undefined) {
      this.isEdit = true;
      this.getInstitutionById(this.institutionId)
    }
  }
}
</script>
