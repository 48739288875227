import moment from 'moment'
import options from './options'

const mapProperty = options.mapProperty
const separator = options.separator

const convertDatetime = (type, value) => {
  let formatDatetime = null
  if (type === 'datetime') {
    formatDatetime = 'YYYYMMDDHHmmss'
  } else if (type === 'date') {
    formatDatetime = 'YYYYMMDD'
  } else if (type === 'time') {
    formatDatetime = 'HHmmss'
  }

  if (value instanceof Date) {
    value = moment(value).format(formatDatetime)
  } else if (moment.isMoment(value)) {
    value = value.format(formatDatetime)
  } else {
    value = moment(value).format(formatDatetime)
  }

  return value
}

const convertBool = (type, value) => {
  return value ? 1 : 0
}

const convertString = (type, value) => {
  return value.toString()
}

const convertInt = (type, value) => {
  return parseInt(value)
}

const convertFloat = (type, value) => {
  return parseFloat(value)
}

const convertValues = (element, callbackFormatter) => {
  if (Array.isArray(element.value)) {
    element.value.forEach((value, index, list) => {
      list[index] = callbackFormatter(element.type, value)
    })
    return
  }

  element.value = callbackFormatter(element.type, element.value)
}
const convertElement = (element, type, callbackFormatter) => {
  if (element.type !== type) {
    return
  }

  convertValues(element, callbackFormatter)
}

const convertTypeWhere = (element) => {
  convertElement(element, 'string', convertString)
  convertElement(element, 'date', convertDatetime)
  convertElement(element, 'datetime', convertDatetime)
  convertElement(element, 'time', convertDatetime)
  convertElement(element, 'bool', convertBool)
  convertElement(element, 'int', convertInt)
  convertElement(element, 'float', convertFloat)
}

/**
 * Convert object filters to string url
 *
 * @param filters
 * @returns {string}
 */
const serialize = (filters) => {
  let resultFilters = []

  Object.keys(filters).forEach((keyFilter) => {
    let resultObjects = []
    filters[keyFilter].forEach((element) => {
      let resultElements = []

      if (keyFilter === 'where') {
        convertTypeWhere(element)
      }

      Object.keys(element).forEach((keyProperty) => {
        let values = element[keyProperty]
        values = Array.isArray(values) ? values.join(separator.value) : values
        let property = mapProperty[keyProperty] !== undefined ? mapProperty[keyProperty] : keyProperty

        resultElements.push(property + separator.property + values)
      })

      resultObjects.push(resultElements.join(separator.element))
    })

    resultFilters.push(keyFilter + '=' + resultObjects.join(separator.object))
  })

  return resultFilters.join(separator.filter)
}

export default serialize
