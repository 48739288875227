<template>
  <div v-if="!isEmpty()" class="list-statistics">
    <div class="item">
      <div class="title">quantidade</div>
      <div class="value">{{ list.meta.pagination.total|integer }}</div>
    </div>
    <div class="item">
      <div class="title">valor total</div>
      <div class="value">R$ {{ list.meta.custom.sum_transaction_value|money }}</div>
    </div>
    <div class="item">
      <div class="title">valor máximo</div>
      <div class="value">R$ {{ list.meta.custom.max_transaction_value|money }}</div>
    </div>
    <div class="item">
      <div class="title">valor mínimo</div>
      <div class="value">R$ {{ list.meta.custom.min_transaction_value|money }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['list'],
  methods: {
    isEmpty() {
      return !(this.list && this.list.data.length)
    }
  }
}
</script>
