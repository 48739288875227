import moment, * as moments from 'moment-timezone';
import 'moment/dist/locale/pt-br';

const dateFormat = (value, format, tz1 = 'UTC', tz2 = 'UTC') => {
  if (!value) {
    return value;
  }
  return moments.tz(String(value), tz1)
    .tz(tz2)
    .format(format)
};

const date = (value, tz1 = 'UTC', tz2 = 'UTC') => {
  if (!value) {
    return value;
  }

  return moments.tz(String(value), tz1)
    .tz(tz2)
    .format('DD/MM/YYYY')
};

const dateTime = (value, tz1 = 'UTC', tz2 = 'UTC') => {
  if (!value) {
    return value;
  }
  return moments.tz(String(value), tz1)
    .tz(tz2)
    .format('DD/MM/YYYY HH:mm:ss')
};

const time = (value, tz1 = 'UTC', tz2 = 'UTC') => {
  if (!value) {
    return value;
  }
  return moments.tz(String(value), tz1)
    .tz(tz2)
    .format('HH:mm:ss')
}

const fromNow = (value, tz1 = 'UTC', tz2 = 'UTC') => {
  if (!value) {
    return value;
  }

  return moments.tz(String(value), tz1)
    .tz(tz2)
    .locale('pt-br')
    .fromNow();
}

const fromNowLight = (date) => {
  const now = moment()
  const actual = moments.tz(date, 'UTC')
  const diff = now.diff(actual, 'days')

  const month = 31
  if (diff <= month) {
    return diff + 'd'
  }

  const year = 365
  if (diff <= year) {
    return Math.ceil(diff / month) + 'm'
  }

  return Math.ceil(diff / year) + 'a'
}

export default {
  fromNow,
  fromNowLight,
  date,
  dateFormat,
  dateTime,
  time,
}
