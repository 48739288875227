import moment from 'moment'
import options from './options'

const mapProperty = options.mapProperty
const separator = options.separator

const convertInt = (type, value) => {
  return parseInt(value)
}

const convertDatetime = (type, value) => {
  let formatIn = null
  let formatOut = null
  if (type === 'datetime') {
    formatIn = 'YYYYMMDDHHmmss'
    formatOut = 'YYYY-MM-DD HH:mm:ss'
  } else if (type === 'date') {
    formatIn = 'YYYYMMDD'
    formatOut = 'YYYY-MM-DD'
  } else if (type === 'time') {
    formatIn = 'HHmmss'
    formatOut = 'HH:mm:ss'
  }

  return moment(value, formatIn).format(formatOut)
}

const convertBool = (type, value) => {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
}

function unserialize(plainText) {
  let objects = []
  let filters = plainText.split(separator.object)
  for (let filter of filters) {
    let obj = {
      field: null,
      operator: null,
      type: null,
      value: null
    }

    let elements = filter.split(separator.element)

    for (let element of elements) {
      let property = element.split(separator.property)

      switch (property[0]) {
        case mapProperty.field:
          obj.field = property[1]
          break
        case mapProperty.operator:
          obj.operator = property[1]
          break
        case mapProperty.type:
          obj.type = property[1]
          break
        case mapProperty.value: {
          let values = property[1].split(separator.value)

          /** Convert values to they respective type **/
          if (obj.type === 'int') {
            for (let i in values) {
              values[i] = convertInt(obj.type, values[i])
            }
          } else if (obj.type === 'date') {
            for (let i in values) {
              values[i] = convertDatetime(obj.type, values[i])
            }
          } else if (obj.type === 'bool') {
            for (let i in values) {
              values[i] = convertBool(obj.type, values[i])
            }
          }

          //TODO: verificar isso aqui,
          if (values.length > 1) {
            obj.value = values
          } else {
            obj.value = [values[0]]
          }

          break
        }
      }
    }
    objects.push(obj)
  }
  return objects
}

export default unserialize
