export default {
  SET_ERP(state, payload) {
    state.transactionsErp = payload
  },
  APPEND_ERP(state, payload) {
    state.transactionsErp.data = state.transactionsErp.data.concat(payload.data)
    state.transactionsErp.meta = payload.meta
  },
  CLEAR_ERP(state) {
    state.transactionsErp = null
  },
  SET_TRANSACTION_STRONGBOX(state, payload) {
    state.transactionsStrongbox = payload
  },
  APPEND_TRANSACTION_STRONGBOX(state, payload) {
    state.transactionsStrongbox.data = state.transactionsStrongbox.data.concat(payload.data)
    state.transactionsStrongbox.meta = payload.meta
  },
  SET_TRANSACTION_BANK(state, payload) {
    state.transactionsBank = payload
  },
  APPEND_TRANSACTION_BANK(state, payload) {
    state.transactionsBank.data = state.transactionsBank.data.concat(payload.data)
    state.transactionsBank.meta = payload.meta
  },
  SET_TRANSACTION_INSTITUTION(state, payload) {
    state.transactionsInstitution = payload
  },
  APPEND_TRANSACTION_INSTITUTION(state, payload) {
    state.transactionsInstitution.data = state.transactionsInstitution.data.concat(payload.data)
    state.transactionsInstitution.meta = payload.meta
  },
}
