const items = {
  Money: 1,
  BankCheck: 2,
  BankCheckPreDated: 3,
  Pix: 4,
  props: {
    1: {name: 'Dinheiro'},
    2: {name: 'Cheque a vista'},
    3: {name: 'Cheque pré-datado'},
    4: {name: 'Pix'}
  }
}

export default Object.freeze(items)
