<template>
  <div>
    <app-table-list>
      <table class="table-list text-center">
        <thead>
        <tr>
          <!--<th class="text-left" style="width: 140px;">Id</th>-->
          <th>Data</th>
          <th class="text-right">Valor</th>
          <th>Destino Depósito</th>
          <th>Tipo de numerário</th>
          <th>Cod. Cofre</th>
          <th>Banco</th>
          <th>Conta</th>
          <th>Agência</th>
          <th>Estabelecimento</th>
          <th>Cod. Estabelecimento</th>
          <th>Nº Estabelecimento</th>
          <th></th>
        </tr>
        </thead>
        <tbody v-if="transactionsErp != null">
        <tr class="text-center" v-for="(item, index) in transactionsErp.data" :key="index">
          <td>{{ item.date | formatDateTime }}</td>
          <td class="text-right">{{ parseFloat(item.value) | money }}</td>
          <td>{{ DestinationTypeEnum.props[item.destination_type_id].name }}</td>
          <td>{{ CashTypeEnum.props[item.cash_type_id].name }}</td>
          <td>{{ item.strongbox_code | strEmpty }}</td>
          <td>{{ item.bank_name | strEmpty }}</td>
          <td>{{ item.bank_account | strEmpty }}</td>
          <td>{{ item.bank_agency | strEmpty }}</td>
          <td>{{ item.institution_origin_name | strEmpty }}</td>
          <td>{{ item.institution_origin_code | strEmpty }}</td>
          <td>{{ item.institution_origin_number }}</td>
          <td class="td-action">
            <div class="btn-action">
              <router-link :to="'/transacao/erp/'+item.id">
                <span class="icon-more_horiz"></span>
              </router-link>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </app-table-list>
  </div>
</template>

<script>
import AppTableList from '/src/components/table-list'
import DestinationTypeEnum from '/src/enums/destinations-type'
import CashTypeEnum from '/src/enums/cash-type'

export default {
  props: ['transactionsErp'],

  components: {
    AppTableList
  },

  data() {
    return {
      DestinationTypeEnum: DestinationTypeEnum,
      CashTypeEnum: CashTypeEnum
    }
  }
}
</script>
