<template>
  <div>
    <div class="card5-item-header">
      <!--<div class="title"><span class="icon-local_shipping"></span>Transportadora</div>-->
      <div class="subtitle">
        <!--<span class="icon-local_shipping"></span>-->
        Transportadora
      </div>
    </div>
    <div class="card5-item-body">
      <div class="row">
        <div class="item col-sm-6">
          <div class="title">Nome</div>
          <div class="value">{{ transporterInstitution.name }}</div>
        </div>
        <div class="item col-sm-6">
          <div class="title">Código</div>
          <div class="value">{{ transporterInstitution.code }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['transporterInstitution']
}
</script>
