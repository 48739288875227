<template>
  <app-layout-site>
    <div class="transacao-list">
      <div class="card5">
        <h1>Transações</h1>
        <ul class="tabs">
          <li :class="{'active': isRouteCurrentName('transactions-erp-index')}">
            <router-link :to="{name: 'transactions-erp-index'}">
              <a>ERP</a>
            </router-link>
          </li>
          <li :class="{'active': isRouteCurrentName('transactions-strongbox-index')}">
            <router-link :to="{name: 'transactions-strongbox-index'}">
              <a>Cofre</a>
            </router-link>
          </li>
          <li :class="{'active': isRouteCurrentName('transactions-institution-index')}">
            <router-link :to="{name: 'transactions-institution-index'}">
              <a>Matriz</a>
            </router-link>
          </li>
          <li :class="{'active': isRouteCurrentName('transactions-bank-index')}">
            <router-link :to="{name: 'transactions-bank-index'}">
              <a>Banco</a>
            </router-link>
          </li>
        </ul>
        <router-view></router-view>
      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'

export default {
  components: {
    AppLayoutSite
  },
  methods: {
    isRouteCurrentName(name) {
      return this.$route.name === name
    },
  }
}
</script>
