<template>
  <div class=" transacao-list">
    <div class="card5">
      <div class="container">
        <div class="card-form">
          <h3>Novo Estabelecimento</h3>
          <institution-form/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import InstitutionForm from './InstitutionForm'

export default {
  name: 'app-create-form',
  components: {
    InstitutionForm
  }
}
</script>
