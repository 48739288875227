<template>
  <div :class="loading ? 'loader-2' : ''">
    <div class="loader-2-progress">
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>
