/* eslint-disable no-template-curly-in-string */
import {Provider} from '/src/parents/provider'
import axios from 'axios'
import authentication from '/src/features/auth/tasks/authentication'

class RelatorioProvider extends Provider {
  erpMoneyBankPDF(payload) {
    let url = `${process.env.VUE_APP_API_URL}/v1/reports/erp-money-bank-pdf`
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authentication.getToken(),
      },
      responseType: 'blob',
    }

    return axios.post(url, payload, options)
  }

  erpMoneyBankXLS(payload) {
    let url = `${process.env.VUE_APP_API_URL}/v1/reports/erp-money-bank-xls`
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authentication.getToken(),
      },
      responseType: 'blob',
    }

    return axios.post(url, payload, options)
  }

  erpPixBankXLS(payload) {
    let url = `${process.env.VUE_APP_API_URL}/v1/reports/erp-pix-bank-xls`
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authentication.getToken(),
      },
      responseType: 'blob',
    }

    return axios.post(url, payload, options)
  }

  erpBankCheckBankXLS(payload) {
    let url = `${process.env.VUE_APP_API_URL}/v1/reports/erp-bankcheck-bank-xls`
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authentication.getToken(),
      },
      responseType: 'blob',
    }

    return axios.post(url, payload, options)
  }

  reportFormat02ErpMoneyBankCsv(payload) {

    let url = `${process.env.VUE_APP_API_URL}/v1/reports/format-02/erp-money-bank-csv`

    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': authentication.getToken(),
      },
      responseType: 'blob',
    }

    return axios.post(url, payload, options)
  }

}

export default new RelatorioProvider()
