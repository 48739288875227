/* eslint-disable no-unused-vars */
// PROVIDERS
import instProvider from '../providers/institutions'
import bankProvider from '../providers/banks'
import transportersProvider from '../providers/transporters'
// MUTATIONS

export default {
  updateInstitutions({commit}, payload) {
    payload.pagination.limit = 20
    let list = instProvider.get(payload)
      .then(response => {
        commit('SET_INSTITUTIONS', response)
      })
  },

  appendInstitutions({commit}, payload) {
    let list = instProvider.get(payload)
      .then(response => {
        commit('APPEND_INSTITUTIONS', response)
      })
  },

  updateBanks({commit}, payload) {
    let list = bankProvider.get(payload)
      .then(response => {
        commit('SET_BANKS', response)
      })
  },

  updateTransporters({commit}, payload) {
    let list = transportersProvider.get(payload)
      .then(response => {
        commit('SET_TRANSPORTERS', response)
      })
  }
}
