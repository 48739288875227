<template>
  <app-layout-site>
    <div class="card5">
      <h1>Visões</h1>

      <!--      <div class="header-destination-container">-->
      <!--        <h4>Transações por Loja</h4>-->
      <!--        <router-link :to="{ name: 'visions-erp-money-bank'}" class="header-destination">-->
      <!--          <div class="item">ERP</div>-->
      <!--          <div class="item">Dinheiro</div>-->
      <!--          <div class="item">Banco</div>-->
      <!--        </router-link>-->
      <!--        <router-link :to="{ name: 'visions-erp-pix-bank'}" class="header-destination">-->
      <!--          <div class="item">ERP</div>-->
      <!--          <div class="item">Pix</div>-->
      <!--          <div class="item">Banco</div>-->
      <!--        </router-link>-->
      <!--        <router-link :to="{ name: 'visions-erp-bank-check-bank'}" class="header-destination">-->
      <!--          <div class="item">ERP</div>-->
      <!--          <div class="item">Cheque a vista</div>-->
      <!--          <div class="item">Banco</div>-->
      <!--        </router-link>-->
      <!--        <router-link :to="{ name: 'visions-erp-bank-check-predated-institution'}" class="header-destination">-->
      <!--          <div class="item">ERP</div>-->
      <!--          <div class="item">Cheque pré-datado</div>-->
      <!--          <div class="item">Instituição</div>-->
      <!--        </router-link>-->
      <!--        <router-link :to="{ name: 'visions-erp-money-strongbox'}" class="header-destination">-->
      <!--          <div class="item">ERP</div>-->
      <!--          <div class="item">Dinheiro</div>-->
      <!--          <div class="item">Cofre</div>-->
      <!--        </router-link>-->
      <!--      </div>-->

      <!--      <div class="header-destination-container">-->
      <!--        <h4>Controle</h4>-->
      <!--        <router-link :to="{ name: 'visions-user-modified'}" class="header-destination">-->
      <!--          <div class="item">Tansações</div>-->
      <!--          <div class="item">Modificadas</div>-->
      <!--          <div class="item">Por Loja</div>-->
      <!--        </router-link>-->
      <!--      </div>-->


      <div class="vision-a">
        <router-link :to="{ name: item.router_to}" class="vision-item" v-for="(item,index) of items" :key="index">
          <h4>{{ item.title }}</h4>
          <div class="vision-actions">
            <div class="vision-actions-item">
              <div>
                <img
                  :title="TransactionTypeIconUtil.transactionTypeName(item.origin_type_id)"
                  :src="TransactionTypeIconUtil.transactionTypeIcon(item.origin_type_id)"
                  alt="">
              </div>

            </div>
            <div class="vision-actions-arrow"></div>
            <!--            <div class="vision-actions-item">-->
            <!--              <div>-->
            <!--                <img-->
            <!--                  :title="CashTypeIconUtil.cashTypeName(item.cash_type_id)"-->
            <!--                  :src="CashTypeIconUtil.cashTypeIcon(item.cash_type_id)"-->
            <!--                  alt="">-->
            <!--&lt;!&ndash;                {{ CashTypeIconUtil.cashTypeName(item.cash_type_id) }}&ndash;&gt;-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="vision-actions-arrow"></div>-->
            <div class="vision-actions-item">
              <div>
                <img
                  :title="TransactionTypeIconUtil.transactionTypeName(item.destination_type_id)"
                  :src="TransactionTypeIconUtil.transactionTypeIcon(item.destination_type_id)"
                  alt="">
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'
import TransactionTypeIconUtil from '/src/utils/icon-transaction-type'
import CashTypeIconUtil from '/src/utils/icon-cash-type'
import TransactionTypeEnum from '/src/enums/transaction-type'
import CashTypeEnum from '/src/enums/cash-type'

const items = [
  {
    router_to: 'visions-erp-money-bank',
    title: 'Depositos',
    origin_type_id: TransactionTypeEnum.ERP,
    cash_type_id: CashTypeEnum.Money,
    destination_type_id: TransactionTypeEnum.Bank,
  },
  {
    router_to: 'visions-erp-pix-bank',
    title: 'PIX',
    origin_type_id: TransactionTypeEnum.ERP,
    cash_type_id: CashTypeEnum.Pix,
    destination_type_id: TransactionTypeEnum.Bank,
  },
  {
    router_to: 'visions-erp-bank-check-bank',
    title: 'Cheque à vista',
    origin_type_id: TransactionTypeEnum.ERP,
    cash_type_id: CashTypeEnum.BankCheck,
    destination_type_id: TransactionTypeEnum.Bank,
  },
  {
    router_to: 'visions-erp-bank-check-predated-institution',
    title: 'Cheque pré-datado',
    origin_type_id: TransactionTypeEnum.ERP,
    cash_type_id: CashTypeEnum.BankCheckPreDated,
    destination_type_id: TransactionTypeEnum.Institution,
  },
  {
    router_to: 'visions-erp-money-strongbox',
    title: 'Cofre',
    origin_type_id: TransactionTypeEnum.ERP,
    cash_type_id: CashTypeEnum.Money,
    destination_type_id: TransactionTypeEnum.Strongbox,
  },
  // {
  //   router_to: 'visions-erp-money-bank',
  //   title: 'Boleto',
  //   origin_type_id: TransactionTypeEnum.ERP,
  //   cash_type_id: CashTypeEnum.Money,
  //   destination_type_id: TransactionTypeEnum.Bank,
  // }
]

// console.log(items)
export default {
  name: 'vision',
  components: {
    AppLayoutSite,
  },
  data() {
    return {
      items,
      CashTypeIconUtil,
      TransactionTypeIconUtil
    }
  }
}
</script>
