export default {
  data() {
    return {
      loadingControl: {
        show: false,
        count: 0
      }
    }
  },
  methods: {
    loadingIncrement() {
      this.loadingControl.count++
      if (this.loadingControl.count > 1) {
        this.loadingControl.show = true
      }
    },
    loadingDecrement() {
      this.loadingControl.count--

      if (this.loadingControl.count <= 0) {
        this.loadingControl.count = 0;
      }

      if (this.loadingControl.count === 0) {
        this.loadingControl.show = false
      }
    },
  }
}
