<template>
  <app-layout-site>

    <div class="card5 header">
      <app-loader :loading="loading">

        <h1>Conciliação manual</h1>

        <div class="container">
          <div class="card-form">
            <ValidationObserver v-slot="{ invalid }">
              <form @submit.prevent="submit">

                <div class="form-group">
                  <label>Conciliação desejada</label>
                  <div class="radios">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="container">ERP -> Banco
                        <input type="radio" name="relationship" value="1:1-2" v-model="form.relationship">
                        <span class="checkmark"></span>
                      </label>
                      <label class="container">ERP -> Cofre Deposito
                        <input type="radio" name="relationship" value="1:2-3" v-model="form.relationship">
                        <span class="checkmark"></span>
                      </label>
                      <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-group">
                  <label for="ipt-origem">Origem</label>
                  <div class="description">Digite o ID da transação de origem.</div>
                  <ValidationProvider rules="required|numeric" v-slot="{ errors }">
                    <input id="ipt-origem" v-model="form.origin" type="text" class="form-control">
                    <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <label for="ipt-destino">Destino</label>
                  <div class="description">Digite o ID da transação de destino.</div>
                  <ValidationProvider rules="required|numeric" v-slot="{ errors }">
                    <input id="ipt-destino" v-model="form.destination" type="text" class="form-control">
                    <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <button class="btn btn-primary btn-block" :disabled="invalid">salvar</button>
                </div>

                <div v-show="conciliationSuccess">
                  <div class="alert alert-success">Conciliado com sucesso</div>
                </div>
                <div v-show="conciliationFail">
                  <div class="alert alert-danger">Falha ao conciliar</div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </app-loader>
    </div>

  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'
import AppLoader from '/src/components/loader'
import DetailsProvider from '/src/features/transactions/providers/transaction-details'

export default {
  name: 'vision',
  components: {
    AppLoader,
    AppLayoutSite,
  },
  data() {
    return {
      conciliationSuccess: false,
      conciliationFail: false,
      loading: false,
      form: {
        relationship: '1:1-2',
        origin: null,
        destination: null,
      }
    }
  },
  methods: {
    submit() {
      let self = this
      self.loading = true
      self.conciliationSuccess = false
      self.conciliationFail = false

      DetailsProvider.conciliationManual(this.form)
        .then((response) => {
          self.response = response.data
          self.conciliationSuccess = true
        })
        .catch(() => {
          self.conciliationFail = true
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>
