<template>
  <div class="table-list-container">
    <div ref="tableListBody" class="table-list-body" v-dragscroll="true">
      <div class="nav-left" @click="navScroll('left')" v-show="navLeftVisibility">
        <span class="icon-keyboard_arrow_left"></span>
      </div>
      <div class="nav-right" @click="navScroll('right')" v-show="navRightVisibility">
        <span class="icon-keyboard_arrow_right"></span>
      </div>
      <slot ref="tableSlot"></slot>
    </div>
  </div>
</template>
<script>
import {dragscroll} from 'vue-dragscroll'

export default {
  directives: {
    dragscroll
  },
  mounted() {
    this.navVisibility()
  },
  updated() {
    this.navVisibility()
  },
  data() {
    return {
      navLeftVisibility: false,
      navRightVisibility: false
    }
  },
  methods: {
    navScroll(positionTo) {
      let length = 100
      let table = this.$refs.tableListBody
      // let table = document.getElementById(this.id)
      if (positionTo === 'left') {
        table.scrollLeft -= length
      } else {
        table.scrollLeft += length
      }

      this.navVisibility()
    },
    navVisibility() {
      let tableListBody = this.$refs.tableListBody
      this.navLeftVisibility = tableListBody.scrollLeft !== 0
      this.navRightVisibility = (tableListBody.scrollWidth - tableListBody.offsetWidth - tableListBody.scrollLeft) > 0
    }
  }
}
</script>
