const items = {
  1: 1,
  2: 2,
  3: 5,
  4: 10,
  5: 20,
  6: 50,
  7: 100,
  props: {
    1: {name: '1'},
    2: {name: '2'},
    3: {name: '5'},
    4: {name: '10'},
    5: {name: '20'},
    6: {name: '50'},
    7: {name: '100'}
  }
}
export default Object.freeze(items)
