<template>
  <b-pagination v-if="state != null && !scroll" align="center"
                :total-rows="state.meta.pagination.total"
                @input="paginateChange"
                v-model="pagination.page" :per-page="pagination.limit">
  </b-pagination>
</template>
<script>
import settings from '/src/utils/settings'
import _ from 'lodash'

// TODO: duplicado
const removeOrderByEmpty = (filter) => {
  let result = _.clone(filter)

  if (result === undefined) {
    return result
  }

  if (result.orderBy === undefined) {
    return result
  }

  if (result.orderBy[0] === undefined) {
    return result
  }

  if (result.orderBy[0].field === 'empty') {
    delete result.orderBy
  }

  return result
}

export default {
  props: ['state', 'update', 'append', 'scroll', 'filter'],

  created() {
    this.pagination.limit = parseInt(settings.getSettings().general.others.pagination.limit)
    // window.addEventListener('scroll', this.handleScroll)

    if (this.state) {
      this.pagination.page = this.state.meta.pagination.current_page
      this.paginateRouteUpdate()
    }
  },

  // destroyed() {
  //   window.removeEventListener('scroll', this.handleScroll)
  // },

  mounted() {
    if (!this.state && !this.$route.query.filter) {
      this.paginateRouteUpdate()
      this.refreshState()
    }
  },

  data() {
    return {
      settings: null,
      pagination: {
        page: 1,
        limit: 25
      }
    }
  },

  methods: {
    updateState() {
      this.$emit('loading')
      let filterObject = removeOrderByEmpty(this.filter)

      // console.log('pagination:updateState:filter', JSON.parse(JSON.stringify(this.filter)))
      this.update({pagination: this.pagination, filterObject: filterObject})

      this.setRouteQuery()
    },
    // appendState() {
    //   this.$emit('loading')
    //   this.pagination.page += 1
    //   this.setRouteQuery()
    //   let filterObject = removeOrderByEmpty(this.filter)
    //   this.append({pagination: this.pagination, filterObject: filterObject})
    // },
    refreshWithFilter(filter) {
      this.pagination.page = 1
      this.$emit('loading')
      let filterObject = removeOrderByEmpty(filter)
      // console.log('pagination:refreshWithFilter:filter', JSON.parse(JSON.stringify(filter)))
      this.update({pagination: this.pagination, filterObject: filterObject})
      this.setRouteQuery()
    },
    refreshState() {
      this.pagination.page = 1
      this.updateState()
    },
    paginateRouteUpdate() {
      if (this.$route.query.p) {
        this.pagination.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.l) {
        this.pagination.limit = parseInt(this.$route.query.l)
      }

      this.setRouteQuery()
    },
    setRouteQuery() {
      this.$router.push({
        query: {
          ...this.$route.query,
          p: this.pagination.page,
          l: this.pagination.limit
        }
      })
    },
    paginateChange(page) {
      this.pagination.page = page
      this.updateState()
    },
    // handleScroll() {
    //   if (this.state == null) {
    //     return
    //   }
    //
    //   let paginationMeta = this.state.meta.pagination
    //   let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
    //   if (bottomOfWindow && !this.loading && this.scroll && paginationMeta.current_page < paginationMeta.total_pages) {
    //     this.appendState()
    //   }
    // }
  }
}
</script>
