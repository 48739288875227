import moment from 'moment'

let formatDate = 'YYYY-MM-DD'
let weekInit = moment().subtract(1, 'week').startOf('week').format(formatDate)
let weekFinal = moment().subtract(1, 'week').endOf('week').format(formatDate)
let value = [weekInit, weekFinal]

export default {
  filterBy: null,
  filterSelectErp: {
    orderBy: [
      {
        field: 'empty',
        direction: null
      }
    ],
    where: [
      {
        custom: false,
        field: 'date',
        operator: 'bt',
        period: 'week',
        type: 'date',
        value: value,
      }
    ]
  },
  filterSelectStrongbox: {
    orderBy: [
      {
        field: 'empty',
        direction: null
      }
    ],
    where: [
      {
        custom: false,
        field: 'date',
        operator: 'bt',
        period: 'week',
        type: 'date',
        value: value,
      }
    ]
  },
  filterSelectInstitution: {
    orderBy: [
      {
        field: 'empty',
        direction: null
      }
    ],
    where: [
      {
        custom: false,
        field: 'date',
        operator: 'bt',
        period: 'week',
        type: 'date',
        value: value,
      }
    ]
  },
  filterSelectBank: {
    orderBy: [
      {
        field: 'empty',
        direction: null
      }
    ],
    where: [
      {
        custom: false,
        field: 'date',
        operator: 'bt',
        period: 'week',
        type: 'date',
        value: value,
      },
      {
        field: 'bank_launch_category_type',
        operator: 'eq',
        type: 'string',
        value: 'C',
      }
    ]
  },
  institutions: null
}
