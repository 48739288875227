<template>
  <div>
    <h1 class="menux-title">Lojas</h1>
    <div>
      <app-loader :loading="loading">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Código</th>
          <th scope="col">Numero</th>
          <th scope="col">Status</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(item) of items" :key="item.id" class="inst-block-container">
          <th scope="row">{{ item.id }}</th>
          <td>{{ item.name }}</td>
          <td>{{ item.code }}</td>
          <td>
            {{ item.number }}
            <span class="badge badge-dark">{{ NumberTypeEnum.props[item.number_type_id].name }}</span>
          </td>
          <td>{{ item.active ? 'ativo' : 'inativo' }}</td>
        </tr>
        </tbody>
      </table>
      </app-loader>
    </div>
  </div>
</template>


<script>
import NumberTypeEnum from '/src/enums/number-type'
import InstitutionsProvider from '/src/features/settings/components/register/providers/institutions'
import InstitutionTypeEnum from '/src/enums/institution-type'
import AppLoader from "../../../components/loader.vue";

export default {
  components: {AppLoader},
  async created() {
    this.loading = true
    try {
      let payload = {institution_type_id: InstitutionTypeEnum.Store}

      if (this.$route.query.parent_id) {
        payload.parent_id = this.$route.query.parent_id
      }

      const data = await InstitutionsProvider.get2(payload);
      this.items = data.data
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  },
  data() {
    return {
      loading: false,
      NumberTypeEnum,
      items: []
    }
  }
}
</script>
