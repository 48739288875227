<template>
  <app-loader :loading="loading">
    <div v-if="response != null">
      <ul class="reconciliation-detail"
          v-if="conciliationItemCollection != null && conciliationItemCollection.length > 0">

        <li v-for="(conciliationItem, navIndex) in conciliationItemCollection" :key="navIndex">
          <div v-if="!conciliationItem.isActual">
            <div class="title" :title="conciliationItem.tooltip">{{ conciliationItem.title }}
            </div>
            <ul class="conciliation-card-level">
              <li v-for="(item, itemIndex) in conciliationItem.items" :key="itemIndex">
                <div class="transaction">

                  <router-link class="element"
                               :to="transactionLink(item.origin.transaction_type_id, item.origin.id)"
                               target='_blank'
                  >
                    <div>
                      <img
                        :title="TransactionTypeIconUtil.transactionTypeName(item.origin.transaction_type_id)"
                        :src="TransactionTypeIconUtil.transactionTypeIcon(item.origin.transaction_type_id)"
                        alt="">
                    </div>
                  </router-link>
                  <div class="value">
                    <div class="title">Valor da Transação</div>
                    <div class="raw">
                      {{ item.origin.value | money }}
                    </div>
                  </div>
                  <div class="date">
                    <div class="day">{{ item.origin.date | moment('DD') }}</div>
                    <div class="month">{{ item.origin.date | moment('MMM') }}</div>
                  </div>
                </div>
                <div class="transaction-event ">
                  <div class="title">Conciliação</div>
                  <div class="value">
                    <div class="raw">
                      {{ item.value | money }}
                    </div>
                    <div class="arrow-container">
                      <div class="arrow"></div>
                    </div>
                  </div>
                  <div class="date">
                    <div class="raw">
                      {{ filters2.dateFormat(item.created_at, 'DD/MM/YYYY HH:mm:ss', 'UTC', 'America/Sao_Paulo') }}
                    </div>
                  </div>
                </div>
                <div class="transaction">
                  <router-link class="element"
                               :to="transactionLink(item.destination.transaction_type_id, item.destination.id)"
                               target='_blank'
                  >
                    <div>
                      <img
                        :title="TransactionTypeIconUtil.transactionTypeName(item.destination.transaction_type_id)"
                        :src="TransactionTypeIconUtil.transactionTypeIcon(item.destination.transaction_type_id)"
                        alt="">
                    </div>
                  </router-link>
                  <div class="value">
                    <div class="title">Valor da Transação</div>
                    <div class="raw">
                      {{ item.destination.value | money }}
                    </div>
                  </div>
                  <div class="date">
                    <div class="day">{{ item.destination.date | moment('DD') }}</div>
                    <div class="month">{{ item.destination.date | moment('MMM') }}</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <div class="title">Transação atual</div>
            <ul class="conciliation-card-level" v-if="conciliationItem.items.length === 1">
              <li class="main" v-bind="stats = conciliationItem.items[0]">
                <div class="transaction">
                  <div class="element"
                       :title="TransactionTypeIconUtil.transactionTypeName(stats.origin.transaction_type_id)">
                    <div>
                      <img :src="TransactionTypeIconUtil.transactionTypeIcon(stats.origin.transaction_type_id)" alt="">
                    </div>
                  </div>
                  <div class="value">
                    <div class="title">Valor da Transação</div>
                    <div class="raw">{{ stats.origin.value | money }}</div>
                  </div>
                  <div class="date">
                    <div class="day">{{ stats.origin.date | moment('DD') }}</div>
                    <div class="month">{{ stats.origin.date | moment('MMM') }}</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!--<li><a href="">+ nivel destino</a></li>-->
      </ul>
    </div>
  </app-loader>
</template>
<script>
import _ from 'lodash'
import filters2 from '/src/filters2/index'
import BankIconUtil from '/src/utils/icon-banks'
import TransactionTypeIconUtil from '/src/utils/icon-transaction-type'
import AppLoader from '/src/components/loader'
import TransactionTypeEnum from '/src/enums/transaction-type'

export default {
  components: {
    AppLoader,
  },
  props: {
    response: {
      default: null
    }
  },
  mounted() {
    this.loading = true
  },
  watch: {
    response() {
      this.loading = true
      this.organize()
    }
  },
  data() {
    return {
      BankIconUtil: BankIconUtil,
      TransactionTypeIconUtil: TransactionTypeIconUtil,
      conciliationItemCollection: null,
      conciliationItem: null,
      loading: false,
      filters2
    }
  },
  methods: {
    organize() {
      let responseData = this.response
      let responseLevels = _.orderBy(responseData.level, ['index'], ['asc'])

      let conciliationItemCollection = []

      responseLevels.forEach((responseLevel) => {
        let conciliationItem = {}
        let direction = responseLevel.index > 0 ? 'destino' : 'origem'

        conciliationItem.title = direction; // + ' ' + Math.abs(responseLevel.index) + '° nível'
        conciliationItem.tooltip = Math.abs(responseLevel.index) + '° nível de conciliação com a ' + direction
        conciliationItem.items = []
        conciliationItem.isActual = responseLevel.index === 0

        responseLevel.value.forEach((levelItem) => {
          let transactionOrigin = _.find(responseData.items, function (o) {
            return o.id === levelItem.origin
          })

          let transactionDestination = _.find(responseData.items, function (o) {
            return o.id === levelItem.destination
          })

          conciliationItem.items.push({
            value: parseFloat(levelItem.value),
            created_at: levelItem.created_at,
            origin: transactionOrigin,
            destination: transactionDestination
          })
        })

        conciliationItemCollection.push(conciliationItem)
      })

      this.conciliationItemCollection = conciliationItemCollection
      this.loading = false
    },

    transactionLink(transactionTypeId, id) {
      let items = [
        {
          'id': TransactionTypeEnum.ERP,
          'origin': 'erp',
        },
        {
          'id': TransactionTypeEnum.Bank,
          'origin': 'bank',
        },
        {
          'id': TransactionTypeEnum.Strongbox,
          'origin': 'strongbox',
        },
        {
          'id': TransactionTypeEnum.Institution,
          'origin': 'institution',
        },
      ];

      let item = _.find(items, (e) => e.id === transactionTypeId)
      if (item === undefined) {
        return;
      }

      // // let routeData = this.$router.resolve(`${item.path}/${id}`)
      // let routeData = this.$router.resolve(`${item.path}/${id}`)
      // window.open(routeData.href, '_blank')
      //
      return {
        name: 'transactions-details',
        params: {
          origin: item.origin,
          id: id,
        }
      }
    }
  }
}
</script>
