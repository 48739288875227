<template>
  <app-site-layout>
    <div class="inst-vision-view">
      <div class="card5 header">
        <h1>Visão</h1>
        <div class="header-destination">
          <div class="item">ERP</div>
          <div class="item">Cheque Pré Datado</div>
          <div class="item">Instituição</div>
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="submit">
            <div class="filter-select">
              <div class="actions">
                <div class="group ipt-w-date">
                  <div class="title">Data de ocorrência</div>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <app-datetime-picker id="ipt-date" v-model="form.date"
                                         output-format='YYYY-MM-DD'
                                         format="YYYY-MM-DD"
                                         formatted="YYYY-MM-DD"
                                         label="Selecione uma data"
                                         :min-date="datepicker.minDate"
                                         :max-date="datepicker.maxDate"
                                         :auto-close="true"
                                         :only-date="true"
                    >
                    </app-datetime-picker>
                    <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <button class="btn btn-primary btn-block" :disabled="invalid">aplicar</button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <app-loader :loading="loading">
        <div v-if="!(items && items.data && items.data.total_conciliated.length > 0)">
          <no-results title="Nenhuma transação encontrada!"
                      description="Tente ajustar a data para encontrar lojas com transações."></no-results>
        </div>
        <div v-else class="grid-container">
          <div class="grid-details">
            <div>
              <div class="group">
                <div class="checkboxes">
                  <div class="container">
                    <label>Somente com transações.
                      <input type="checkbox" :value="true" v-model="isEmpty">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <app-progress-bar-conciliated :items="totalConciliatedFiltered"></app-progress-bar-conciliated>
              <app-grid-conciliated :items="totalConciliatedFiltered" @itemClick="onItemClick"></app-grid-conciliated>
              <app-time-conciliated :items="this.items.data.time_conciliated"></app-time-conciliated>
            </div>
          </div>
          <app-table-list class="grid-list">
            <table class="table-list text-center">
              <thead>
              <tr>
                <th class="text-left">Loja</th>
                <th class="text-left">Código</th>
                <th class="text-right">Transações</th>
                <th class="text-right">Conciliado</th>
                <th class="text-right">Percentual</th>
                <th></th>
                <!--<th></th>-->
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in totalConciliatedFiltered" :key="index">
                <td class="text-left">{{ item.institution_name }}</td>
                <td class="text-left">{{ item.institution_code }}</td>
                <td class="text-right">
                  <span title="valor total de transações">{{ item.transactions.sum_values | money }} </span>
                  <span class="badge badge-dark"
                        title="quantidade total de transações">{{ item.transactions.count }}</span>
                </td>
                <td class="text-right">
              <span
                title="valor total de transações conciliadas">{{ item.conciliations.sum_values | money }}
              </span>
                  <span class="badge badge-dark"
                        title="quantidade total de transações conciliadas">{{ item.conciliations.count }}</span>
                </td>
                <td class="text-right">{{ conciliationPercent(item) | percent }} <span class="inst-block"
                                                                                       :class="getStatus(item)"></span>
                </td>
                <!--<td><div class="">quadrado</div></td>-->
                <td class="td-action">
                  <div class="btn-action" @click="onItemClick(item)">
                    <span class="icon-more_horiz"></span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </app-table-list>
        </div>
      </app-loader>
    </div>
  </app-site-layout>
</template>


<script>
import AppSiteLayout from '/src/components/layouts/site'
import moment from 'moment'
import NoResults from '/src/components/NoResults'
import AppLoader from '/src/components/loader'
import AppDatetimePicker from '/src/components/date-time-picker'
import AppTableList from '/src/components/table-list'
import VisionRepository from '/src/repositories/vision'
import DestinationTypeEnum from '/src/enums/destinations-type'
import CashTypeEnum from '/src/enums/cash-type'
import AppProgressBarConciliated from '/src/features/visions/components/progress-bar-conciliated';
import AppGridConciliated from '/src/features/visions/components/GridConciliated';
import AppTimeConciliated from '/src/features/visions/components/TimeConciliated';

const minDate = moment('20180101', 'YYYYMMDD').format('YYYY-MM-DD')
const maxDate = moment().format('YYYY-MM-DD')

export default {
  components: {
    AppSiteLayout,
    NoResults,
    AppLoader,
    AppDatetimePicker,
    AppTableList,
    AppProgressBarConciliated,
    AppGridConciliated,
    AppTimeConciliated
  },
  data() {
    return {
      loading: false,
      datepicker: {
        minDate: minDate,
        maxDate: maxDate
      },
      items: null,
      form: {
        date: null
      },
      isEmpty: false,
    }
  },
  methods: {
    getItems(date) {
      this.loading = true;
      VisionRepository.getErpBankCheckPredatedInstitution({date})
        .then((response) => {
          this.items = response;
        })
        .catch((response) => {
          window.console.error(response);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    submit() {
      this.getItems(this.form.date)
    },
    getStatus(item) {
      if (item.transactions.count > 0) {
        if (item.transactions.count === item.conciliations.count) {
          return 'status-ok'
        } else if ((this.conciliationPercent(item) * 100) > 0) { //Settings.getSettings().general.instVision.conciliationPercent) {
          return 'status-warning'
        } else {
          return 'status-error'
        }
      } else {
        return 'status-empty'
      }
    },

    conciliationPercent(item) {
      let conciliated = item.conciliations.count
      let total = item.transactions.count

      if (total === 0) {
        return 0
      }

      let result = conciliated / total
      return parseFloat(result)
    },

    onItemClick(item) {
      let destinationTypeValue = DestinationTypeEnum.Institution
      let cashTypeValue = CashTypeEnum.BankCheckPreDated

      let filter = {
        destinationTypeId: {
          field: 'destination_type_id',
          type: 'int',
          operator: 'in',
          value: [destinationTypeValue]
        },
        cashType: {
          field: 'cash_type_id',
          type: 'int',
          operator: 'eq',
          value: cashTypeValue
        },
        date: {
          field: 'date',
          type: 'date',
          operator: 'bt',
          value: null,
          custom: true,
          period: 'custom'
        },
        customDate: {
          from: this.form.date,
          to: this.form.date
        },
        institution: {
          field: 'institution_origin_id',
          type: 'string',
          operator: 'in',
          institutions: [{name: item.institution_name, id: item.institution_id}],
          value: [item.institution_id]
        }
      }
      this.$router.push({
        name: 'transactions-erp-index',
        query: {filter: filter}
      })
    }
  },
  computed: {
    totalConciliatedFiltered() {
      if (this.items === null) {
        return []
      }

      let result = this.items.data.total_conciliated

      return this.isEmpty === true ? result.filter((item) => {
        return item.transactions.count > 0
      }) : result
    },
  },
}
</script>
