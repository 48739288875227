<template>
  <div>
    <div class="card-11">
      <div class="title">{{ title }}</div>
      <!--      <div class="pt-2 pl-4">-->
      <!--        <h5 class="card-title text-alingn-center">{{ this.title }}-->
      <!--          <span v-if="tooltipText" v-b-tooltip.hover :title="tooltipText" class="icon-help"></span>-->
      <!--        </h5>-->
      <!--      </div>-->
      <div class="detail">
        <div class="options">
          <div v-for="item in this.data" :key="item.name">
            <span class="mr-1" :style="{borderLeft: 'solid 6px', color: item.color}"></span> {{ item.name }}: R$
            {{ item.value | money }}
          </div>
        </div>
        <div class="chart">
          <PieChart :title="title" :data="data" height="150px"/>
        </div>
      </div>

      <!--      <div class="card-body p-0">-->
      <!--        <div class="d-flex flex-row">-->
      <!--          <div class="col ml-3">-->
      <!--            <div v-for="item in this.data" :key="item.name">-->
      <!--              <span class="mr-1" :style="{borderLeft: 'solid 6px', color: item.color}"></span> {{ item.name }}: R$-->
      <!--              {{ item.value | money }}-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="">-->
      <!--            <PieChart :title="title" :data="data" height="200 px"/>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import PieChart from "../../charts/pie-chart/PieChart";

export default {
  components: {
    PieChart,
  },
  props: {
    data: {},
    title: {},
    tooltipText: null
  },
};
</script>
