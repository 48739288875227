/* eslint-disable no-template-curly-in-string */
import {Provider} from '/src/parents/provider'

class UserProvider extends Provider {
  get() {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/users2`)
  }
}

export default new UserProvider()
