<template>
  <div>
    <div v-if="transactionsErp" class="d-flex flex-wrap">
      <div class="t-card3" v-for="(transaction, index) of transactionsErp.data" :key="index">
        <erp-card :transaction="transaction"></erp-card>
      </div>
    </div>
    <div v-if="transactionsBank" class="d-flex flex-wrap">
      <div class="t-card3" v-for="(transaction, index) of transactionsBank.data" :key="index">
        <bank-card :transaction="transaction" :ctid="ctid"></bank-card>
      </div>
    </div>
    <div v-if="transactionsStrongbox" class="d-flex flex-wrap">
      <div class="t-card3" v-for="(transaction, index) of transactionsStrongbox.data" :key="index">
        <strongbox-card :transaction="transaction"></strongbox-card>
      </div>
    </div>
    <div v-if="transactionsInstitution" class="d-flex flex-wrap">
      <div class="t-card3" v-for="(transaction, index) of transactionsInstitution.data" :key="index">
        <institution-card :transaction="transaction"></institution-card>
      </div>
    </div>
  </div>
</template>

<script>
import ErpCard from './erp-card'
import BankCard from './bank-card'
import StrongboxCard from './strongbox-card'
import InstitutionCard from './institution-card'

export default {
  props: [
    'transactionsErp',
    'transactionsInstitution',
    'transactionsBank',
    'transactionsStrongbox',
    'ctid',
  ],
  components: {
    ErpCard,
    StrongboxCard,
    InstitutionCard,
    BankCard
  }
}
</script>
