<template>
  <div class="col-md-12" v-if="transaction != null">
    <div class="card5" v-bind="transactionBank = transaction.transaction_bank.data">
      <app-detail-transaction :transaction="transaction"></app-detail-transaction>
      <!--<hr class="divider">-->
      <section>
        <div class="card5-item-header">
          <!--<div class="title"><span class="icon-grain"></span>Lançamento</div>-->
          <!--<div class="subtitle">características</div>-->
        </div>
        <div class="card5-item-body">
          <div class="row">
            <div class="item col-sm-12">
              <div class="title">Tipo de importação</div>
              <div class="value">{{ OriginTypeEnum.props[transactionBank.origin_type].name }}</div>
            </div>
          </div>

          <div v-if="transactionBank.origin_type === OriginTypeEnum.BankStatementFile">
            <div class="row">
              <div class="item col-sm-3">
                <div class="title">Código do Cupom</div>
                <div class="value">{{ transactionBank.coupon_code }}</div>
              </div>
              <div class="item col-sm-3">
                <div class="title">Categoria</div>
                <div class="value">{{ transactionBank.bank_launch_category_id | strEmpty }}
                </div>
              </div>
              <div class="item col-sm-3">
                <div class="title">Histórico</div>
                <div class="value">{{ transactionBank.bank_launch_history | strEmpty }}</div>
              </div>
            </div>

            <div class="row">
              <div class="item col-sm-3">
                <div class="title">Número</div>
                <div class="value">{{ transactionBank.bank_launch_number | strEmpty }}</div>
              </div>
              <div class="item col-sm-3" v-if="transactionBank.origin_type === OriginTypeEnum.BankStatementFile">
                <div class="title">Código</div>
                <div class="value">{{ transactionBank.bank_launch_code | strEmpty }}</div>
              </div>
            </div>
          </div>

          <div
            v-if="transactionBank.origin_type === OriginTypeEnum.PixApi || transactionBank.origin_type === OriginTypeEnum.PixFile">
            <div class="row">
              <div class="item col-sm-6">
                <div class="title">Chave PIX</div>
                <div class="value">
                  {{ transactionBank.pix_code | strEmpty }}
                </div>
              </div>
              <div class="item col-sm-6">
                <div class="title">TXID</div>
                <div class="value">
                  {{ transactionBank.txid | strEmpty }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr class="divider">
      <section>
        <app-institution :institution="transactionBank.institution.data" subtitle="Estabelecimento"></app-institution>
      </section>
      <hr class="divider">
      <app-bank-account :bank-account="transactionBank.bank_account"></app-bank-account>


      <section>
        <hr class="divider">
        <div class="card5-item-header">
          <div class="title"><span class="icon-signpost"></span>Conciliação</div>
        </div>
        <div class="card5-item-body">
          <div class="section-reconciliation-percent">
            <!--<app-conciliation-percentage :percents="transaction.conciliation_percent"-->
            <!--class="ml-auto">-->
            <!--</app-conciliation-percentage>-->
            <app-conciliation-item-detail :response="conciliationCollection">
            </app-conciliation-item-detail>
          </div>
        </div>
      </section>

      <!--<hr class="divider">-->
      <!--<app-activities></app-activities>-->
    </div>
  </div>
</template>
<script>
// import AppActivities from '/src/features/transactions/components/details-components/activities'
import AppInstitution from '/src/features/transactions/components/details-components/institution'
import AppBankAccount from '/src/features/transactions/components/details-components/bank-account'
import ConciliationProvider from '/src/features/transactions/providers/conciliation'
import AppConciliationItemDetail from '/src/components/conciliation-item-detail'
import AppDetailTransaction from '/src/features/transactions/components/details-components/detail-transaction'
import OriginTypeEnum from '/src/enums/origin-type'

export default {
  mounted() {
    this.loadConciliationCollection()
  },
  props: ['transaction'],
  components: {
    AppBankAccount,
    AppInstitution,
    AppDetailTransaction,
    // AppActivities,
    // AppConciliationPercentage,
    AppConciliationItemDetail
  },
  data() {
    return {
      conciliationCollection: null,
      OriginTypeEnum
    }
  },
  methods: {
    async loadConciliationCollection() {
      const response = await ConciliationProvider.findById(this.$route.params.id)
      this.conciliationCollection = response.data
    }
  }
}
</script>
