import Login from './components/Login'
import Register from './components/Register'

export default [
  {
    path: '/login',
    name: 'auth-login',
    component: Login
  },
  {
    path: '/register',
    name: 'auth-register',
    component: Register
  }
]
