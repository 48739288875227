<template>
  <div>
    <h1 class="menux-title">Geral</h1>

    <div class="setting-form">
      <div class="setting-form-item">
        <div class="setting-label">
          <div class="setting-label-title">Modo de exibição</div>
          <div class="setting-label-subtitle">As transações são exibidas em modo de cartões</div>
        </div>

        <div class="setting-input">
          <app-toggle v-model="form.transaction_view_mode"></app-toggle>
        </div>
      </div>

      <div class="setting-form-item">
        <div class="setting-label">
          <div class="setting-label-title">Paginação</div>
          <div class="setting-label-subtitle">Quantidade por página</div>
        </div>

        <div class="setting-input">
          <input type="text" name="pagination_limit" v-model="form.pagination_limit" class="form-control">
        </div>
      </div>

      <div>
        <button class="btn btn-primary" @click="save">salvar</button>
      </div>

      <div v-if="showMessageSuccess">
        Atualizado com Successo
      </div>
    </div>
  </div>
</template>

<script>
import Settings from '/src/utils/settings'
import AppToggle from '/src/components/toggle'

const settings = Settings.getSettings();
export default {
  components: {
    AppToggle,
  },
  data() {
    return {
      form: {
        transaction_view_mode: settings.general.transactions.viewMode,
        pagination_limit: settings.general.others.pagination.limit
      },
      showMessageSuccess: false,
    }
  },
  created() {
  },
  methods: {
    save() {
      this.showMessageSuccess = false;
      try {

        settings.general.others.pagination.limit = this.form.pagination_limit;
        Settings.setSettings(settings)

        this.showMessageSuccess = true;
      } catch (e) {
        console.error('error');
      } finally {
        const self = this;

        setTimeout(function () {
          self.showMessageSuccess = false;
        }, 1000)
      }
    }
  }
}
</script>
