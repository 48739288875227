<template>
  <app-layout-site>
    <div class="menux-container">
      <div class="menux">
        <div class="menux-group">
          <div class="menux-group-title">Configurações</div>
          <div class="menux-items">
            <div class="menux-item" :class="{'active': this.$route.name === 'settings2-general'}">
              <router-link :to="{name: 'settings2-general'}">
                Geral
              </router-link>
            </div>
            <!--            <div class="menux-item" :class="{'active': this.$route.name === 'settings2-users'}">-->
            <!--              <router-link :to="{name: 'settings2-users'}">-->
            <!--                Usuários-->
            <!--              </router-link>-->
            <!--            </div>-->
            <!--            <div class="menux-item" :class="{'active': this.$route.name === 'settings2-institutions'}">-->
            <!--              <router-link :to="{name: 'settings2-institutions'}">-->
            <!--                Instituições-->
            <!--              </router-link>-->
            <!--            </div>-->
            <!--            <div class="menux-item" :class="{'active': this.$route.name === 'settings2-bank-accounts'}">-->
            <!--              <router-link :to="{name: 'settings2-bank-accounts'}">-->
            <!--                Contas Bancárias-->
            <!--              </router-link>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="menux-group">
          <div class="menux-group-title">Cadastros</div>
          <div class="menux-items">
            <div class="menux-item" :class="{'active': this.$route.name === 'settings2-users'}">
              <router-link :to="{name: 'settings2-users'}">
                Usuários
              </router-link>
            </div>


            <div class="menux-item" :class="{'active': this.$route.name === 'settings2-bank-accounts'}">
              <router-link :to="{name: 'settings2-bank-accounts'}">
                Contas Bancárias
              </router-link>
            </div>

            <hr>

            <div class="menux-item" :class="{'active': this.$route.name === 'settings2-groups'}">
              <router-link :to="{name: 'settings2-groups'}">
                Grupos
              </router-link>
            </div>

            <div class="menux-item" :class="{'active': this.$route.name === 'settings2-companies'}">
              <router-link :to="{name: 'settings2-companies'}">
                Empresas
              </router-link>
            </div>

            <div class="menux-item" :class="{'active': this.$route.name === 'settings2-stores'}">
              <router-link :to="{name: 'settings2-stores'}">
                Lojas
              </router-link>
            </div>

          </div>
        </div>


      </div>

      <div class="menux-body">
        <router-view></router-view>
      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'

export default {
  components: {
    AppLayoutSite
  },
  created() {
    // console.log(this.$route)
  }
}
</script>
