<template>
  <div v-if="items.length > 0">
    <div class="progress-money">
      <div class="progress-bar-money ok" :style="{width: (stats.percent.ok)+'%'}"
           :title="(stats.percent.ok)+'%'"></div>
      <div class="progress-bar-money warning" :style="{width: (stats.percent.warning)+'%'}"
           :title="(stats.percent.warning)+'%'"></div>
      <div class="progress-bar-money error" :style="{width: (stats.percent.error)+'%'}"
           :title="(stats.percent.error)+'%'"></div>
      <div class="progress-bar-money empty" :style="{width: (stats.percent.empty)+'%'}"
           :title="(stats.percent.empty)+'%'"></div>
    </div>

    <div class="progress-money-legend">
      <div class="progress-bar-money-legend ok">
        <div class="icon"></div>
        <div class="title">{{ (stats.percent.ok) + '%' }}</div>
      </div>
      <div class="progress-bar-money-legend warning">
        <div class="icon"></div>
        <div class="title">{{ (stats.percent.warning) + '%' }}</div>
      </div>
      <div class="progress-bar-money-legend error">
        <div class="icon"></div>
        <div class="title">{{ (stats.percent.error) + '%' }}</div>
      </div>
      <div class="progress-bar-money-legend empty">
        <div class="icon"></div>
        <div class="title">{{ (stats.percent.empty) + '%' }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'app-progress-bar-conciliated',
  props: ['items'],
  created() {
    this.updateStats()
  },
  data() {
    return {
      stats: {
        count: {
          ok: 0,
          warning: 0,
          error: 0,
          empty: 0,
          total: 0
        },
        percent: {
          ok: 0,
          warning: 0,
          error: 0,
          empty: 0,
        }
      }
    }
  },
  methods: {
    updateStats() {
      let result = {
        count: {
          ok: 0,
          warning: 0,
          error: 0,
          empty: 0,
          total: 0
        },
        percent: {
          ok: 0,
          warning: 0,
          error: 0,
          empty: 0,
        }
      }

      this.items.forEach((item) => {
        let conciliated = item.conciliations.count
        let total = item.transactions.count

        result.count.total++;
        if (total === 0) {
          result.count.empty++;
          return true;
        }

        if (conciliated === total) {
          result.count.ok++;
          return true;
        }

        let percent = conciliated / total
        if (percent > 0) {
          result.count.warning++;
          return true;
        }

        result.count.error++;
      })

      result.percent = {
        ok: Math.round((result.count.ok / result.count.total) * 100 * 100) / 100,
        warning: Math.round((result.count.warning / result.count.total) * 100 * 100) / 100,
        error: Math.round((result.count.error / result.count.total) * 100 * 100) / 100,
        empty: Math.round((result.count.empty / result.count.total) * 100 * 100) / 100
      }

      this.stats = result;
    }
  },
  watch: {
    items() {
      this.updateStats()
    }
  }
}
</script>
