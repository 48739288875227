<template>
  <div class="col-md-12" v-if="transaction != null">

    <div class="card5"
         v-bind="transactionStrongboxData = transaction.transaction_strongbox.data"
    >

      <app-detail-transaction :transaction="transaction"></app-detail-transaction>
      <!--<hr class="divider">-->
      <section>
        <div class="card5-item-header">
          <!--<div class="title"><span class="icon-signpost"></span>Detalhes da Transação</div>-->
          <!--<div class="subtitle">características</div>-->
        </div>
        <div class="card5-item-body">
          <div class="row">
            <div class="item col-sm-3">
              <div class="title">Código do Cupom</div>
              <div class="value">{{ transactionStrongboxData.coupon_code }}</div>
            </div>
            <div class="item col-sm-3">
              <div class="title">Tipo</div>
              <div class="value">
                {{ TransactionsStrongboxesTypeEnum.props[transactionStrongboxData.type].name }}
              </div>
            </div>
            <div class="item col-sm-3">
              <div class="title">Código do Cofre</div>
              <div class="value">{{ transactionStrongboxData.strongbox.data.code }}</div>
            </div>
          </div>
        </div>
      </section>
      <!--      <section>-->
      <!--        &lt;!&ndash;<div class="card5-item-header">&ndash;&gt;-->
      <!--        &lt;!&ndash;<div class="title"><span class="icon-attach_money"></span>Cédulas</div>&ndash;&gt;-->
      <!--        &lt;!&ndash;</div>&ndash;&gt;-->
      <!--        <div class="card5-item-body">-->
      <!--          <div class="row">-->
      <!--            <div class="item col-sm-3">-->
      <!--              <div class="title">Relatório das Cédulas</div>-->
      <!--              <div class="value">-->
      <!--                <table class="table-list table-list-sm">-->
      <!--                  <thead>-->
      <!--                  <tr>-->
      <!--                    <th class="text-right">Cédula</th>-->
      <!--                    <th class="text-right">QNT.</th>-->
      <!--                    <th class="text-right">Total</th>-->
      <!--                  </tr>-->
      <!--                  </thead>-->
      <!--                  <tbody>-->

      <!--                  &lt;!&ndash;<div class="item col-sm-2"&ndash;&gt;-->
      <!--                  &lt;!&ndash;v-for="(detail,detailIndex) in strongboxDetailData"&ndash;&gt;-->
      <!--                  &lt;!&ndash;:key="'strongbox-detail'+detailIndex">&ndash;&gt;-->

      <!--                  <tr v-for="(detail,detailIndex) in strongboxDetailData"-->
      <!--                      :key="'strongbox-detail'+detailIndex">-->
      <!--                    <td class="text-right">{{ BallotTypeEnum.props[detail.ballot_type_id].name |-->
      <!--                      money }}-->
      <!--                    </td>-->
      <!--                    <td class="text-right">{{ detail.quantity }}</td>-->
      <!--                    <td class="text-right">-->
      <!--                      {{ (parseInt(BallotTypeEnum[detail.ballot_type_id]) * detail.quantity) | money }}-->
      <!--                    </td>-->
      <!--                  </tr>-->
      <!--                  &lt;!&ndash;<div class="title">Cédulas de {{ BallotTypeEnum.props[detail.ballot_type_id].name }}</div>&ndash;&gt;-->
      <!--                  &lt;!&ndash;<div class="value">{{ detail.quantity }}</div>&ndash;&gt;-->
      <!--                  &lt;!&ndash;&ndash;&gt;-->
      <!--                  &lt;!&ndash;</div>&ndash;&gt;-->
      <!--                  </tbody>-->
      <!--                  &lt;!&ndash;<tfoot>&ndash;&gt;-->
      <!--                  &lt;!&ndash;<tr>&ndash;&gt;-->
      <!--                  &lt;!&ndash;<td colspan="2"></td>&ndash;&gt;-->
      <!--                  &lt;!&ndash;<td class="text-right">{{ 123 | money }}</td>&ndash;&gt;-->
      <!--                  &lt;!&ndash;</tr>&ndash;&gt;-->
      <!--                  &lt;!&ndash;</tfoot>&ndash;&gt;-->
      <!--                </table>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </section>-->
      <hr class="divider">

      <section
        v-for="(transporterInstitution, transporterIntitutionIndex) in transactionStrongboxData.strongbox.data.transporters_institutions.data"
        :key="'transporter-institution-' + transporterIntitutionIndex">
        <app-transporter-institution
          :transporterInstitution="transporterInstitution"></app-transporter-institution>
      </section>
      <hr class="divider">
      <section
        v-for="(institution, institutionIndex) in transactionStrongboxData.strongbox.data.institutions.data"
        :key="'institution-'+institutionIndex">
        <app-institution :institution="institution" subtitle="Estabelecimento de Origem"></app-institution>
      </section>
      <!--<hr class="divider">-->


      <hr class="divider">
      <section>
        <div class="card5-item-header">
          <div class="title"><span class="icon-signpost"></span>Conciliação</div>
        </div>
        <div class="card5-item-body">
          <div class="section-reconciliation-percent">
            <!--<app-conciliation-percentage :percents="transaction.conciliation_percent"-->
            <!--class="ml-auto">-->
            <!--</app-conciliation-percentage>-->
            <app-conciliation-item-detail :response="response"/>
          </div>
        </div>
      </section>
      <!--<app-activities></app-activities>-->
    </div>
  </div>
</template>
<script>
// import AppActivities from '/src/features/transactions/components/details-components/activities'
import AppInstitution from '/src/features/transactions/components/details-components/institution'
import AppTransporterInstitution from '/src/features/transactions/components/details-components/transporter-institution'
import TransactionsStrongboxesTypeEnum from '/src/enums/strongbox/type'
import BallotTypeEnum from '/src/enums/ballot-type'
import AppDetailTransaction from '/src/features/transactions/components/details-components/detail-transaction'
import ConciliationProvider from '/src/features/transactions/providers/conciliation'
import AppConciliationItemDetail from '/src/components/conciliation-item-detail'

export default {
  mounted() {
    this.responseParam()
  },
  components: {
    AppInstitution,
    AppDetailTransaction,
    AppTransporterInstitution,
    AppConciliationItemDetail
  },
  props: ['transaction'],
  data() {
    return {
      response: null,
      TransactionsStrongboxesTypeEnum: TransactionsStrongboxesTypeEnum,
      BallotTypeEnum: BallotTypeEnum
    }
  },
  methods: {
    responseParam() {
      let self = this

      ConciliationProvider
        .findById(this.$route.params.id)
        .then((response) => {
          self.response = response.data
        })
        .finally(() => {
          // this.loading = false
        })
    }
  }
}
</script>
