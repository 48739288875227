const items = {
  BankStatementFile: 1,
  DepositFile: 2,
  PixFile: 3,
  PixApi: 4,
  props: {
    1: {name: 'Arq. Bancário (CNAB 240)'},
    2: {name: 'Arq. Depósito (Layout CEF)'},
    3: {name: 'Arq. PIX (CNAB 750)'},
    4: {name: 'API PIX'},
  }
}

export default Object.freeze(items)
