<template>
  <app-site-layout>
    <div class="inst-vision-view">
      <div class="card5 header">
        <h1>Visão</h1>
        <div class="header-destination">
          <div class="item">Transações</div>
          <div class="item">Modificadas</div>
          <div class="item">Por Loja</div>
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="submit">
            <div class="filter-select">
              <div class="actions">
                <div class="group ipt-w-date">
                  <div class="title">Mês de ocorrência</div>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <app-datetime-picker id="ipt-created-at"
                                         v-model="form.date"
                                         output-format='YYYYMM'
                                         format="YYYY-MM"
                                         formatted="YYYY-MM"
                                         :auto-close="true"
                                         :only-date="true"
                                         label="Selecione um mês"
                    ></app-datetime-picker>
                    <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <button class="btn btn-primary btn-block" :disabled="invalid">aplicar</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>

      <app-loader :loading="loading">
        <div v-if="data === null">
          <no-results title="Nenhuma transação encontrada!"
                      description="Tente ajustar a data para encontrar resultados.">
          </no-results>
        </div>

        <div v-else class="vision-user-modified">
          <div class="totals">
            <div class="totals-item">
              <div class="count-description">Total</div>
              <div class="count"><span class="badge badge-dark">{{ data.totals.count }}</span></div>
            </div>
            <div v-for="(cash_type, cash_type_index) in data.totals.cash_types" :key="cash_type_index"
                 class="totals-item">
              <div>{{ CashTypeEnum.props[cash_type.cash_type_id].name }}</div>
              <div>
                <span class="badge">{{ cash_type.count }}</span>
                <span class="badge badge-warning">{{ cash_type.percent | percent }}</span>
              </div>
            </div>
          </div>

          <div v-for="(institution, institution_index) in data.institutions" :key="institution_index"
               class="institution">
            <div class="institution-detail">
              <div class="institution-name">{{ institution.name }}</div>
              <div class="count"><span class="badge badge-dark">{{ institution.count }}</span></div>
            </div>

            <div v-for="(cash_type, cash_type_index) in institution.cash_types" :key="cash_type_index"
                 class="cash-types">
              <div class="cash-type-name">{{ CashTypeEnum.props[cash_type.cash_type_id].name }}</div>
              <div class="count">{{ cash_type.count }}</div>
            </div>
          </div>
        </div>

      </app-loader>
    </div>
  </app-site-layout>
</template>

<script>
import AppSiteLayout from '/src/components/layouts/site'
import AppDatetimePicker from '/src/components/date-time-picker'
import moment from 'moment'
import VisionRepository from '/src/repositories/vision'
import AppLoader from '/src/components/loader'
import NoResults from '/src/components/NoResults'
// import AppTableList from '/src/components/table-list'
import CashTypeEnum from '/src/enums/cash-type'

export default {
  components: {
    AppSiteLayout,
    AppDatetimePicker,
    AppLoader,
    // AppTableList,
    NoResults,
  },
  data() {
    return {
      CashTypeEnum: CashTypeEnum,
      loading: false,
      data: null,
      form: {
        date: moment().format('YYYYMM')
      },
    }
  },
  methods: {
    submit() {
      this.getItems(this.form.date)
    },
    getItems(date) {
      this.loading = true;


      VisionRepository.getUserModified({date})
        .then((response) => {
          this.data = response.data;
        })
        .catch((response) => {
          window.console.error(response);
        })
        .finally(() => {
          this.loading = false;
        })
    },
  }
}
</script>
