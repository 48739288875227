<template>
  <div v-if="items.length > 0">
    <div class="time-conciliated-title">tempo de conciliação</div>
    <div class="time-conciliated-info">
      <div @click="onShow" class="btn-info"></div>
      <div v-show="showInfo">
        <div class="title">Diferença entre as datas de ocorrência de duas transações.</div>
        <app-time-conciliated-info></app-time-conciliated-info>
      </div>
    </div>

    <div class="time-conciliated-container">
      <div class="time-conciliated">
        <div v-for="(item, index) in  items" :key="index"
             class="time-conciliated-item">
          <div v-if="item.days === null" class="days">
            Não conciliado
          </div>
          <div v-else-if="item.days === '0'" class="days">
            No mesmo dia
          </div>
          <div v-else-if="item.days === '1'" class="days">1 dia</div>
          <div v-else class="days">
            {{ item.days + ' dias' }}
          </div>
          <div class="value">{{ item.value|money }}</div>
        </div>
      </div>
      <div class="time-conciliated-progress">
        <div v-for="(item, index) in graph" :key="index" class="time-conciliated-progress-item">
          <div class="time-conciliated-progress-bar" :style="{ width: item.value+'%' }">
            <div class="time-conciliated-progress-bar-legend">{{ item.value + '%' }}</div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import AppTimeConciliatedInfo from '/src/features/visions/components/TimeConciliatedInfo'

export default {
  name: 'app-time-conciliated',
  props: ['items'],
  components: {
    AppTimeConciliatedInfo
  },
  data() {
    return {
      graph: [],
      showInfo: false
    }
  },
  methods: {
    onShow() {
      this.showInfo = !this.showInfo
    }
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        let result = [];
        let total = 0;

        this.items.forEach((item) => {
          total += parseFloat(item.value)
        })

        this.items.forEach((item, index) => {
          result[index] = {
            value: Math.round((parseFloat(item.value) / total) * 100 * 100) / 100,
          };
        })

        this.graph = result
      }
    }
  }
}
</script>
