/* eslint-disable no-undef */
const set = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

const get = (key) => {
  const string = window.localStorage.getItem(key)
  return JSON.parse(string)
}

const forget = (key) => {
  window.localStorage.removeItem(key)
}

export default {
  get,
  set,
  forget
}
