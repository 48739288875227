/* eslint-disable no-unused-vars */
import institutionProvider from '/src/features/settings/components/register/providers/institutions'
// MUTATIONS

export default {
  updateFilterBy({commit}, payload) {
    commit('SET_FILTER_BY', payload)
  },
  updateFilterSelectErp({commit}, payload) {
    commit('SET_FILTER_SELECT_ERP', payload)
  },
  updateFilterSelectStrongbox({commit}, payload) {
    commit('SET_FILTER_SELECT_STRONGBOX', payload)
  },
  updateFilterSelectBank({commit}, payload) {
    // console.log('commit updateFilterSelectBank', JSON.parse(JSON.stringify(payload)))
    commit('SET_FILTER_SELECT_BANK', payload)
  },
  updateFilterSelectInstitution({commit}, payload) {
    commit('SET_FILTER_SELECT_INSTITUTION', payload)
  },
  updateInstitutions({commit}) {
    let list = institutionProvider.getFull()
      .then(response => {
        commit('SET_FILTER_INSTITUTIONS', response)
      })
  }
}
