<template>
  <div class="item">
    <div class="display">
      <!--      <div class="value">{{ cmc7Value }}</div>-->
      <div class=" ml-auto">
        <div :class="loading ? 'loader-1' : ''">
          <div class="dot-1"></div>
          <div class="dot-2"></div>
          <div class="dot-3"></div>
        </div>
      </div>
    </div>
    <div class="body">
      <div v-if="response !== null" class="bank-check-item">
        <div class="bank-check">
          <div class="bank-check-row">
            <div class="item">
              <div class="description">Comp.</div>
              <div class="value">
                {{ response.data.cmc7.compensation_code }}
              </div>
            </div>
            <div class="item">
              <div class="description">Banco</div>
              <div class="value">
                {{ response.data.cmc7.bank_code }}
              </div>
            </div>
            <div class="item">
              <div class="description">Agencia</div>
              <div class="value">
                {{ response.data.cmc7.agency }}
              </div>
            </div>
            <div class="item">
              <div class="description">Conta</div>
              <div class="value">
                {{ response.data.cmc7.account + '-' + response.data.cmc7.account_check }}
              </div>
            </div>
            <div class="item">
              <div class="description">Número</div>
              <div class="value">
                {{ response.data.cmc7.number }}
              </div>
            </div>
            <div class="item">
              <div class="description">Dt. Emissão</div>
              <div class="value">
                {{ issueDate() }}
              </div>
            </div>
            <div class="item">
              <div class="description">Dt. Venc.</div>
              <div class="value">
                {{ dueDate() }}
              </div>
            </div>
            <div class="item ml-auto">
              <div class="description">Valor</div>
              <div class="value money">
                <div class="prefix">R$</div>
                <div class="raw">
                  {{ formatValue() }}
                </div>
              </div>
            </div>
          </div>
          <div class="bank-check-row">

            <div class="item">
              <div class="description">CMC7</div>
              <div class="value value-empty-cmc7">{{ response.data.cmc7.cmc7 | cmc7 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="response !== null" class="bank-check-option">
        <div v-if="response.data.type === 1">
          Nenhuma transação corresponde ao CMC7
        </div>
        <div v-if="response.data.type === 2">
          CMC7 já vinculado com uma transação de origem.
          <router-link :to="detailLink()">ver mais</router-link>
        </div>
        <div v-if="response.data.type === 3">
          Muitas opções, não foi possivel identificar o cheque
        </div>
        <div v-if="response.data.type === 4">
          Transação de origem identificada e atualizado o CMC7. Transação de destino criada e conciliada.
          <router-link :to="detailLink()">ver mais</router-link>
        </div>
      </div>

      <div v-if="notFound">
        <div class="alert alert-warning">CMC7 não identificado</div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailsProvider from '/src/features/transactions/providers/transaction-details'

export default {
  props: ['cmc7Value'],
  name: 'cmc7-item',

  mounted() {
    this.loading = true
    DetailsProvider.getTransactionErpByCmc7(this.cmc7Value)
      .then((response) => {
        this.response = response
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.notFound = true
        }
      })
      .finally(() => {
        this.loading = false
      })
  },
  data() {
    return {
      loading: false,
      notFound: false,
      response: null
    }
  },
  methods: {
    formatValue() {
      if (this.response.data.transaction === null) {
        return '-';
      }

      return this.$options.filters.money(this.response.data.transaction.value)
    },
    dueDate() {
      if (this.response.data.transaction === null) {
        return '-'
      }

      return this.$options.filters.moment(this.response.data.transaction.due_date, 'DD/MM/YY')
    },
    issueDate() {
      if (this.response.data.transaction === null) {
        return '-'
      }

      return this.$options.filters.moment(this.response.data.transaction.issue_date, 'DD/MM/YY')
    },
    detailLink() {
      return `/transacao/erp/${this.response.data.transaction.id}`
    },
  }
}
</script>
