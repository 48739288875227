import Auth from '../tasks/authentication'

export default {
  interceptor(router) {
    router.beforeEach((to, from, next) => {
      if (to.path !== '/login' && !Auth.isAuthenticated()) {
        next('/login')
      } else {
        next()
      }
    })
  }
}
