// export default {
//   prefix: '',
//   suffix: '',
//   thousands: ',',
//   decimal: '.',
//   precision: 2
// }
export default {
  // prefix: 'R$ ',
  prefix: '',
  // suffix: ' #',
  suffix: '',
  thousands: '.',
  decimal: ',',
  precision: 2
}
