/* eslint-disable no-template-curly-in-string */
import {Provider} from '/src/parents/provider'

class BanksProvider extends Provider {
  get() {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/banks-accounts`)
  }

  getBankLaunchCategory() {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/banks-accounts/launch-category`)
  }

  findBankLaunchCategoryById(id) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/banks-accounts/launch-category/${id}`)
  }

  findTransactionBankByIdEdit(id) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-bank/${id}/edit`)
  }

  get2() {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/banks-accounts2`)
  }
}

export default new BanksProvider()
