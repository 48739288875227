<template>
  <app-layout-site>
    <div class=" transacao-list">
      <div class="card5">
        <app-loader :loading="loadingControl.show">
          <div class="container">
            <div class="card-form">
              <h3>Transações ERP - Adicionar</h3>
              <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="submit">
                  <div class="form-group">
                    <label for="ipt-value">Valor</label>
                    <app-input-money id="ipt-value" v-model="form.value"
                                     :show-nullable="false"></app-input-money>
                  </div>
                  <div class="form-group">
                    <label for="ipt-date">Data</label>
                    <app-datetime-picker id="ipt-date"
                                         v-model="form.date"
                                         output-format='YYYY-MM-DD HH:mm:ss'
                                         format="YYYY-MM-DD hh:mm a"
                                         :min-date="datepicker.minDate"
                                         :max-date="datepicker.maxDate"
                    >
                    </app-datetime-picker>
                  </div>
                  <div class="form-group">
                    <label for="ipt-coupon_code">Código do Cupom</label>
                    <input id="ipt-coupon_code" type="text" name="coupon_code"
                           v-model="form.coupon_code"
                           class="form-control">
                  </div>
                  <div>
                    <div class="form-group">
                      <label for="ipt-institution_origin_id">Loja</label>

                      <app-multiselect
                        id="ipt-institution_origin_id"
                        placeholder="Pesquise e selecione"
                        v-model="multiselect.institution.selected"
                        :options="multiselect.institution.options"
                        :loading="multiselect.institution.loading"
                        :searchable="true"
                        :allow-empty="true"
                        :close-on-select="true"
                        label="name"
                        track-by="id"
                        @input="multiselectInstitutionInput"
                        @search-change="multiselectInstitutionSearch"
                      >
                      </app-multiselect>
                    </div>

                    <div class="form-group">
                      <label for="ipt-destination_type_id">Destino Dinheiro</label>
                      <app-multiselect
                        id="ipt-destination_type_id"
                        placeholder="Selecione uma opção"
                        v-model="multiselect.destinationType.selected"
                        :options="multiselect.destinationType.options"
                        :searchable="true"
                        :allow-empty="false"
                        :close-on-select="true"
                        label="name"
                        track-by="id"
                        @input="multiselectDestinationTypeInput"
                      ></app-multiselect>
                    </div>

                    <div v-show="form.destination_type_id !== undefined">
                      <div class="form-group">
                        <label for="ipt-cash_type_id">Tipo de Valor</label>
                        <app-multiselect
                          id="ipt-cash_type_id"
                          placeholder="Selecione uma opção"
                          :options="multiselect.cashType.options"
                          :searchable="true"
                          :allow-empty="false"
                          :close-on-select="true"
                          label="name"
                          track-by="id"
                          :value="multiselect.cashType.selected"
                          @input="(selected) => {form.cash_type_id = selected.id; multiselect.cashType.selected = selected}"
                        ></app-multiselect>
                      </div>
                      <!--                        v-model="multiselect.cashType.selected"-->
                      <!--                        @input="(selected) => {form.cash_type_id = selected.id}"-->

                      <div v-show="form.destination_type_id === DestinationTypeEnum.Bank">
                        <div class="form-subgroup">
                          <div class="form-group">
                            <label for="ipt-bank_account_id">Conta Bancaria</label>
                            <app-multiselect
                              id="ipt-bank_account_id"
                              placeholder="Selecione uma opção"
                              v-model="multiselect.bankAccount.selected"
                              :options="multiselect.bankAccount.options"
                              :searchable="true"
                              :allow-empty="true"
                              :close-on-select="true"
                              track-by="id"
                              :custom-label="(item)=> { return item.account + '-' + item.agency }"
                              @input="(selected) => {form.bank_account_id = selected !== null? selected.id : null}"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div class="option__bank-account_bank-logo">
                                  <img :src="BankService.bankIcon(props.option.bank_code)">
                                </div>
                                <div class="option__bank-detail">
                                  <div class="option__bank-account_detail">
                                    <div class="option__bank-account_agency">
                                      {{
                                        props.option.agency +
                                        (props.option.agency_check
                                        !== null ? '-' +
                                          props.option.agency_check : '')
                                      }}
                                    </div>
                                    <div class="option__bank-account_account">{{
                                        props.option.account +
                                        (props.option.account_check !== null ? '-' +
                                          props.option.account_check : '')
                                      }}
                                    </div>
                                  </div>
                                  <div class="option__bank-bank_name">
                                    <div>{{ BankService.getName(props.option.bank_code) }}</div>
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div class="option__bank-account_bank-logo">
                                  <img :src="BankService.bankIcon(props.option.bank_code)">
                                </div>
                                <div class="option__bank-detail">
                                  <div class="option__bank-account_detail">
                                    <div class="option__bank-account_agency">
                                      {{
                                        props.option.agency +
                                        (props.option.agency_check
                                        !== null ? '-' +
                                          props.option.agency_check : '')
                                      }}
                                    </div>
                                    <div class="option__bank-account_account">{{
                                        props.option.account +
                                        (props.option.account_check !== null ? '-' +
                                          props.option.account_check : '')
                                      }}
                                    </div>
                                  </div>
                                  <div class="option__bank-bank_name">
                                    <div>{{ BankService.getName(props.option.bank_code) }}</div>
                                  </div>
                                </div>
                                <span class="status"></span>
                              </template>
                            </app-multiselect>
                          </div>
                        </div>
                      </div>

                      <div v-if="isDestinationStrongbox()">
                        <div class="form-subgroup">
                          <div class="form-group">
                            <label for="ipt-strongbox-id">Cofre</label>
                            <app-multiselect
                              id="ipt-strongbox-id"
                              placeholder="Pesquise o codigo do cofre ou da loja"
                              :options="multiselect.strongbox.options"
                              :searchable="true"
                              :allow-empty="true"
                              :close-on-select="true"
                              label="code"
                              track-by="id"
                              :custom-label="(item)=> { return item.code + '-' + item.institution.code }"
                              v-model="multiselect.strongbox.selected"
                              @input="multiselectStrongboxInput"
                              @search-change="multiselectStrongboxSearch"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div class="option__strongbox">
                                  <div class="name">Cofre</div>
                                  <div class="value">{{ props.option.code }}</div>
                                </div>
                                <div class="option__strongbox-institution">
                                  <div class="name">Loja</div>
                                  <div class="value">{{
                                      props.option.institution.code + ' - '
                                      +
                                      props.option.institution.name
                                    }}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div class="option__strongbox">
                                  <div class="name">Cofre</div>
                                  <div class="value">{{ props.option.code }}</div>
                                </div>
                                <div class="option__strongbox-institution">
                                  <div class="name">Loja</div>
                                  <div class="value">{{
                                      props.option.institution.code + ' - '
                                      +
                                      props.option.institution.name
                                    }}
                                  </div>
                                </div>
                                <span class="status"></span>
                              </template>
                            </app-multiselect>
                          </div>
                        </div>
                      </div>

                      <div v-if="isDestinationInstitution()">
                        <div class="form-subgroup">
                          <div class="form-group">
                            <label for="ipt-cp-compensation-code">Codigo de Compensação</label>
                            <input id="ipt-cp-compensation-code" type="text"
                                   v-model="form.check_predated.compensation_code"
                                   class="form-control">
                          </div>
                          <div class="form-group">
                            <label for="ipt-cp-bank-code">Código Banco</label>
                            <input id="ipt-cp-bank-code" type="text"
                                   v-model="form.check_predated.bank_code"
                                   class="form-control">
                          </div>
                          <div class="form-group">
                            <label for="ipt-cp-agency">Agência</label>
                            <div class="description">Numero da agência sem dígito verificador.
                              Exemplo:
                              <div class="badge-code-container">
                                <div class="badge-code">00001</div>
                                <div class="badge-code">00350</div>
                              </div>
                            </div>
                            <ValidationProvider rules="required|numeric|digits:5" v-slot="{ errors }">
                              <input id="ipt-cp-agency" type="text"
                                     v-model="form.check_predated.bank_agency"
                                     class="form-control">
                              <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group">
                            <label for="ipt-cp-bank-account">Conta Corrente</label>
                            <div class="description">Numero da conta corrente sem dígito verificador.
                              Exemplo:
                              <div class="badge-code-container">
                                <div class="badge-code">000000000001</div>
                                <div class="badge-code">003506666801</div>
                              </div>
                            </div>
                            <ValidationProvider rules="required|numeric|digits:12" v-slot="{ errors }">
                              <input id="ipt-cp-bank-account" type="text"
                                     v-model="form.check_predated.bank_account"
                                     class="form-control">
                              <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>

                          <div class="form-group">
                            <label for="ipt-cp-client">Cliente (CNPJ/CPF)</label>
                            <ValidationProvider rules="required|numeric" v-slot="{ errors }">
                              <input id="ipt-cp-client" type="text"
                                     v-model="form.check_predated.client"
                                     class="form-control">
                              <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <div class="form-group">
                            <label for="ipt-cp-number">Numero</label>
                            <input id="ipt-cp-number" type="text"
                                   v-model="form.check_predated.number"
                                   class="form-control">
                          </div>

                          <div class="form-group">
                            <label for="ipt-cp-issue-date">Data de Emissão</label>
                            <app-datetime-picker id="ipt-cp-issue-date"
                                                 v-model="form.check_predated.issue_date"
                                                 output-format='YYYY-MM-DD'
                                                 format="YYYY-MM-DD"
                                                 formatted="DD/MM/YYYY"
                                                 :only-date="true"
                                                 :min-date="datepicker.minDate"
                            >
                            </app-datetime-picker>
                          </div>
                          <div class="form-group">
                            <label for="ipt-cp-due-date">Data de Vencimento</label>
                            <app-datetime-picker id="ipt-cp-due-date"
                                                 v-model="form.check_predated.due_date"
                                                 output-format='YYYY-MM-DD'
                                                 format="YYYY-MM-DD"
                                                 formatted="DD/MM/YYYY"
                                                 :only-date="true"
                                                 :min-date="datepicker.minDate"
                            >
                            </app-datetime-picker>
                          </div>
                          <!--                        <div class="form-group">-->
                          <!--                          <label for="ipt-cp-cmc7">CMC7</label>-->
                          <!--                          <input id="ipt-cp-cmc7" type="text"-->
                          <!--                                 v-model="form.check_predated.cmc7"-->
                          <!--                                 class="form-control">-->
                          <!--                        </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                :disabled="isSubmitDisabled()"-->
                  <!--                <button class="btn btn-primary btn-block" :disabled="isSubmitDisabled()">salvar</button>-->
                  <button class="btn btn-primary btn-block" :disabled="invalid">salvar</button>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </app-loader>
      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'
import AppMultiselect from '/src/components/multiselect'
import BankService from '/src/utils/icon-banks'
import DestinationTypeEnum from '/src/enums/destinations-type'
import CashTypeEnum from '/src/enums/cash-type'
import DetailsProvider from '/src/features/transactions/providers/transaction-details'
import AppDatetimePicker from '/src/components/date-time-picker'
import AppInputMoney from '/src/components/input-money/component'
import InstitutionProvider from '/src/features/settings/components/register/providers/institutions'
import BankAccountProvider from '/src/features/settings/components/register/providers/bank-accounts'
import AppLoader from '/src/components/loader'
import moment from 'moment'
import _ from 'lodash'
import loadingMixin from '/src/features/transactions/components/mixins/loading'
import cashTypeByDestinationType from '/src/enums/destination-cash-type'

const minDate = moment('20180101', 'YYYYMMDD').format('YYYY-MM-DD')
const maxDate = moment().format('YYYY-MM-DD')

const destinationTypeOptions = [
  {
    id: DestinationTypeEnum.Bank,
    name: DestinationTypeEnum.props[DestinationTypeEnum.Bank].name
  },
  {
    id: DestinationTypeEnum.Strongbox,
    name: DestinationTypeEnum.props[DestinationTypeEnum.Strongbox].name
  },
  {
    id: DestinationTypeEnum.Institution,
    name: DestinationTypeEnum.props[DestinationTypeEnum.Institution].name
  }
]

export default {
  name: 'transacao-add',
  mixins: [
    loadingMixin
  ],
  components: {
    AppLoader,
    AppLayoutSite,
    AppMultiselect,
    AppDatetimePicker,
    AppInputMoney
  },

  mounted() {
    this.multiselectInstitutionPreLoad()
    this.multiselectStrongboxPreLoad()
  },

  data: function () {
    return {
      multiselect: {
        institution: {
          debounce: null,
          loading: false,
          selected: null,
          options: []
        },
        destinationType: {
          selected: null,
          options: destinationTypeOptions
        },
        cashType: {
          selected: null,
          options: []
        },
        strongbox: {
          debounce: null,
          loading: false,
          selected: null,
          options: []
        },
        bankAccount: {
          loading: false,
          selected: null,
          options: []
        }
      },
      datepicker: {
        minDate: minDate,
        maxDate: maxDate
      },
      form: {
        date: null,
        check_predated: {
          issue_date: null,
          due_date: null,
        }
      },
      BankService: BankService,
      DestinationTypeEnum: DestinationTypeEnum,
      CashTypeEnum: CashTypeEnum
    }
  },
  methods: {
    isSubmitDisabled() {
      //TODO: usar algum validador depois;

      if (this.form.value === undefined || this.form.value === null || this.form.value === 0) {
        return true
      }

      if (this.form.date === undefined || this.form.date === null) {
        return true
      }

      if (this.form.institution_origin_id === undefined || this.form.institution_origin_id === null) {
        return true
      }

      if (this.form.destination_type_id === undefined || this.form.destination_type_id === null) {
        return true
      }

      // if (this.form.coupon_code === undefined || this.form.coupon_code === null || this.form.coupon_code.trim() === '') {
      //     return true
      // }

      if (this.form.destination_type_id === DestinationTypeEnum.Bank) {
        if (this.form.bank_account_id === undefined || this.form.bank_account_id === null) {
          return true
        }
      }

      if (this.form.destination_type_id === DestinationTypeEnum.Strongbox) {
        if (this.form.strongbox_id === undefined || this.form.strongbox_id === null) {
          return true
        }
      }

      // if (this.form.destination_type_id === DestinationTypeEnum.ESTABELECIMENTO) {
      //     if (this.form.cashType === CashTypeEnum.CHEQUE_PREDATADO) {
      //         if (this.form.check_predated.cmc7 === undefined || this.form.check_predated.cmc7 === null) {
      //             return true
      //         }
      //
      //         if (this.form.check_predated.bank_account === undefined || this.form.check_predated.bank_account === null) {
      //             return true
      //         }
      //     }
      // }

      return false
    },
    submit() {
      let self = this
      this.loadingIncrement()

      DetailsProvider.createTransactionErp(this.form)
        .then((response) => {
          self.response = response.data
        })
        .finally(() => {
          self.loadingDecrement()

          this.$router.push({name: 'transactions-erp-index'})
        })
    },

    multiselectCashTypeOptions(destinationTypeId) {
      return cashTypeByDestinationType[destinationTypeId] !== undefined ? cashTypeByDestinationType[destinationTypeId] : null
    },
    multiselectDestinationTypeInput(selected) {
      this.form.destination_type_id = selected !== null ? selected.id : null

      this.multiselect.cashType.selected = null
      this.multiselect.cashType.options = this.multiselectCashTypeOptions(selected.id)

      // /**
      //  * select first element if have only one element in list
      //  */
      // if (this.multiselect.cashType.options.length === 1) {
      //     this.multiselect.cashType.selected = this.multiselect.cashType.options[0]
      //
      // }

      /**
       * Load bank accounts if select
       */
      if (this.multiselect.bankAccount.options.length === 0) {
        this.multiselectBankAccountsPreLoad()
      }
    },
    multiselectBankAccountsPreLoad() {
      let self = this
      BankAccountProvider.get()
        .then(res => {
          self.multiselect.bankAccount.options = _.orderBy(res.data, 'bank_code')
        })
    },

    //Institution
    multiselectInstitutionPreLoad() {
      let self = this
      self.loadingIncrement()

      InstitutionProvider.get({pagination: {limit: 10, page: 1}})
        .then(res => {
          self.multiselect.institution.options = res.data
        })
        .finally(() => {
          self.loadingDecrement()
        })
    },
    multiselectInstitutionSearch(query) {
      let self = this
      clearTimeout(this.multiselect.institution.debounce)
      /** Prevent white space requests **/
      if (query.match(/^\s*$/)) {
        return
      }

      if (query === '') {
        return
      }

      let callback = () => {
        self.multiselect.institution.loading = true
        InstitutionProvider
          .getFiltered(query)
          .then(res => {
            let options = _.clone(self.multiselect.institution.options)
            self.multiselect.institution.options = _.uniqBy(options.concat(res.data), (e) => {
              return e.id
            })
          })
          .finally(() => {
            self.multiselect.institution.loading = false
          })
      }

      this.multiselect.institution.debounce = setTimeout(callback, 500)
    },
    multiselectInstitutionInput(selected) {
      this.form.institution_origin_id = selected !== null ? selected.id : null
      // this.multiselectStrongboxLoadByInstitutionId(this.form.institution_origin_id)
    },

    //Strongbox
    multiselectStrongboxPreLoad() {
      let self = this
      self.loadingIncrement()

      InstitutionProvider.getStrongboxFiltered({pagination: {limit: 5, page: 1}})
        .then(res => {
          self.multiselect.strongbox.options = res.data
        })
        .finally(() => {
          self.loadingDecrement()
        })
    },
    multiselectStrongboxInput(selected) {
      this.form.strongbox_id = selected !== null ? selected.id : null
    },
    multiselectStrongboxSearch(query) {
      let self = this
      clearTimeout(this.multiselect.strongbox.debounce)
      /** Prevent white space requests **/
      if (query.match(/^\s*$/)) {
        return
      }

      if (query === '') {
        return
      }

      let callback = () => {
        self.multiselect.strongbox.loading = true
        InstitutionProvider
          .getStrongboxFiltered({pagination: {limit: 10, page: 1}, search: query})
          .then(res => {
            let options = _.clone(self.multiselect.strongbox.options)
            self.multiselect.strongbox.options = _.uniqBy(options.concat(res.data), (e) => {
              return e.id
            })
          })
          .finally(() => {
            self.multiselect.strongbox.loading = false
          })
      }

      this.multiselect.strongbox.debounce = setTimeout(callback, 500)
    },
    multiselectStrongboxByIdAndSelected(id) {
      /**
       * Search in items pre-loaded
       */
      if (this.multiselect.strongbox.options.length !== 0) {
        let item = _.find(this.multiselect.strongbox.options, (e) => e.id === id)

        if (item !== undefined) {
          this.multiselect.strongbox.selected = item
          return
        }
      }

      /**
       * Search in api
       */
      let self = this
      InstitutionProvider
        .getStrongboxFiltered({pagination: {limit: 1, page: 1}, id: id})
        .then(res => {
          let item = res.data[0]
          if (item === null || item === undefined) {
            return
          }

          let options = _.clone(self.multiselect.strongbox.options)
          self.multiselect.strongbox.options = _.uniqBy(options.concat([item]), (e) => {
            return e.id
          })

          self.multiselect.strongbox.selected = item
          this.multiselectStrongboxInput(item)
        })
        .finally(() => {
          self.loadingDecrement()
        })
    },
    isDestinationBank() {
      return this.form.destination_type_id === DestinationTypeEnum.Bank
    },
    isDestinationStrongbox() {
      return this.form.destination_type_id === DestinationTypeEnum.Strongbox
    },
    isDestinationInstitution() {
      return this.form.destination_type_id === DestinationTypeEnum.Institution
    }
  },
}
</script>
