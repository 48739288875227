<template>
  <app-layout-site>
    <div class="card5 header">
      <app-loader :loading="loading">
        <h1>Cheque Pré datado</h1>

        <div class="filter-select">
          <!--          <h4>selecionar cheques para gerar arquivo e enviar para o banco</h4>-->
          <div class="actions">
            <div class="group ipt-w-date">
              <div class="title">Status</div>
              <app-multiselect
                placeholder="Selecione uma opção"
                v-model="multiselect.status.selected"
                :options="multiselect.status.options"
                :searchable="true"
                :allow-empty="true"
                :close-on-select="true"
                label="name"
                track-by="id"
                :multiple="true"
                @input="inputStatus"
              ></app-multiselect>
            </div>
            <div class="group ipt-w-date">
              <div class="title">Data Captura</div>
              <app-datetime-picker
                v-model="form.created_at"
                output-format='YYYY-MM-DD HH:mm:ss'
                format="YYYY-MM-DD hh:mm a"
                :range="true"
                :custom-shortcuts="customShortcuts"
              ></app-datetime-picker>
            </div>
            <div class="group ipt-w-date">
              <div class="title">Data Vencimento</div>
              <app-datetime-picker
                v-model="form.due_date"
                output-format='YYYY-MM-DD HH:mm:ss'
                format="YYYY-MM-DD hh:mm a"
                :range="true"
                :custom-shortcuts="customShortcuts"
              ></app-datetime-picker>
            </div>
            <div class="group">
              <div class="btn btn-primary" @click="onFiltrar()">Filtrar</div>
            </div>
          </div>
        </div>
        <div v-if="response !== null">
          <div v-if="response.data.length > 0">
            <div class="form-cheque">
              <div class="form-group">
                <label for="ipt-nsa">Numero Seq. Arquivo (dia)</label>
                <input id="ipt-nsa" type="text"
                       v-model="form.nsa"
                       class="form-control">
              </div>
            </div>
            <div class="form-cheque">
              <div class="form-group">
                <button class="btn btn-primary" @click="gerarArquivo()">Gerar arquivo</button>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" @click="gerarRelatorio()">Gerar Relatório</button>
              </div>
            </div>
          </div>
          <div v-else>
            Nenhum item encontrado
          </div>
          <!--        <app-toggle v-model="toggleAll" @change="changeToggleAll"></app-toggle>-->


          <div class="cmc7-values">
            <div v-if="response.data.length > 0" class="counter">
              <div>
                <div class="title">Quantidade</div>
                <div class="value">{{ response.data.length }}</div>
              </div>
              <div>
                <div class="title">Valor Total</div>
                <div class="value">{{ sumValue(response.data) | money }}</div>
              </div>
            </div>
            <div class="item" v-for="(transaction, transactionIndex) in response.data" :key="transactionIndex">
              <div class="body">
                <div class="bank-check-item">

                  <!--                    <div class="bank-check-checkbox">-->
                  <!--                      <app-toggle-->
                  <!--                        :id="transaction.id"-->
                  <!--                        @change="toggleTransaction({-->
                  <!--                      event: $event,-->
                  <!--                      transaction_id: transaction.id-->
                  <!--                    })">-->
                  <!--                      </app-toggle>-->
                  <!--                    </div>-->
                  <div class="bank-check">
                    <div @click="editLink(transaction)" class="btn-card">
                      <span class="icon-mode_edit"></span>
                    </div>
                    <div class="bank-check-row">
                      <div class="item">
                        <div class="description">Comp.</div>
                        <div class="value">
                          {{ transaction.transaction_institution.check_predated.compensation_code }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="description">Banco</div>
                        <div class="value">
                          {{ transaction.transaction_institution.check_predated.bank_code }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="description">Agencia</div>
                        <div class="value">
                          {{ transaction.transaction_institution.check_predated.bank_agency }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="description">Conta</div>
                        <div class="value">
                          {{
                            transaction.transaction_institution.check_predated.bank_account + '-' +
                            transaction.transaction_institution.check_predated.bank_account_check
                          }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="description">Número</div>
                        <div class="value">
                          {{ transaction.transaction_institution.check_predated.number }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="description">Dt. Emissão</div>
                        <div class="value">
                          {{ transaction.transaction_institution.check_predated.issue_date | moment('DD/MM/YY') }}
                        </div>
                      </div>
                      <div class="item highlight">
                        <div class="description">Dt. Venc.</div>
                        <div class="value">
                          {{ transaction.transaction_institution.check_predated.due_date | moment('DD/MM/YY') }}
                        </div>
                      </div>
                      <div class="item ml-auto">
                        <div class="description">Valor</div>
                        <div class="value money">
                          <div class="prefix">R$</div>
                          <div class="raw">
                            R$ {{ transaction.value | money }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bank-check-row">
                      <div class="item">
                        <div class="description">CMC7</div>
                        <div class="value value-empty-cmc7">
                          {{ transaction.transaction_institution.check_predated.cmc7 | cmc7 }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="description">Cliente</div>
                        <div class="value">
                          {{ formatClient(transaction.transaction_institution.check_predated.client) }}
                        </div>
                      </div>
                    </div>
                    <div class="bank-check-row">
                      <div class="item">
                        <div class="description">Dt. Criação Reg.</div>
                        <div class="value">
                          {{ transaction.created_at | moment('DD/MM/YY') }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="description">Dt. Ocorrência</div>
                        <div class="value">
                          {{ transaction.date | moment('DD/MM/YY') }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="description">Status</div>
                        <div class="value">
                          {{ formatStatus(transaction.transaction_institution.check_predated.status) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </app-loader>
    </div>
  </app-layout-site>
</template>

<script>
import AppLoader from '/src/components/loader'
import AppMultiselect from '/src/components/multiselect'
import AppLayoutSite from '/src/components/layouts/site'
import DetailsProvider from '/src/features/transactions/providers/transaction-details'
import _ from 'lodash'
import AppDatetimePicker from '/src/components/date-time-picker'

export default {
  name: 'cheque-arquivo',
  components: {
    AppLayoutSite,
    AppMultiselect,
    AppLoader,
    AppDatetimePicker
  },
  data() {
    return {
      customShortcuts: [
        // {label: `Hoje`, value: 'day', isSelected: false},
        // {label: 'Ontem', value: '-day', isSelected: false},
        // {label: '5 dias atrás', value: 4, isSelected: false},
        // {label: 'Esta Semana', value: 'week', isSelected: true},
        // // {label: '1 Semana atrás', value: '-week', isSelected: false},
        // // { label: 'This iso Week', value: 'isoWeek', isSelected: true },
        // // { label: 'Last iso Week', value: '-isoWeek', isSelected: false },
        // {label: 'Este Mês', value: 'month', isSelected: false},
        // {label: '1 Mês atrás', value: '-month', isSelected: false},
        // {label: 'Este Ano', value: 'year', isSelected: false},
        // {label: 'Este Ano', value: '-year', isSelected: false},

        {key: 'thisWeek', label: 'Esta Semana', value: 'isoWeek'},
        // {key: 'lastWeek', label: 'Last week', value: '-isoWeek'},
        {key: 'last5Days', label: 'Últimos 5 dias', value: 5},
        // {key: 'last30Days', label: 'Last 30 days', value: 30},
        {key: 'thisMonth', label: 'Este Mês', value: 'month'},
        {key: 'lastMonth', label: 'Ultimo Mês', value: '-month'},
        {key: 'thisYear', label: 'Este Ano', value: 'year'},
        // {key: 'lastYear', label: 'Last year', value: '-year'}
      ],
      loading: false,
      response: null,
      toggleAll: false,
      form: {
        // id: [],
        // selected: [],
        motivo_id: [],
      },
      multiselect: {
        status: {
          debouncing: null,
          loading: false,
          selected: null,
          options: [
            {
              id: 1,
              name: 'Capturado'
            },
            {
              id: 2,
              name: 'Enviado a Banco'
            },
            // {
            //     id: 3,
            //     name: 'Parcialmente Rejeitado'
            // },
            // {
            //     id: 4,
            //     name: 'Rejeitado'
            // },
          ]
        },
        // motivo: {
        //     debouncing: null,
        //     loading: false,
        //     selected: null,
        //     options: [
        //         {
        //             id: 19,
        //             name: '19 - DV1,DV2,DV3 INVALIDO OU INVERTIDO'
        //         },
        //         {
        //             id: 18,
        //             name: '18 - TIPIFICACAO INVALIDA P/CHQ FUTURO'
        //         },
        //
        //     ]
        // },
      }
    }
  },
  methods: {
    onFiltrar() {
      // this.form.selected = []
      let filters = {
        status: this.form.status_id,
      }

      if (this.form.created_at !== null && this.form.created_at !== undefined) {
        filters.created_at = {
          start: this.form.created_at.start,
          end: this.form.created_at.end,
        }
      }

      if (this.form.due_date !== null && this.form.due_date !== undefined) {
        filters.due_date = {
          start: this.form.due_date.start,
          end: this.form.due_date.end,
        }
      }


      this.loading = true
      DetailsProvider.getTransactionInstitutionsCheckPredatedPending(filters)
        .then((response) => {
          this.response = response
        })
        .finally(() => {
          this.loading = false
        })
    },
    gerarArquivo() {
      let data = {
        id: _.map(this.response.data, 'id'),
        nsa: this.form.nsa,
      }

      this.loading = true
      DetailsProvider.downloadBiu(data)
        .then(res => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;

          let nsa = this.form.nsa ? this.form.nsa : 1
          let filename = 'BIUVCM' + nsa + '.MOV';

          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);


          fileLink.click();
          fileLink.remove();
        })
        .finally(() => {
          this.loading = false
        })
    },
    gerarRelatorio() {
      let data = {
        id: _.map(this.response.data, 'id'),
        status: this.form.status_id,
        created_at: {
          start: this.form.created_at ? this.form.created_at.start : null,
          end: this.form.created_at ? this.form.created_at.end : null,
        },
        due_date: {
          start: this.form.due_date ? this.form.due_date.start : null,
          end: this.form.due_date ? this.form.due_date.end : null,
        }
      }

      this.loading = true
      DetailsProvider.downloadPdf(data)
        .then(res => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement('a');


          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'relatorio.pdf');
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatClient(value) {
      if (value.length === 11) {
        return this.$options.filters.cpf(value)
      }

      if (value.length === 14) {
        return this.$options.filters.cnpj(value)
      }

      return value
    },
    formatStatus(value) {
      if (value === 1) {
        return 'Capturado'
      }
      if (value === 2) {
        return 'Enviado a Banco'
      }

      return 'Indefinido';
    },
    toggleTransaction($event) {
      let checked = $event.event.value
      let value = $event.transaction_id

      let index = this.form.selected.indexOf(value)
      if (checked && index === -1) {
        this.form.selected.push(value)
        return
      }

      if (!checked && index !== -1) {
        this.form.selected.slice(index, 1)
      }
    },
    inputStatus(selected) {
      this.form.status_id = selected.length > 0 ? _.map(selected, 'id') : []
    },
    sumValue(items) {
      return _.sumBy(items, function (i) {
        return parseFloat(i.value);
      });
    },
    editLink(transaction) {
      this.$router.push({name: 'transactions-erp-edit', params: {id: transaction.transaction_origin_id}})
    }
    // inputMotivo(selected) {
    //     this.form.motivo_id = selected.length > 0 ? _.map(selected, 'id') : []
    // }
    // changeToggleAll($event) {
    //     for (let i = 0; i < this.toggleList.length; i++) {
    //         this.toggleList[i] = $event.value;
    //     }
    // }
  }
}
</script>
