const mapProperty = {
  'field': 'f',
  'type': 't',
  'operator': 'o',
  'value': 'v'
}

const separator = {
  property: ':=',
  value: ',',
  element: ';',
  object: '|',
  filter: '&',
}

export default {
  mapProperty,
  separator
}
