<template>
  <app-layout-site>
    <div class=" transacao-list">
      <div class="card5">
        <app-loader :loading="loadingControl.show">
          <div class="container">
            <div class="card-form">
              <h3>Transações Banco - Adicionar</h3>
              <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="submit">
                  <div class="form-group">
                    <label for="ipt-value">Valor</label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <app-input-money id="ipt-value" v-model="form.value"
                                       :show-nullable="false"></app-input-money>
                      <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="ipt-date">Data da Transação</label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <app-datetime-picker id="ipt-date"
                                           v-model="form.date"
                                           output-format='YYYY-MM-DD HH:mm:ss'
                                           format="YYYY-MM-DD hh:mm a"
                                           :min-date="datepicker.minDate"
                                           :max-date="datepicker.maxDate"
                      >
                      </app-datetime-picker>
                      <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="ipt-bank_account_id">Conta Bancaria</label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <app-multiselect
                        id="ipt-bank_account_id"
                        placeholder="Selecione uma opção"
                        v-model="multiselect.bankAccount.selected"
                        :options="multiselect.bankAccount.options"
                        :searchable="true"
                        :allow-empty="true"
                        :close-on-select="true"
                        track-by="id"
                        :custom-label="(item)=> { return item.account + '-' + item.agency }"
                        @input="(selected) => {form.bank_account_id = selected !== null? selected.id : null}"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          <div class="option__bank-account_bank-logo">
                            <img :src="BankService.bankIcon(props.option.bank_code)">
                          </div>
                          <div class="option__bank-account_detail">
                            <div class="option__bank-account_agency">
                              {{
                                props.option.agency +
                                (props.option.agency_check
                                !== null ? '-' +
                                  props.option.agency_check : '')
                              }}
                            </div>
                            <div class="option__bank-account_account">{{
                                props.option.account +
                                (props.option.account_check !== null ? '-' +
                                  props.option.account_check : '')
                              }}
                            </div>
                          </div>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__bank-account_bank-logo">
                            <img :src="BankService.bankIcon(props.option.bank_code)">
                          </div>
                          <div class="option__bank-account_detail">
                            <div class="option__bank-account_agency">{{
                                props.option.agency +
                                (props.option.agency_check
                                !== null ? '-' +
                                  props.option.agency_check : '')
                              }}
                            </div>
                            <div class="option__bank-account_account">{{
                                props.option.account +
                                (props.option.account_check !== null ? '-' +
                                  props.option.account_check : '')
                              }}
                            </div>
                          </div>
                          <span class="status"></span>
                        </template>
                      </app-multiselect>
                      <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group">
                    <label for="ipt-institution_origin_id">Categoria de Lançamento</label>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <app-multiselect
                        id="ipt-institution_origin_id"
                        placeholder="Pesquise e selecione"
                        v-model="multiselect.bankLaunchCategory.selected"
                        :options="multiselect.bankLaunchCategory.options"
                        :loading="multiselect.bankLaunchCategory.loading"
                        :searchable="true"
                        :allow-empty="true"
                        :close-on-select="true"
                        label="name"
                        track-by="id"
                        @input="(selected) => {form.bank_launch_category_id = selected !== null? selected.id : null}"
                      >
                        <template slot="option" slot-scope="props">
                          <div class="option__bank_launch_category_detail">
                            <div class="name">
                              <div class="id">{{ props.option.id }}</div>
                              <div class="value">{{ props.option.name }}</div>
                            </div>
                            <div class="category">{{ props.option.type === 'C' ? 'Crédito' : 'Débito' }}</div>
                          </div>
                          <span class="status"></span>
                        </template>
                      </app-multiselect>
                      <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group">
                    <label for="ipt-value">Histórico do Lançamento</label>
                    <div class="description">Descrição do histórico do lançamento, presente no extrato bancário.
                      Exemplo:
                      <div class="badge-code-container">
                        <div class="badge-code">DEP.DINH.CASH IA</div>
                        <div class="badge-code">DEP ONLINE TAA</div>
                        <div class="badge-code">RESGATE APLIC. FINANCEIRA</div>
                      </div>
                    </div>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <input id="ipt-bank-launch-history" type="text" v-model="form.bank_launch_history"
                             class="form-control"/>
                      <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="ipt-value">Código do Lançamento</label>
                    <div class="description">Código do lançamento no banco, presente no extrato bancário. Exemplo:
                      <div class="badge-code-container">
                        <div class="badge-code">0830</div>
                        <div class="badge-code">0500</div>
                      </div>
                    </div>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <input id="ipt-bank-launch-code" type="text" v-model="form.bank_launch_code"
                             class="form-control"/>
                      <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group">
                    <label for="ipt-value">Número do Lançamento</label>
                    <div class="description">Número do documento comprobatório do lançamento, presente no extrato
                      bancário. Exemplo:
                      <div class="badge-code-container">
                        <div class="badge-code">781871452150614</div>
                      </div>
                    </div>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <input id="ipt-bank-launch-number" type="text" v-model="form.bank_launch_number"
                             class="form-control"/>
                      <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!--                <div class="form-group">-->
                  <!--                  <label for="ipt-value">Código do Cupom</label>-->
                  <!--                  <input id="ipt-bank-coupon-code" type="text" v-model="form.coupon_code"-->
                  <!--                         class="form-control"/>-->
                  <!--                </div>-->

                  <!--                  <ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }">-->
                  <!--                    <input v-model="form.email" type="email">-->
                  <!--                    <span>{{ errors[0] }}</span>-->
                  <!--                  </ValidationProvider>-->
                  <button class="btn btn-primary btn-block" :disabled="invalid">salvar</button>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </app-loader>
      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'
import DetailsProvider from '/src/features/transactions/providers/transaction-details'
import AppDatetimePicker from '/src/components/date-time-picker'
import AppInputMoney from '/src/components/input-money/component'
import AppLoader from '/src/components/loader'
import moment from 'moment'
import loadingMixin from '/src/features/transactions/components/mixins/loading'
import BankAccountProvider from '/src/features/settings/components/register/providers/bank-accounts'
import AppMultiselect from '/src/components/multiselect'
import BankService from '/src/utils/icon-banks'
import _ from 'lodash'

const minDate = moment('20180101', 'YYYYMMDD').format('YYYY-MM-DD')
const maxDate = moment().format('YYYY-MM-DD')


export default {
  name: 'transacao-add',
  mixins: [
    loadingMixin
  ],
  components: {
    AppLoader,
    AppLayoutSite,
    AppMultiselect,
    AppDatetimePicker,
    AppInputMoney
  },
  mounted() {
    this.multiselectBankAccountsPreLoad();
    this.multiselectBankLaunchCategoryPreLoad();
  },
  data: function () {
    return {
      multiselect: {
        bankAccount: {
          loading: false,
          selected: null,
          options: []
        },
        bankLaunchCategory: {
          loading: false,
          selected: null,
          options: []
        }
      },
      datepicker: {
        minDate: minDate,
        maxDate: maxDate
      },
      form: {
        date: null,
      },
      BankService: BankService,
    }
  },
  methods: {
    submit() {
      let self = this
      this.loadingIncrement()

      DetailsProvider.createTransactionBank(this.form)
        .then((response) => {
          self.response = response.data
        })
        .finally(() => {
          self.loadingDecrement()
          this.$router.push({name: 'transactions-bank-index'})
        })
    },
    multiselectBankLaunchCategoryPreLoad() {
      let self = this
      BankAccountProvider.getBankLaunchCategory()
        .then(res => {
          self.multiselect.bankLaunchCategory.options = res.data
        })
    },
    multiselectBankAccountsPreLoad() {
      let self = this
      BankAccountProvider.get()
        .then(res => {
          self.multiselect.bankAccount.options = _.orderBy(res.data, 'bank_code')
        })
    },
  }
}
</script>
