import Vue from 'vue'

let format = function (value) {
  value = value.replace(/\D/g, '') // only number
  value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')// format cnpj
  return value
}

Vue.filter('cnpj', (value) => {
  return format(value)
})
