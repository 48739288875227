<template>
  <footer class="footer">
    <div class="container">
      <div class="text-center">
        ©{{ year }} TeiaValues, Inc.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    let date = new Date()
    return {
      year: date.getFullYear()
    }
  }
}
</script>
