const items = {
  INDEFINIDO: 1,
  CPF: 2,
  CNPJ: 3,
  PISPASEP: 4,
  OUTROS: 5,
  props: {
    1: {name: 'Indefinido'},
    2: {name: 'CPF'},
    3: {name: 'CNPJ'},
    4: {name: 'PisPasep'},
    5: {name: 'Outros'}
  }
}

export default Object.freeze(items)
