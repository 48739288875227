<template>
  <app-loader :loading="filterLoadingControl.show">
    <div class="filter-select">
      <div class="radio-groups">
        <div class="group">
          <div class="title">Identificador</div>
          <div class="value-box">
            <input class="form-control" type="text" v-model="form.id.value"/>
          </div>
        </div>
      </div>
      <div class="radio-groups">
        <div class="group">
          <div class="title">Identificador do cliente</div>
          <div class="value-box">
            <input class="form-control" type="text" v-model="form.provider_id.value"/>
          </div>
        </div>
      </div>
      <div :class="{ 'radio-groups':true, 'group-opacity': form.id.value }">
        <div class="group">
          <div class="title">Valor</div>
          <div class="value-box">
            <app-input-money v-model="form.value.value" :show-nullable="true"></app-input-money>
          </div>
        </div>
        <div class="group">
          <div class="title">Código do Cupom</div>
          <div class="value-box">
            <input class="form-control" type="text" v-model="form.couponCode.value"/>
          </div>
        </div>
        <div class="group">
          <div class="title">Estabelecimento</div>
          <app-multiselect v-model="multiselect.institutions.selected"
                           :options="multiselect.institutions.options"
                           :loading="multiselect.institutions.loading"
                           @search-change="asyncFind"
                           id="ajax"
                           label="name"
                           track-by="id"
                           placeholder="Pesquise o estabelecimento"
                           :multiple="true"
                           :searchable="true"
                           :clear-on-select="false"
                           :close-on-select="false"
                           :internal-search="true"
                           class="slt-w-max"
          >
          </app-multiselect>
        </div>
        <div class="group">
          <div class="title">Qual o destino do dinheiro?</div>
          <div class="checkboxes">
            <div class="container">
              <label>Banco
                <input type="checkbox" name="bank" :value="1" v-model="form.destinationTypeId.value">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="container">
              <label>Cofre
                <input type="checkbox" name="strongbox" :value="2" v-model="form.destinationTypeId.value">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="container">
              <label>Estabelecimento
                <input type="checkbox" name="inst" :value="3" v-model="form.destinationTypeId.value">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div :class="{ 'radio-groups':true, 'group-opacity': form.id.value }">
        <div class="group group-sub" v-show="form.destinationTypeId.value.indexOf(1) !== -1">
          <div class="title">Conta Bancária</div>
          <app-multiselect
            id="ipt-bank_account_id"
            placeholder="Selecione a Conta Bancária"
            v-model="multiselect.bankAccount.selected"
            :options="multiselect.bankAccount.options"
            :searchable="true"
            :allow-empty="true"
            :close-on-select="true"
            track-by="id"
            :multiple="true"
            class="slt-w-max"
            :custom-label="(item)=> { return BankService.getName(item.bank_code) + '-' + item.account + '-' + item.agency }"
            @input="multiselectBankAccountInput"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="option__bank-account_bank-logo">
                <img :src="BankService.bankIcon(props.option.bank_code)">
              </div>
              <div class="option__bank-detail">
                <div class="option__bank-account_detail">
                  <div class="option__bank-account_agency">
                    {{
                      props.option.agency +
                      (props.option.agency_check
                      !== null ? '-' +
                        props.option.agency_check : '')
                    }}
                  </div>
                  <div class="option__bank-account_account">{{
                      props.option.account +
                      (props.option.account_check !== null ? '-' +
                        props.option.account_check : '')
                    }}
                  </div>
                </div>
                <div class="option__bank-bank_name">
                  <div>{{ BankService.getName(props.option.bank_code) }}</div>
                </div>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__bank-account_bank-logo">
                <img :src="BankService.bankIcon(props.option.bank_code)">
              </div>
              <div class="option__bank-detail">
                <div class="option__bank-account_detail">
                  <div class="option__bank-account_agency">
                    {{
                      props.option.agency +
                      (props.option.agency_check
                      !== null ? '-' +
                        props.option.agency_check : '')
                    }}
                  </div>
                  <div class="option__bank-account_account">{{
                      props.option.account +
                      (props.option.account_check !== null ? '-' +
                        props.option.account_check : '')
                    }}
                  </div>
                </div>
                <div class="option__bank-bank_name">
                  <div>{{ BankService.getName(props.option.bank_code) }}</div>
                </div>
              </div>
              <span class="status"></span>
            </template>
          </app-multiselect>
        </div>
        <div class="group group-sub" v-show="form.destinationTypeId.value.indexOf(2) !== -1">
          <div class="title">Código do cofre</div>
          <div class="value-box">

            <app-multiselect
              id="ipt-strongbox-id"
              placeholder="Pesquise o codigo do cofre ou da loja"
              :options="multiselect.strongbox.options"
              :searchable="true"
              :allow-empty="true"
              :close-on-select="true"
              label="code"
              track-by="id"
              class="slt-w-max"
              :multiple="true"
              :custom-label="(item)=> { return item.code + '-' + item.institution.code }"
              v-model="multiselect.strongbox.selected"
              @input="multiselectStrongboxInput"
              @search-change="multiselectStrongboxSearch"
            >
              <template slot="singleLabel" slot-scope="props">
                <div class="option__strongbox">
                  <div class="name">Cofre</div>
                  <div class="value">{{ props.option.code }}</div>
                </div>
                <div class="option__strongbox-institution">
                  <div class="name">Loja</div>
                  <div class="value">{{
                      props.option.institution.code + ' - '
                      +
                      props.option.institution.name
                    }}
                  </div>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__strongbox">
                  <div class="name">Cofre</div>
                  <div class="value">{{ props.option.code }}</div>
                </div>
                <div class="option__strongbox-institution">
                  <div class="name">Loja</div>
                  <div class="value">{{
                      props.option.institution.code + ' - '
                      +
                      props.option.institution.name
                    }}
                  </div>
                </div>
                <span class="status"></span>
              </template>
            </app-multiselect>
          </div>
        </div>
      </div>

      <div :class="{ 'radio-groups':true, 'group-opacity': form.id.value }">
        <div class="group">
          <div class="title">Conciliação com Destino</div>
          <div class="radios">
            <label class="container" v-for="(item, index) in select.conciliationType" :key="index">{{ item.label }}
              <input type="radio" name="conciliationType" :value="item.value" v-model="form.conciliation.value">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="group group-sub" v-if="form.conciliation.value === 1 && form.date.period !== null">
          <div class="title">Conciliação Temporal</div>
          <div class="checkboxes">
            <div class="container">
              <label>Transação de destino conciliado dentro do mesmo período.
                <input type="checkbox" name="bank" :value="1" v-model="form.is_conciliated_period.value">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="group group-sub" v-if="form.conciliation.value === 1">
          <div class="title">Tempo de Conciliação</div>
          <div class="description">
            <div>Diferença entre data da ocorrência da origem e data de ocorrência do destino.</div>
          </div>
          <div class="value-box">
            <input class="form-control" type="number" v-model="form.timeConciliation.value"/>
          </div>
        </div>
        <div class="group">
          <div class="title">Tipo de valor</div>
          <div class="radios">
            <label class="container" v-for="(item, index) in select.cashType" :key="index">{{ item.label }}
              <input type="radio" name="cashType" :value="item.value" v-model="form.cashType.value">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div v-if="form.cashType.value === CashTypeEnum.BankCheckPreDated">
          <div class="group group-sub">
            <div class="title">Numero do Cheque</div>
            <div class="value-box">
              <input class="form-control" type="text" v-model="form.checkPredatedNumber.value"/>
            </div>
          </div>
        </div>

        <div class="group">
          <div class="title">Data Ocorrência</div>
          <div class="radios">
            <label class="container">Todos
              <input type="radio" name="date" :value="null" v-model="form.date.period">
              <span class="checkmark"></span>
            </label>
            <label class="container">Ontem
              <input type="radio" name="date" value="yesterday" v-model="form.date.period">
              <span class="checkmark"></span>
            </label>
            <label class="container">Semana Passada
              <input type="radio" name="date" value="week" v-model="form.date.period">
              <span class="checkmark"></span>
            </label>
            <label class="container">Mês Passado
              <input type="radio" name="date" value="month" v-model="form.date.period">
              <span class="checkmark"></span>
            </label>
            <label class="container">Personalizado
              <input type="radio" name="date" value="custom" v-model="form.date.period">
              <span class="checkmark"></span>
            </label>
            <div class="custom-date" v-if="form.date.custom===true">
              <div>
                <span>De</span>
                <input type="date" class="input-text" v-model="form.customDate.from">
              </div>
              <div>
                <span>até</span>
                <input type="date" class="input-text" v-model="form.customDate.to">
              </div>
            </div>
          </div>
        </div>

        <div class="group">
          <div class="title">Ordenação</div>
          <div class="radios">
            <label class="container">Nenhuma
              <input type="radio" name="orderBy" value="empty" v-model="form.orderBy.field"
                     @click="changeOrder(null)">
              <span class="checkmark"></span>
            </label>
            <label class="container">Data
              <input type="radio" name="orderBy" value="date" v-model="form.orderBy.field"
                     @click="changeOrderDefault('desc')">
              <span class="checkmark"></span>
              <div class="sort-direction" v-if="form.orderBy.field==='date'">
                <div class="direction" :class="{'active':form.orderBy.direction === 'asc'}"
                     @click="changeOrder('asc')"><span
                  class="icon-arrow-up2"></span></div>
                <div class="direction" :class="{'active':form.orderBy.direction === 'desc'}"
                     @click="changeOrder('desc')"><span
                  class="icon-arrow-down2"></span></div>
              </div>
            </label>
            <label class="container">Valor
              <input type="radio" name="orderBy" value="value" v-model="form.orderBy.field"
                     @click="changeOrderDefault('asc')">
              <span class="checkmark"></span>
              <div class="sort-direction" v-if="form.orderBy.field==='value'">
                <div class="direction" :class="{'active':form.orderBy.direction === 'asc'}"
                     @click="changeOrder('asc')"><span
                  class="icon-arrow-up2"></span></div>
                <div class="direction" :class="{'active':form.orderBy.direction === 'desc'}"
                     @click="changeOrder('desc')"><span
                  class="icon-arrow-down2"></span></div>
              </div>
            </label>
          </div>
        </div>
        <div class="group">
          <div class="title">Tempo de Criação</div>
          <div class="description">
            <div>Diferença entre data da ocorrência e data de criação no sistema de origem.</div>
            <div>Maior que:</div>
          </div>
          <div class="value-box">
            <input class="form-control" type="number" v-model="form.timeCreated.value"/>
          </div>
        </div>
      </div>
    </div>
  </app-loader>
</template>

<script>
import AppLoader from '/src/components/loader'
import {mapActions, mapState} from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import filterSelectMixin
  from '/src/features/transactions/components/list-components/filter-components/mixins/filter-select'
import AppMultiselect from '/src/components/multiselect'
import InstitutionProvider from '/src/features/settings/components/register/providers/institutions'
import AppInputMoney from '/src/components/input-money/component'
import CashTypeEnum from '/src/enums/cash-type'
import BankService from '/src/utils/icon-banks'
import BankAccountProvider from '/src/features/settings/components/register/providers/bank-accounts'

const minDate = moment('20180101', 'YYYYMMDD').format('YYYY-MM-DD')
const maxDate = moment().format('YYYY-MM-DD')

export default {
  components: {
    AppInputMoney,
    AppMultiselect,
    AppLoader
  },

  mixins: [
    filterSelectMixin
  ],

  mounted() {
    this.multiselectBankAccountsPreLoad()
    this.multiselectStrongboxPreLoad()
    this.loadState()
    this.multiselectInstitutionPreLoad()
  },

  data() {
    return {
      BankService: BankService,
      filterLoadingControl: {
        show: false,
        count: 0
      },
      select: {
        conciliationType: [
          {
            label: 'Todos',
            value: null,
          },
          {
            label: 'Conciliado',
            value: 1,
          },
          {
            label: 'Não Conciliado',
            value: 0,
          },
        ],
        cashType: [
          {
            label: 'Todos',
            value: null,
          },
          {
            label: 'Dinheiro',
            value: CashTypeEnum.Money,
          },
          {
            label: 'Cheque à vista',
            value: CashTypeEnum.BankCheck,
          },
          {
            label: 'Cheque pré-datado',
            value: CashTypeEnum.BankCheckPreDated,
          },
          {
            label: 'Pix',
            value: CashTypeEnum.Pix,
          }
        ]
      },
      multiselect: {
        institutions: {
          options: [],
          selected: [],
          debouncing: null,
          loading: false
        },
        // bank: {
        //     options: banks,
        //     selected: [],
        //     debouncing: null,
        //     loading: false
        // },
        bankAccount: {
          loading: false,
          selected: null,
          options: []
        },
        strongbox: {
          debounce: null,
          loading: false,
          selected: null,
          options: []
        },
      },
      form: {
        provider_id: {
          field: 'provider_id',
          type: 'string',
          operator: 'eq',
          value: null
        },
        conciliation: {
          field: 'destination_is_conciliated',
          type: 'bool',
          operator: 'eq',
          value: null
        },
        destinationTypeId: {
          field: 'destination_type_id',
          type: 'int',
          operator: 'in',
          value: []
        },
        couponCode: {
          field: 'coupon_code',
          type: 'string',
          operator: 'eq',
          value: null
        },
        id: {
          field: 'id',
          type: 'string',
          operator: 'eq',
          value: null
        },
        timeCreated: {
          field: 'time_created',
          type: 'int',
          operator: 'eq',
          value: null
        },
        timeConciliation: {
          field: 'time_conciliation',
          type: 'int',
          operator: 'eq',
          value: null
        },
        checkPredatedNumber: {
          field: 'check_predated_number',
          type: 'int',
          operator: 'eq',
          value: null
        },
        // strongboxCode: {
        //     field: 'strongbox_code',
        //     type: 'int',
        //     operator: 'eq',
        //     value: null,
        //     // visible: false
        // },
        strongboxId: {
          field: 'strongbox_id',
          type: 'int',
          operator: 'in',
          value: []
        },
        bankAccountId: {
          field: 'bank_account_id',
          type: 'int',
          operator: 'in',
          value: []
        },
        cashType: {
          field: 'cash_type_id',
          type: 'int',
          operator: 'eq',
          value: null
        },
        date: {
          field: 'date',
          type: 'date',
          operator: 'bt',
          value: this.getDate('week'),
          custom: false,
          period: 'week'
        },
        customDate: {
          from: null,
          to: null
        },
        orderBy: {
          field: 'empty',
          direction: null
        },
        value: {
          field: 'value',
          type: 'float',
          operator: 'eq',
          value: null
        },
        institution: {
          field: 'institution_origin_id',
          type: 'string',
          operator: 'in',
          institutions: [],
          value: []
        },
        is_conciliated_period: {
          field: 'is_conciliated_period',
          type: 'bool',
          operator: 'eq',
          value: false,
        }
      },
      datepicker: {
        minDate: minDate,
        maxDate: maxDate
      },
      CashTypeEnum: CashTypeEnum
    }
  },

  computed: {
    ...mapState('filters', ['filterSelectErp'])
  },

  methods: {
    ...mapActions('filters', ['updateFilterSelectErp', 'updateInstitutions']),

    saveState() {
      let queryObject = {
        where: [],
        orderBy: []
      }

      if (!(this.form.provider_id.value === null || this.form.provider_id.value === '')) {
        queryObject.where.push(this.form.provider_id)
        delete queryObject['orderBy']

        this.updateFilterSelectErp(queryObject)
        return;
      }

      if (!(this.form.id.value === null || this.form.id.value === '')) {
        queryObject.where.push(this.form.id)
        delete queryObject['orderBy']

        this.updateFilterSelectErp(queryObject)
        return;
      }

      // Destination Object
      if (this.form.destinationTypeId.value.length > 0) {
        queryObject.where.push(this.form.destinationTypeId)
      }

      // BankAccountId Object
      if (this.form.destinationTypeId.value.indexOf(1) !== -1 && this.form.bankAccountId.value.length > 0) {
        queryObject.where.push(this.form.bankAccountId)
      }
      // // StrongboxCode Object
      // if (this.form.destinationTypeId.value.indexOf(2) !== -1 && this.form.strongboxCode.value) {
      //     queryObject.where.push(this.form.strongboxCode)
      // }
      // StrongboxCode Object

      if (!(this.form.couponCode.value === null || this.form.couponCode.value === '')) {
        queryObject.where.push(this.form.couponCode)
      }

      if (!(this.form.timeCreated.value === null || this.form.timeCreated.value === '')) {
        queryObject.where.push(this.form.timeCreated)
      }
      if (!(this.form.timeConciliation.value === null || this.form.timeConciliation.value === '')) {
        queryObject.where.push(this.form.timeConciliation)
      }

      if (this.form.cashType.value === CashTypeEnum.BankCheckPreDated && !(this.form.checkPredatedNumber.value === null || this.form.checkPredatedNumber.value === '')) {
        queryObject.where.push(this.form.checkPredatedNumber)
      }

      if (this.form.destinationTypeId.value.indexOf(2) !== -1 && this.form.strongboxId.value.length > 0) {
        queryObject.where.push(this.form.strongboxId)
      }
      // Conciliation Object
      if (this.form.conciliation.value != null) {
        queryObject.where.push(this.form.conciliation)
      }
      // Cashtype Object
      if (this.form.cashType.value != null) {
        queryObject.where.push(this.form.cashType)
      }
      if (this.form.conciliation.value === 1 && this.form.date.period !== null && this.form.is_conciliated_period.value === true) {
        queryObject.where.push(this.form.is_conciliated_period)
      }
      // Date Object
      if (this.form.date.period !== null && this.form.date.period !== 'custom') {
        queryObject.where.push(this.form.date)
      } else if (this.form.date.custom === true && this.form.customDate.from && this.form.customDate.to) {
        let dateClone = _.clone(this.form.date, true)
        dateClone.value = []
        dateClone.value.push(this.form.customDate.from)
        dateClone.value.push(this.form.customDate.to)
        queryObject.where.push(dateClone)
      }
      // Value Object
      if (this.form.value.value != null && this.form.value.value) {
        queryObject.where.push(this.form.value)
      }
      // Institution Object
      if (this.form.institution.value != null && this.form.institution.value.length > 0) {
        queryObject.where.push(this.form.institution)
      }
      // Sort/OrderBy Object
      queryObject.orderBy.push(this.form.orderBy)
      // Check if queryObject has 'where'
      if (queryObject.where.length < 1) {
        delete queryObject.where
      }

      // remove orderBy if empty
      if (queryObject.orderBy[0] !== undefined && queryObject.orderBy[0].field === 'empty') {
        delete queryObject['orderBy']
      }

      this.updateFilterSelectErp(queryObject)
    },
    loadState() {
      /** Get firstly the route filters **/
      if (this.$route.query.filter) {
        this.loadRouteFilter()
        /** Load institutions to Multiselect **/
        this.multiselect.institutions.selected = this.form.institution.institutions
        this.$emit('apply')
      } else {
        /** Then load the rest from state **/
        if (this.filterSelectErp.where && this.filterSelectErp.where.length > 0) {
          let newForm = _.cloneDeep(this.form)
          for (let item of this.filterSelectErp.where) {
            switch (item.field) {
              case 'destination_is_conciliated':
                newForm.conciliation.value = item.value
                break
              case 'destination_type_id':
                newForm.destinationTypeId.value = item.value
                break
              case 'cash_type_id':
                newForm.cashType.value = item.value
                break
              case 'value':
                newForm.value.value = item.value
                break
              case 'date':
                if (item.custom) {
                  newForm.date = item
                  newForm.customDate.from = moment(item.value[0]).format('YYYY-MM-DD')
                  newForm.customDate.to = moment(item.value[1]).format('YYYY-MM-DD')
                } else {
                  newForm.date = item
                }
                break
              case 'institution_origin_id':
                newForm.institution.value = item.value
                newForm.institution.institutions = item.institutions
                for (let inst of newForm.institution.institutions) {
                  this.multiselect.institutions.selected.push(inst)
                }
                break
            }
          }
          this.form = newForm
        }
      }
      if (this.filterSelectErp.orderBy && this.filterSelectErp.orderBy.length > 0) {
        // Load Order
        this.form.orderBy = this.filterSelectErp.orderBy[0]
      }
    },
    asyncFind: function (query) {
      let self = this
      clearTimeout(self.multiselect.institutions.debouncing)
      /** Prevent white space requests **/
      if (query.match(/^\s*$/)) {
        return
      }

      if (query === null || query === '') {
        return
      }

      let callback = () => {
        self.multiselect.institutions.loading = true
        InstitutionProvider
          .getFiltered(query)
          .then(res => {
            let options = _.clone(self.multiselect.institutions.options)
            self.multiselect.institutions.options = _.uniqBy(options.concat(res.data), (e) => {
              return e.id
            })
          })
          .finally(() => {
            self.multiselect.institutions.loading = false
          })
      }

      self.multiselect.institutions.debouncing = setTimeout(callback, 500)
    },
    multiselectInstitutionPreLoad() {
      let self = this
      InstitutionProvider.get({pagination: {limit: 5, page: 1}})
        .then(res => {
          self.multiselect.institutions.options = res.data
        })
    },
    multiselectBankAccountsPreLoad() {
      let self = this
      BankAccountProvider.get()
        .then(res => {
          self.multiselect.bankAccount.options = _.orderBy(res.data, 'bank_code')
        })
    },
    multiselectBankAccountInput(selected) {
      this.form.bankAccountId.value = selected.length > 0 ? _.map(selected, 'id') : []
    },
    multiselectStrongboxSearch(query) {
      let self = this
      clearTimeout(this.multiselect.strongbox.debounce)
      /** Prevent white space requests **/
      if (query.match(/^\s*$/)) {
        return
      }

      if (query === '') {
        return
      }

      let callback = () => {
        self.multiselect.strongbox.loading = true
        InstitutionProvider
          .getStrongboxFiltered({pagination: {limit: 10, page: 1}, search: query})
          .then(res => {
            let options = _.clone(self.multiselect.strongbox.options)
            self.multiselect.strongbox.options = _.uniqBy(options.concat(res.data), (e) => {
              return e.id
            })
          })
          .finally(() => {
            self.multiselect.strongbox.loading = false
          })
      }

      this.multiselect.strongbox.debounce = setTimeout(callback, 500)
    },
    multiselectStrongboxInput(selected) {
      this.form.strongboxId.value = selected.length > 0 ? _.map(selected, 'id') : []
    },
    multiselectStrongboxPreLoad() {
      let self = this
      self.filterLoadingIncrement()

      InstitutionProvider.getStrongboxFiltered({pagination: {limit: 5, page: 1}})
        .then(res => {
          self.multiselect.strongbox.options = res.data
        })
        .finally(() => {
          self.filterLoadingDecrement()
        })
    },
    filterLoadingIncrement() {
      this.filterLoadingControl.count++
      if (this.filterLoadingControl.count > 1) {
        this.filterLoadingControl.show = true
      }
    },
    filterLoadingDecrement() {
      this.filterLoadingControl.count--

      if (this.filterLoadingControl.count <= 0) {
        this.filterLoadingControl.count = 0;
      }

      if (this.filterLoadingControl.count === 0) {
        this.filterLoadingControl.show = false
      }
    },
  },

  watch: {
    'multiselect.institutions.selected': function (value) {
      this.form.institution.value = []
      this.form.institution.institutions = []

      if (value !== null && value.length > 0) {
        for (let item of value) {
          this.form.institution.value.push(item.id)
          this.form.institution.institutions.push(item)
        }
      }
    },
    // 'form.destination.value': {
    //     handler: function (value) {
    // this.form.strongboxCode.visible = value.includes(2)
    // this.form.bankCode.visible = value.includes(1)
    //     },
    //     deep: true
    // },
    // 'multiselect.bank.selected': function (items) {
    //     this.form.bankCode.value = []
    //     this.form.bankCode.banks = []
    //
    //     if (items !== null && items.length > 0) {
    //         for (let item of items) {
    //             this.form.bankCode.value.push(item.id)
    //             this.form.bankCode.banks.push(item)
    //         }
    //     }
    // }
  }
}
</script>
