/* eslint-disable no-template-curly-in-string */
import {Provider} from '/src/parents/provider'

class BanksProvider extends Provider {
  get(pagination) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/banks?limit=${pagination.limit}&page=${pagination.page}`)
  }
}

export default new BanksProvider()
