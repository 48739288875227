import Vue from 'vue'

/**
 * '001015554613863485900000292788' => '00101555 4613863485 900000292788'
 * @param value
 * @returns {*}
 */
let format = function (value) {
  if (!value) {
    return null;
  }

  value = value.replace(/\D/g, '') // only number
  value = value.replace(/^(\d{8})(\d{10})(\d{12})$/, '$1 $2 $3')// format cnpj
  return value
}

Vue.filter('cmc7', (value) => {
  return format(value)
})
