import Vue from 'vue'
import {extend, localize, ValidationObserver, ValidationProvider} from 'vee-validate';
import {digits, email, numeric, required} from 'vee-validate/dist/rules';
import pt_Br from 'vee-validate/dist/locale/pt_BR.json';

// No message specified.
extend('email', email);
extend('numeric', {
  ...numeric,
  message: 'O campo deve conter apenas números'
});
extend('digits', {
  ...digits,
  message: 'O campo deve ser numérico e ter exatamente {length} dígitos'
});

// Override the default message.
extend('required', {
  ...required,
  message: 'O campo é obrigatório'
});

// const dict = {
//   messages: ptBR.messages,
//   pt_BR: {
//     custom: {
//       cpf: {
//         required: 'Favor preencher o cpf',
//       },
//     }
//   },
// };

// Validator.localize({pt_BR: dict})

// Add a rule.
// extend('secret', {
//   validate: value => value === 'example',
//   message: 'This is not the magic word'
// });
//
// extend('required', {
//   validate(value) {
//     return {
//       required: true,
//       valid: ['', null, undefined].indexOf(value) === -1
//     };
//   },
//   computesRequired: true
// });

localize({
  pt_Br,
})

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// localize({
//   en: {
//     messages: {
//       required: 'this field is required',
//       min: 'this field must have no less than {length} characters',
//       max: (_, { length }) => `this field must have no more than ${length} characters`
//     }
//   }
// });

