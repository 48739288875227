/* eslint-disable no-template-curly-in-string */
import {Provider} from '/src/parents/provider'
import filterSerializer from '/src/utils/serialize-filter/index'

class InstitutionProvider extends Provider {
  get(payload) {
    if (payload.filterObject) {
      let serialized = filterSerializer.serialize(payload.filterObject)
      serialized = serialized.trim() === '' ? '' : '&' + serialized
      return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-institutions?limit=${payload.pagination.limit}&page=${payload.pagination.page}${serialized}`)
    }

    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-institutions?limit=${payload.pagination.limit}&page=${payload.pagination.page}`)
  }
}

export default new InstitutionProvider()
