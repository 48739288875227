<template>
  <div class="card6-container">

    <div class="card6">
      <div class="card6-list">
        <div class="list-header">
          <div>
            <app-list-statistics :list="transactionsBank"></app-list-statistics>
          </div>
          <div class="ml-auto action-buttons">
            <button class="no-fill" @click="add()">
              <span class="icon-add"></span>
            </button>
            <button class="no-fill" @click="toggleFilter()">
              <span :class="{'icon-equalizer':true, 'active': filter}"></span>
            </button>

            <button class="no-fill" @click="toggleCards()">
              <span v-if="cardsMode" class="icon-view_comfy"></span>
              <span v-if="!cardsMode" class="icon-view_stream"></span>
            </button>

            <button class="no-fill" @click="refresh()">
              <span class="icon-sync"></span>
            </button>
          </div>
        </div>
        <app-loader :loading="loading">
          <app-no-results v-if="!loading && transactionsIsEmpty()"/>
          <cards-list v-if="!transactionsIsEmpty() && cardsMode" :transactions-bank="transactionsBank" :ctid="ctid"/>
          <bank-table v-if="!transactionsIsEmpty() && !cardsMode" :transactions-bank="transactionsBank" :ctid="ctid"/>
          <app-pagination v-show="!transactionsIsEmpty()" :state="transactionsBank" :update="updateBank"
                          :append="appendBank"
                          @loading="paginateLoading"
                          :scroll="false"
                          :filter="filterSelectBank"
                          ref="pagination"/>

          <!--          <div v-if="!loading && !transactionsBank">-->
          <!--            <app-no-results/>-->
          <!--          </div>-->
        </app-loader>
      </div>
    </div>
    <div v-show="filter" id="filter">
      <app-filter-modal @close="closeFilter" @apply="applyFilter"></app-filter-modal>
    </div>
  </div>
</template>
<script>
import AppLoader from '/src/components/loader'
import AppNoResults from '/src/components/NoResults'
import AppPagination from '/src/components/pagination'
import AppFilterModal from './filter-components/filter-modal'
import BankTable from './table-components/bank-table'
import CardsList from './card-components/cards-list'
import {mapActions, mapState} from 'vuex'
import listModeMixin from '/src/features/transactions/components/list-components/mixins/list-mode'
import settingsMixin from './mixins/settings'
import filterMixin from './mixins/filter'
import AppListStatistics from './list-statistics'

export default {
  components: {
    AppLoader,
    AppNoResults,
    AppPagination,
    AppFilterModal,
    AppListStatistics,
    BankTable,
    CardsList
  },

  mixins: [
    listModeMixin,
    settingsMixin,
    filterMixin
  ],
  mounted() {
    if (this.$route.query.ctid !== undefined) {
      this.ctid = parseInt(this.$route.query.ctid)
    }
  },
  data() {
    return {
      ctid: null,
      loading: false,
      filter: true
    }
  },
  computed: {
    ...mapState('transactions', ['transactionsBank']),
    ...mapState('filters', ['filterSelectBank'])
  },

  methods: {
    ...mapActions('transactions', ['updateBank', 'appendBank']),
    transactionsIsEmpty() {
      return !(this.transactionsBank && this.transactionsBank.data.length)
    },
    paginateLoading() {
      this.loading = true
    },
    refresh() {
      this.$refs.pagination.refreshState()
    },
    add() {
      this.$router.push({name: 'transactions-bank-add'})
    }
  },

  watch: {
    'transactionsBank': {
      handler: function () {
        this.loading = false
      },
      deep: true
    }
  }
}
</script>
