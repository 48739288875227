const items = {
  DepositBankCheck: 3,
  DepositBankCheckChargeback: 11,
  DepositChargeback: 8,
  DepositMoney: 2,
  Doc: 7,
  PixReceived: 6,
  PixChargeback: 10,
  Ted: 4,
  TedChargeback: 9,
  TransferReceived: 5,
  Undefined: 1,
  PixSent: 12,
  Tax: 13,
  Fee: 14,
  TransferSent: 15,
  Acquirer: 16,
  FeeChargeback: 17,
  SupplierPayment: 18,
  SupplierReceipt: 19,
  FinancialInvestmentRedemption: 20,
  FinancialInvestment: 21,
  SalaryPayment: 22,
  props: {
    1: {name: 'Indefinido'},
    2: {name: 'Deposito Dinheiro'},
    3: {name: 'Deposito Cheque'},
    4: {name: 'Ted'},
    5: {name: 'Transferência Recebida'},
    6: {name: 'Pix Recebido'},
    7: {name: 'DOC'},
    8: {name: 'Deposito Dinheiro Estornado'},
    9: {name: 'Ted Estornada'},
  }
}

export default Object.freeze(items)
