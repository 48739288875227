const items = {
  1: 'erp',
  2: 'bank',
  3: 'strongbox',
  4: 'institution'
}

const names = {
  1: 'ERP',
  2: 'Banco',
  3: 'Cofre',
  4: 'Instituição',
}

const transactionTypeIcon = (transactionTypeId) => {
  let item = items[transactionTypeId]

  if (item === undefined) {
    item = 'undefined'
  }

  return require('@/assets/transaction_types/' + item + '.svg')
}

const transactionTypeName = (transactionTypeId) => {
  let item = names[transactionTypeId]

  if (item === undefined) {
    item = 'nenhum'
  }

  return item
}

export default {
  transactionTypeIcon,
  transactionTypeName
}
