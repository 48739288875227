<template>
  <div class="card6-container">
    <div class="card6">
      <div class="card6-list">
        <div class="list-header">
          <div>
            <app-list-statistics :list="transactionsInstitution"></app-list-statistics>
          </div>
          <div class="ml-auto action-buttons">
            <button class="no-fill" @click="toggleFilter()">
              <span :class="{'icon-equalizer':true, 'active': filter}"></span>
            </button>
            <!--            <button class="no-fill" @click="toggleCards()">-->
            <!--              <span v-if="cardsMode" class="icon-view_comfy"></span>-->
            <!--              <span v-if="!cardsMode" class="icon-view_stream"></span>-->
            <!--            </button>-->
            <button class="no-fill" @click="refresh()">
              <span class="icon-sync"></span>
            </button>
          </div>
        </div>
        <app-loader :loading="loading">
          <app-no-results v-if="!loading && transactionsIsEmpty()"/>
          <cards-list v-if="!transactionsIsEmpty() && cardsMode" :transactions-institution="transactionsInstitution"/>
          <!--          <institution-table v-if="!transactionsIsEmpty() && !cardsMode"-->
          <!--                             :transactions-institution="transactionsInstitution"/>-->
          <app-pagination v-show="!transactionsIsEmpty()" :state="transactionsInstitution"
                          :update="updateInstitution"
                          :append="appendInstitution"
                          @loading="paginateLoading"
                          :scroll="false"
                          :filter="filterSelectInstitution"
                          ref="pagination"/>

        </app-loader>
      </div>
    </div>
    <div v-show="filter" id="filter">
      <app-filter-modal @close="closeFilter" @apply="applyFilter"></app-filter-modal>
    </div>
  </div>
</template>
<script>
import AppLoader from '/src/components/loader'
import AppNoResults from '/src/components/NoResults'
import AppPagination from '/src/components/pagination'
import AppFilterModal from './filter-components/filter-modal'
// import InstitutionTable from './table-components/institution-table'
import CardsList from './card-components/cards-list'
import {mapActions, mapState} from 'vuex'
import listModeMixin from '/src/features/transactions/components/list-components/mixins/list-mode'
import settingsMixin from './mixins/settings'
import filterMixin from './mixins/filter'
import AppListStatistics from './list-statistics'

export default {
  components: {
    AppLoader,
    AppNoResults,
    AppPagination,
    AppFilterModal,
    AppListStatistics,
    // InstitutionTable,
    CardsList
  },

  mixins: [
    listModeMixin,
    settingsMixin,
    filterMixin
  ],

  data() {
    return {
      loading: false,
      filter: true
    }
  },
  computed: {
    ...mapState('transactions', ['transactionsInstitution']),
    ...mapState('filters', ['filterSelectInstitution'])
  },

  methods: {
    ...mapActions('transactions', ['updateInstitution', 'appendInstitution']),
    transactionsIsEmpty() {
      return !(this.transactionsInstitution && this.transactionsInstitution.data.length)
    },
    paginateLoading() {
      this.loading = true
    },
    refresh() {
      this.$refs.pagination.refreshState()
    }
  },

  watch: {
    'transactionsInstitution': {
      handler: function () {
        this.loading = false
      },
      deep: true
    }
  }
}
</script>
