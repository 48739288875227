import storage from './storage'

const defaultSettings = {
  general: {
    others: {
      pagination: {
        limit: 8,
      }
    },
    transactions: {
      viewMode: true
    }
  }
}

export default {
  isSet() {
    return !!storage.get('settings');
  },

  setDefaultSettings() {
    storage.set('settings', defaultSettings)
  },

  getSettings() {
    return storage.get('settings')
  },

  setSettings(settings) {
    storage.set('settings', settings)
  }
}
