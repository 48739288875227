import settings from '/src/utils/settings'

export default {
  created() {
    this.settings = settings.getSettings().general.others.pagination
  },
  data() {
    return {
      settings: null
    }
  }
}
