<template>
  <div class="card6-container">
    <div class="card6">
      <div class="card6-list">
        <div class="list-header">
          <div>
            <app-list-statistics :list="transactionsErp"></app-list-statistics>
          </div>
          <div class="ml-auto action-buttons">

            <button class="no-fill" @click="add()">
              <span class="icon-add"></span>
            </button>

            <button class="no-fill" @click="toggleFilter()">
              <span :class="{'icon-equalizer':true, 'active': filter}"></span>
            </button>

            <button class="no-fill" @click="toggleCards()">
              <span v-if="cardsMode" class="icon-view_comfy"></span>
              <span v-if="!cardsMode" class="icon-view_stream"></span>
            </button>

            <button class="no-fill" @click="refresh()">
              <span class="icon-sync"></span>
            </button>
          </div>
        </div>
        <app-loader :loading="loading">
          <app-no-results v-if="!loading && transactionsIsEmpty()"/>
          <erp-cards v-if="!transactionsIsEmpty() && cardsMode> 0" :transactions-erp="transactionsErp"/>
          <erp-table v-if="!transactionsIsEmpty() && !cardsMode> 0" :transactions-erp="transactionsErp"/>

          <app-pagination v-show="!transactionsIsEmpty()"
                          :state="transactionsErp"
                          :update="updateErp"
                          :append="appendErp"
                          @loading="paginateLoading()"
                          :scroll="false"
                          :filter="filterSelectErp"
                          ref="pagination"/>


        </app-loader>
      </div>
    </div>
    <div v-show="filter" id="filter">
      <app-filter-modal @close="closeFilter" @apply="applyFilter"></app-filter-modal>
    </div>
  </div>
</template>
<script>
import AppLoader from '/src/components/loader'
import AppNoResults from '/src/components/NoResults'
import AppFilterModal from './filter-components/filter-modal'
import AppPagination from '/src/components/pagination'
import ErpCards from './card-components/cards-list'
import AppListStatistics from './list-statistics'
import ErpTable from './table-components/erp-table'
import listModeMixin from '/src/features/transactions/components/list-components/mixins/list-mode'
import settingsMixin from './mixins/settings'
import filterMixin from './mixins/filter'
import {mapActions, mapState} from 'vuex';

export default {
  components: {
    AppLoader,
    AppNoResults,
    AppFilterModal,
    AppPagination,
    AppListStatistics,
    ErpTable,
    ErpCards
  },

  mixins: [
    listModeMixin,
    settingsMixin,
    filterMixin
  ],
  data() {
    return {
      loading: false,
      filter: true,
      // transactionsErp: [],
    }
  },
  computed: {
    ...mapState('transactions', ['transactionsErp']),
    ...mapState('filters', ['filterSelectErp'])
  },

  methods: {
    ...mapActions('transactions', ['updateErp', 'appendErp', 'clearErp']),

    transactionsIsEmpty() {
      return !(this.transactionsErp && this.transactionsErp.data.length)
    },
    paginateLoading() {
      this.loading = true
    },
    refresh() {
      this.$refs.pagination.refreshWithFilter(this.filterSelectErp)
    },
    add() {
      this.$router.push({name: 'transactions-erp-add'})
    }
  },

  watch: {
    'transactionsErp': {
      handler: function () {
        this.loading = false
      },
      deep: true
    }
  }
}
</script>
