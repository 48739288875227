import {Provider} from '/src/parents/provider'

class DashboardRepository extends Provider {
  getMonthData(date, noCache) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/dashboard/month?date=${date}&no-cache=${noCache}`)
  }

  getHistoryData(noCache) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/dashboard/month/history?no-cache=${noCache}`)
  }
}

export default new DashboardRepository()
