<template>
  <div>
    <div>
      <h1 class="menux-title">Usuários</h1>
    </div>

    <div>
      <app-loader :loading="loading">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Email</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item) of items" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
          </tr>
          </tbody>
        </table>
      </app-loader>
    </div>

  </div>
</template>

<script>
import UserProvider from "../../settings/components/register/providers/users";
import AppLoader from "../../../components/loader.vue";

export default {
  components: {AppLoader},
  async created() {
    this.loading = true
    try {
      const data = await UserProvider.get();
      this.items = data.data
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  },
  data() {
    return {
      loading: false,
      items: []
    }
  }
}
</script>
