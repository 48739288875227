const items = {
  ERP: 1,
  Bank: 2,
  Strongbox: 3,
  Institution: 4,
  props: {
    1: {name: 'ERP'},
    2: {name: 'Banco'},
    3: {name: 'Cofre'},
    4: {name: 'Matriz'},
  }
}

export default Object.freeze(items)
