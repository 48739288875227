<template>
  <app-layout-simple>
    <div class="container">
      <div class="form-login">
        <form @submit.prevent="submit">
          <div>
            <h1>Bem-vindo à bordo!</h1>
            <h2>Organize seu time, customize seus processos e ganhe tempo com o TeiaValues!</h2>
          </div>

          <div class="form-group">
            <label for="ipt-email">Email</label>
            <input id="ipt-email" type="text" name="email" v-model="user.email" class="form-control">
          </div>

          <button class="btn btn-primary btn-block">salvar</button>

          <div>Já tem uma conta Teia Values?
            <router-link :to="{name: 'auth-login'}">Login</router-link>
          </div>
        </form>
      </div>
    </div>
  </app-layout-simple>
</template>
<script>
import AppLayoutSimple from '/src/components/layouts/simple'

export default {
  components: {
    AppLayoutSimple
  },
  data: function () {
    return {
      user: {}
    }
  },
  methods: {
    submit() {
      let self = this
      let redirect = self.$route.query.redirect || '/dashboard'

      self.$router.push(redirect)
    }
  }
}
</script>
