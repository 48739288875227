<template>
  <section>
    <div>
      <div class="card5-item-header">
        <div class="subtitle">
          Cheque
        </div>
      </div>
      <div class="card5-item-body">
        <div class="row">
          <div class="item col-sm-3">
            <div class="title">Data de emissão</div>
            <div class="value">{{ checkPredated.issue_date | formatDate }}</div>
          </div>
          <div class="item col-sm-3">
            <div class="title">Data de vencimento</div>
            <div class="value">{{ checkPredated.due_date | formatDate }}</div>
          </div>
        </div>
        <div class="row">
          <div class="item col-sm-3">
            <div class="title">Numero</div>
            <div class="value">{{ checkPredated.number }}</div>
          </div>
          <div class="item col-sm-3">
            <div class="title">Cliente</div>
            <div class="value">{{ formatClient(checkPredated.client) }}</div>
          </div>
          <div class="item col-sm-6">
            <div class="title">CMC7</div>
            <div class="value">{{ checkPredated.cmc7 | cmc7 }}</div>
          </div>
        </div>


        <div class="row">
          <div class="item col-sm-3">
            <div class="title">Banco</div>
            <div class="value">{{ bankValue() }}</div>
          </div>
          <div class="item col-sm-2">
            <div class="title">Agência</div>
            <div class="value">{{ agencyValue() }}</div>
          </div>
          <div class="item col-sm-2">
            <div class="title">Conta</div>
            <div class="value">{{ accountValue() }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['checkPredated'],
  methods: {
    formatClient(value) {
      if (value.length === 11) {
        return this.$options.filters.cpf(value)
      }

      if (value.length === 14) {
        return this.$options.filters.cnpj(value)
      }

      return value
    },
    bankValue() {
      // let bankData = this.bankAccount.data.bank.data
      return this.$options.filters.bankCode(this.checkPredated.bank_code)
    },
    agencyValue() {
      let agencyCheck = this.checkPredated.bank_agency_check ? '-' + this.checkPredated.bank_agency_check : ''
      return this.checkPredated.bank_agency + agencyCheck
    },
    accountValue() {
      let accountCheck = this.checkPredated.bank_account_check ? '-' + this.checkPredated.bank_account_check : ''
      return this.checkPredated.bank_account + accountCheck
    }
  }
}
</script>
