<template>
  <app-layout-site>
    <div class="card5">
      <h1>Relatórios</h1>

      <div class="header-destination-container">
        <h4>Formato 1</h4>

        <div class="vision-a">
          <router-link :to="{ name: item.router_to}" class="vision-item" v-for="(item,index) of items" :key="index">
            <h4>{{ item.title }}</h4>
            <div class="vision-actions">
              <div class="vision-actions-item">
                <div>
                  <img
                    :title="TransactionTypeIconUtil.transactionTypeName(item.origin_type_id)"
                    :src="TransactionTypeIconUtil.transactionTypeIcon(item.origin_type_id)"
                    alt="">
                </div>

              </div>
              <div class="vision-actions-arrow"></div>
              <!--              <div class="vision-actions-item">-->
              <!--                <div>-->
              <!--                  <img-->
              <!--                    :title="CashTypeIconUtil.cashTypeName(item.cash_type_id)"-->
              <!--                    :src="CashTypeIconUtil.cashTypeIcon(item.cash_type_id)"-->
              <!--                    alt="">-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="vision-actions-arrow"></div>-->
              <div class="vision-actions-item">
                <div>
                  <img
                    :title="TransactionTypeIconUtil.transactionTypeName(item.destination_type_id)"
                    :src="TransactionTypeIconUtil.transactionTypeIcon(item.destination_type_id)"
                    alt="">
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <!--        <router-link :to="{ name: 'reports-format-01-erp-money-bank'}" class="header-destination">-->
        <!--          <div class="item">ERP</div>-->
        <!--          <div class="item">Dinheiro</div>-->
        <!--          <div class="item">Banco</div>-->
        <!--        </router-link>-->

        <!--        <router-link :to="{ name: 'reports-format-01-erp-pix-bank'}" class="header-destination">-->
        <!--          <div class="item">ERP</div>-->
        <!--          <div class="item">Pix</div>-->
        <!--          <div class="item">Banco</div>-->
        <!--        </router-link>-->

        <!--        <router-link :to="{ name: 'reports-format-01-erp-bank-check-bank'}" class="header-destination">-->
        <!--          <div class="item">ERP</div>-->
        <!--          <div class="item">Cheque</div>-->
        <!--          <div class="item">Banco</div>-->
        <!--        </router-link>-->

      </div>

      <div class="header-destination-container">
        <h4>Formato 2</h4>
        <div class="vision-a">
          <router-link :to="{ name: item.router_to}" class="vision-item" v-for="(item,index) of items2" :key="index">
            <h4>{{ item.title }}</h4>
            <div class="vision-actions">
              <div class="vision-actions-item">
                <div>
                  <img
                    :title="TransactionTypeIconUtil.transactionTypeName(item.origin_type_id)"
                    :src="TransactionTypeIconUtil.transactionTypeIcon(item.origin_type_id)"
                    alt="">
                </div>

              </div>
              <div class="vision-actions-arrow"></div>
              <!--              <div class="vision-actions-item">-->
              <!--                <div>-->
              <!--                  <img-->
              <!--                    :title="CashTypeIconUtil.cashTypeName(item.cash_type_id)"-->
              <!--                    :src="CashTypeIconUtil.cashTypeIcon(item.cash_type_id)"-->
              <!--                    alt="">-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="vision-actions-arrow"></div>-->
              <div class="vision-actions-item">
                <div>
                  <img
                    :title="TransactionTypeIconUtil.transactionTypeName(item.destination_type_id)"
                    :src="TransactionTypeIconUtil.transactionTypeIcon(item.destination_type_id)"
                    alt="">
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <!--        <router-link :to="{ name: 'reports-format-02-erp-money-bank'}" class="header-destination">-->
        <!--          <div class="item">ERP</div>-->
        <!--          <div class="item">Dinheiro</div>-->
        <!--          <div class="item">Banco</div>-->
        <!--        </router-link>-->
      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'
import TransactionTypeIconUtil from '/src/utils/icon-transaction-type'
import CashTypeIconUtil from '/src/utils/icon-cash-type'
import TransactionTypeEnum from '/src/enums/transaction-type'
import CashTypeEnum from '/src/enums/cash-type'


const items = [
  {
    router_to: 'reports-format-01-erp-money-bank',
    title: 'Depositos',
    origin_type_id: TransactionTypeEnum.ERP,
    cash_type_id: CashTypeEnum.Money,
    destination_type_id: TransactionTypeEnum.Bank,
  },
  {
    router_to: 'reports-format-01-erp-pix-bank',
    title: 'Pix',
    origin_type_id: TransactionTypeEnum.ERP,
    cash_type_id: CashTypeEnum.Pix,
    destination_type_id: TransactionTypeEnum.Bank,
  },
  {
    router_to: 'reports-format-01-erp-bank-check-bank',
    title: 'Cheque à vista',
    origin_type_id: TransactionTypeEnum.ERP,
    cash_type_id: CashTypeEnum.BankCheck,
    destination_type_id: TransactionTypeEnum.Bank,
  }
]


const items2 = [
  {
    router_to: 'reports-format-02-erp-money-bank',
    title: 'Depositos',
    origin_type_id: TransactionTypeEnum.ERP,
    cash_type_id: CashTypeEnum.Money,
    destination_type_id: TransactionTypeEnum.Bank,
  },
]


export default {
  components: {
    AppLayoutSite
  },
  data() {
    return {
      items,
      items2,
      CashTypeIconUtil,
      TransactionTypeIconUtil,
    }
  }
}
</script>
