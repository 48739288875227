<template>
  <div>
    <div class="card6">
      <div class="card6-list">
        <div class="d-flex">
          <div>
            <div v-if="institutions != null && institutions.data.length > 0">
              {{ institutions.meta.pagination.total|integer }} resultados
            </div>
          </div>
          <div class="ml-auto action-buttons">
            <button class="no-fill" @click="create()">
              <span class="icon-add"></span>
            </button>
            <button class="no-fill" @click="refresh()">
              <span class="icon-sync"></span>
            </button>
          </div>
        </div>
        <app-loader :loading="loading">
          <institutions-table :institutions="institutions"/>
          <app-no-results v-if="!loading && institutionsIsEmpty()"/>
          <app-pagination v-show="!institutionsIsEmpty()"
                          :state="institutions"
                          :update="updateInstitutions"
                          :append="appendInstitutions"
                          @loading="paginateLoading"
                          ref="pagination"/>
          <div v-if="!loading && !institutions">
            <app-no-results/>
          </div>
        </app-loader>
      </div>
    </div>
  </div>
</template>

<script>
import AppLoader from '/src/components/loader'
import AppNoResults from '/src/components/NoResults'
import AppPagination from '/src/components/pagination'
import Settings from '/src/utils/settings'
import {mapActions, mapState} from 'vuex'
import InstitutionsTable from './table'

export default {
  components: {
    AppLoader,
    AppNoResults,
    AppPagination,
    InstitutionsTable
  },

  created() {
    this.settings = Settings.getSettings()
  },

  data() {
    return {
      loading: false,
      settings: null
    }
  },
  computed: {
    ...mapState('settingsRegister', ['institutions'])
  },

  methods: {
    ...mapActions('settingsRegister', ['updateInstitutions', 'appendInstitutions']),
    institutionsIsEmpty() {
      return !(this.institutions && this.institutions.data.length)
    },
    paginateLoading() {
      this.loading = true
    },
    refresh() {
      this.$refs.pagination.refreshState()
    },
    create() {
      this.$router.push({name: 'settings-institution-add'})
    }
  },

  watch: {
    'institutions': {
      handler: function () {
        this.loading = false
      },
      deep: true
    }
  }
}
</script>
