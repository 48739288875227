/* eslint-disable no-template-curly-in-string */
import {Provider} from '/src/parents/provider'
import qs from 'qs'
import axios from 'axios'
import authentication from '/src/features/auth/tasks/authentication'

class TransactionDetailsProvider extends Provider {
  getTransactionStrongbox(id) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-strongboxes/${id}`)
  }

  getTransactionBank(id) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-banks/${id}`)
  }

  getTransactionInstitution(id) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-institutions/${id}`)
  }

  getTransactionErp(id) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-erps/${id}`)
  }

  getTransactionErpByCmc7(cmc7) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-erps-cmc7/${cmc7}`)
  }

  createTransactionErp(item) {
    return this.doRequest('POST', `${process.env.VUE_APP_API_URL}/v1/transactions-erps/add`, item)
  }

  createTransactionBank(item) {
    return this.doRequest('POST', `${process.env.VUE_APP_API_URL}/v1/transactions-bank/add`, item)
  }

  updateTransactionBank(item) {
    return this.doRequest('PATCH', `${process.env.VUE_APP_API_URL}/v1/transactions-bank/edit/${item.id}`, item)
  }

  updateTransactionErp(item) {
    return this.doRequest('PATCH', `${process.env.VUE_APP_API_URL}/v1/transactions-erp/edit/${item.id}`, item)
  }

  softTransactionErpDelete(id) {
    return this.doRequest('PATCH', `${process.env.VUE_APP_API_URL}/v1/transactions-erp/soft-delete/${id}`)
  }

  updateTransactionErpChargeback(id) {
    return this.doRequest('PATCH', `${process.env.VUE_APP_API_URL}/v1/transactions-erp/chargeback/${id}`)
  }

  findTransactionErpByIdEdit(id) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-erps/${id}/edit`)
  }

  conciliationManual($payload) {
    return this.doRequest('POST', `${process.env.VUE_APP_API_URL}/v1/conciliation/manual`, $payload)
  }

  getTransactionInstitutionsCheckPredatedPending(filters) {
    let query = qs.stringify(filters)
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/cp/list?${query}`)
  }

  downloadBiu(payload) {
    let url = `${process.env.VUE_APP_API_URL}/v1/cp/download-biu`
    let options = {
      headers: {
        'Content-Type': 'application/json',
        // 'Accept': 'text/plain',
        'Accept': 'application/json',
        'Authorization': authentication.getToken(),
        'Access-Control-Expose-Headers': 'Accept-Ranges, Access-Control-Allow-Origin, Cache-Control, Connection, Content-Disposition, Content-Encoding, Content-Language, Content-Length, Content-Type, Date, ETag, Keep-Alive, Last-Modified, Server, Vary, X-Powered-By, X-RateLimit-Limit, X-RateLimit-Remaining, X-Suggested-Filename'
      }
    }
    return axios.post(url, payload, options)
  }

  downloadPdf(payload) {
    let url = `${process.env.VUE_APP_API_URL}/v1/cp/download-pdf`
    let options = {
      headers: {
        'Content-Type': 'application/json',
        // 'Accept': 'text/plain',
        'Accept': 'application/json',
        'Authorization': authentication.getToken(),
      },
      responseType: 'blob',
    }

    return axios.post(url, payload, options)
  }
}

export default new TransactionDetailsProvider()
