import Vue from 'vue'

let format = function (value) {
  value = value.replace(/\D/g, '') // only number
  value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')// format cnpj
  return value
}

Vue.filter('cpf', (value) => {
  return format(value)
})
