<template>
  <app-layout-site>
    <div class="transactions-pix-list-items">
      <div v-for="(item, index) in items" v-bind:key="index" class="transactions-pix-list-item">
        <div>{{ item.id }}</div>
        <div>{{ item.value }}</div>
        <div>{{ item.date }}</div>
        <div>{{ item.txid }}</div>
      </div>
    </div>

    <div>

    </div>
  </app-layout-site>
</template>
<script>
import AppLayoutSite from '/src/components/layouts/site'

const items = [
  {
    id: 'AISoduahs',
    value: 21.33,
    txid: 'HHASDIYGASUD',
    date: '2024-01-01 00:00:00'
  },
  {
    id: 'AISoduahs',
    value: 244.33,
    txid: 'HHASDIYGASUD',
    date: '2024-01-01 00:00:00'
  },
  {
    id: 'AISoduahs',
    value: 21.33,
    txid: 'HHASDIYGASUD',
    date: '2024-01-01 00:00:00'
  },
  {
    id: 'AISoduahs',
    value: 244.33,
    txid: 'HHASDIYGASUD',
    date: '2024-01-01 00:00:00'
  }
]

export default {
  components: {
    AppLayoutSite,
  },
  data() {
    return {
      items,
    }
  }
}
</script>
