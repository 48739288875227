<template>
  <app-layout-site>
    <div class="card5 settings">
      <h1>Configurações</h1>
      <div class="panel">
        <div class="nav">
          <div class="nav-group">
            <div class="nav-heading">Geral</div>

            <ul>
              <li>
                <router-link tag="a" :to="{name: 'settings-general-transactions'}">Transações</router-link>
              </li>
              <li>
                <router-link tag="a" :to="{name: 'general-others'}">Outros</router-link>
              </li>
            </ul>
          </div>
          <div class="nav-group">
            <div class="nav-heading">Cadastros</div>
            <ul>
              <li>
                <router-link tag="a" :to="{name: 'settings-institution-index'}">Estabelecimentos</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'

export default {
  components: {
    AppLayoutSite
  }
}
</script>
