<template>
  <div class="card-10">
    <div class="title">{{ title }}</div>
    <div>
      <apex-charts
        type="bar"
        :options="chartOptions"
        :series="series"
        :height="height"
      ></apex-charts>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts';

export default {
  components: {
    ApexCharts,
  },
  props: {
    series: {},
    title: {},
    height: {}
  },
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        height: this.height,
        stroke: {
          show: true,
          curve: "straight",
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        // colors: ['#222', '#333'],
        // colors: ['#ffa3ad', '#ed5559', '#ffe15f','#ffc25c','#438add'],
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "right",
          horizontalAlign: "center",
          floating: false,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 5,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        grid: {
          show: false,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toLocaleString('pt-BR');
            }
          }
        }
      },
    };
  },
};
</script>
