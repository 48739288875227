/* eslint-disable no-unused-vars */
// PROVIDERS
import erpProvider from '../providers/erp'
import strongboxProvider from '../providers/strongbox'
import institutionProvider from '../providers/institution'
import bankProvider from '../providers/bank'
// MUTATIONS

export default {
  updateErp({commit}, payload) {
    erpProvider.get(payload)
      .then(response => {
        commit('SET_ERP', response)
      })
  },

  appendErp({commit}, payload) {
    erpProvider.get(payload)
      .then(response => {
        commit('APPEND_ERP', response)
      })
  },

  clearErp({commit}) {
    commit('CLEAR_ERP')
  },

  updateStrongbox({commit}, payload) {
    strongboxProvider.get(payload)
      .then(response => {
        commit('SET_TRANSACTION_STRONGBOX', response)
      })
  },

  appendStrongbox({commit}, payload) {
    strongboxProvider.get(payload)
      .then(response => {
        commit('APPEND_TRANSACTION_STRONGBOX', response)
      })
  },

  updateBank({commit}, payload) {
    // console.log('action:updateBank', JSON.parse(JSON.stringify(payload)))

    bankProvider.get(payload)
      .then(response => {
        console.log('action:updateBank:commit')
        commit('SET_TRANSACTION_BANK', response)
      })
  },

  appendBank({commit}, payload) {
    bankProvider.get(payload)
      .then(response => {
        commit('APPEND_TRANSACTION_BANK', response)
      })
  },

  updateInstitution({commit}, payload) {
    institutionProvider.get(payload)
      .then(response => {
        commit('SET_TRANSACTION_INSTITUTION', response)
      })
  },
  appendInstitution({commit}, payload) {
    institutionProvider.get(payload)
      .then(response => {
        commit('APPEND_TRANSACTION_INSTITUTION', response)
      })
  },

}
