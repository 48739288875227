<template>

  <div>
    <section>
      <div class="card5-item-header">
        <div class="title"><span class="icon-signpost"></span>Detalhes da Transação</div>
      </div>
      <div class="card5-item-body">
        <div class="row">
          <div class="item col-sm-3">
            <div class="title">Identificação
              <app-info title="Identificador no Teia Values"></app-info>
            </div>
            <div class="value">{{ transaction.id }}
            </div>
          </div>
          <div class="item col-sm-3">
            <div class="title">Data de Criação
              <app-info title="data de criação do registro no Teia Values"></app-info>
            </div>
            <div class="value">{{ transaction.created_at | formatDateTime }}</div>
          </div>
          <div class="item col-sm-3">
            <div class="title">Última Atualização
              <app-info title="última atualização em algum dos campos dessa transação"></app-info>
            </div>
            <div class="value">{{ transaction.updated_at | formatDateTime }}</div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="card5-item-header">
        <!--<div class="title"><span class="icon-signpost"></span>Detalhes da Transação</div>-->
        <div class="subtitle">Informações do sistema de origem</div>
      </div>
      <div class="card5-item-body">
        <div class="row">
          <!--<div class="item col-sm-3">-->
          <!--<div class="title">Identificação-->
          <!--<app-info title="Identificador no sistema de origem"></app-info>-->
          <!--</div>-->
          <!--<div class="value">{{ '-' }}</div>-->
          <!--</div>-->
          <div class="item col-sm-12" v-if="transaction.transaction_type_id === TransactionTypeEnum.ERP">
            <div class="title">Identificação
              <app-info title="Identificador no sistema de origem"></app-info>
            </div>

            <div class="value" >
              {{ transaction.provider_id !== null ? transaction.provider_id : '-' }}
            </div>
          </div>
          <div class="item col-sm-3">
            <div class="title">Data de Criação
              <app-info title="data da criação no sistema de origem"></app-info>
            </div>
            <div class="value">
              {{ transaction.processed_at | formatDateTime }}
            </div>
          </div>
          <div class="item col-sm-3">
            <div class="title">Data da Ocorrência
              <app-info title="data da ocorrência da transação"></app-info>

              <span class="date-diff"
                    title="diferença em dias entre a data de criação do registro e a data de ocorrência"
                    v-if="transaction.transaction_type_id === TransactionTypeEnum.ERP"
              >
                {{ diffday(transaction.date, transaction.processed_at) }}
              </span>
            </div>
            <div class="value"><strong>{{ transaction.date | formatDateTime }}</strong></div>
          </div>
        </div>
      </div>
    </section>
  </div>

</template>
<script>
import AppInfo from '/src/components/info'
import moment from 'moment'
import TransactionTypeEnum from '/src/enums/transaction-type'

export default {
  components: {
    AppInfo
  },
  data() {
    return {
      TransactionTypeEnum,
    }
  },
  props: ['transaction'],
  methods: {
    diffday(d1, d2) {
      let a = moment(d1);
      let b = moment(d2);
      return Math.abs(a.diff(b, 'days'))
    }
  }
}
</script>
