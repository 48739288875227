<template>
  <div class="card-10">
    <div class="title">{{ this.title }}</div>
    <div class="value">
      {{ this.subtitle }}
      {{ this.totalPrefix }}
      {{ this.total }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {},
    subtitle: {},
    total: {},
    totalPrefix: {
      default: "",
    },
  },
  data() {
    return {}
  },
};
</script>
