<template>
  <div>
    <app-table-list>
      <table class="table-list text-center">
        <thead>
        <tr>
          <th>Data</th>
          <th class="text-right">Valor</th>
          <th>Banco</th>
          <th>Conta</th>
          <th>Agência</th>
          <th>Estabelecimento</th>
          <th>Cod. Estabelecimento</th>
          <th>Nº Estabelecimento</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr class="text-center" v-for="(item, index) in transactionsBank.data" :key="index">
          <td>{{ item.date | formatDateTime }}</td>
          <td class="text-right">{{ parseFloat(item.value)|money }}</td>
          <td>{{ item.bank_name }}</td>
          <td>{{ item.bank_account }}</td>
          <td>{{ item.bank_agency }}</td>
          <td>{{ item.institution_name }}</td>
          <td>{{ item.institution_code }}</td>
          <td>{{ item.institution_number }}</td>
          <td class="td-action">
            <div class="btn-action">
              <router-link
                :to="{name: 'transactions-details', params:{origin:'bank', id: item.id}, query: queryTransactionDetails()}">
                <span class="icon-more_horiz"></span>
              </router-link>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </app-table-list>
  </div>
</template>

<script>
import AppTableList from '/src/components/table-list'

export default {
  props: [
    'transactionsBank',
    'ctid',
  ],

  components: {
    AppTableList
  },

  methods: {
    queryTransactionDetails() {
      if (this.ctid !== null) {
        return {ctid: this.ctid}
      }

      return {}
    }
  }
}
</script>
