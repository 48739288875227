const items = {
  Bank: 1,
  Strongbox: 2,
  Institution: 3,
  props: {
    1: {name: 'Banco'},
    2: {name: 'Cofre'},
    3: {name: 'Estabelecimento'},
  }
}

export default Object.freeze(items)
