const banks = [
  {id: 1, name: 'Banco do Brasil'},
  {id: 33, name: 'Santander'},
  {id: 41, name: 'Banrisul'},
  {id: 104, name: 'Caixa Econômica Federal'},
  {id: 133, name: 'Cresol'},
  {id: 237, name: 'Bradesco'},
  {id: 341, name: 'Itaú'},
  {id: 399, name: 'HSBC'},
  {id: 422, name: 'Safra'},
  {id: 707, name: 'Daycoval'},
  {id: 748, name: 'Sicredi'},
  {id: 756, name: 'Sicoob'}
]

export default banks
