<template>
  <div class="col-md-12" v-if="transaction != null">
    <div class="card5"
         v-bind="transactionInstitution = transaction.transaction_institution.data"
    >
      <app-detail-transaction :transaction="transaction"></app-detail-transaction>
      <section>
        <div class="card5-item-header">
          <div>
            <div class="card5-item-body">
              <div class="row">
                <div class="item col-sm-3">
                  <div class="title">Tipo de Numerário</div>
                  <div class="value">
                    {{ CashTypeEnum.props[transactionInstitution.cash_type_id].name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section v-bind="institutionData = transactionInstitution.institution_origin.data">
        <hr class="divider">
        <div>
          <app-institution :institution="institutionData" subtitle="Estabelecimento de Origem"></app-institution>
        </div>
      </section>

      <hr class="divider">
      <app-check-predated :checkPredated="transactionInstitution.check_predated.data"></app-check-predated>
      <hr class="divider">

      <section>
        <div class="card5-item-header">
          <div class="title"><span class="icon-signpost"></span>Conciliação</div>
        </div>
        <div class="card5-item-body">
          <div class="section-reconciliation-percent">
            <app-conciliation-item-detail :response="response">
            </app-conciliation-item-detail>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import AppDetailTransaction from '/src/features/transactions/components/details-components/detail-transaction'
import AppInstitution from '/src/features/transactions/components/details-components/institution'
import AppCheckPredated from '/src/features/transactions/components/details-components/check-predated'
import ConciliationProvider from '/src/features/transactions/providers/conciliation'
import AppConciliationItemDetail from '/src/components/conciliation-item-detail'
import CashTypeEnum from '/src/enums/cash-type'

export default {
  mounted() {
    this.responseParam()
  },
  components: {
    AppDetailTransaction,
    AppInstitution,
    AppConciliationItemDetail,
    AppCheckPredated
  },
  props: ['transaction'],
  data() {
    return {
      CashTypeEnum: CashTypeEnum,
      response: null,
    }
  },
  methods: {
    responseParam() {
      let self = this

      ConciliationProvider
        .findById(this.$route.params.id)
        .then((response) => {
          self.response = response.data
        })
        .finally(() => {
          // this.loading = false
        })
    },
    formatClient(value) {
      if (value.length === 11) {
        return this.$options.filters.cpf(value)
      }

      if (value.length === 14) {
        return this.$options.filters.cnpj(value)
      }

      return value
    }
  }
}
</script>
