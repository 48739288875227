<template>
  <div class="transaction-card mx-auto t-card2" @click="detailLink()">
    <div class="header">
      <div class="options">
        <router-link :to="{name: 'transactions-details', params:{origin:'institution', id: transaction.id}}"
                     class="btn-card btn-show">
          <span class="icon-keyboard_arrow_right" title="Detalhes"></span>
        </router-link>
      </div>
    </div>
    <div class="conciliation ml-auto">
      <app-conciliation-percentage :percents="transaction.conciliation_percent" class="ml-auto">
      </app-conciliation-percentage>
    </div>
    <div class="body">
      <div class="dl-item-group ">
        <div class="dl-title">Valor</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Origem">valor</div>
          <div class="dl-col dl-col-val destaque">{{ transaction.value | money }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Tipo de valor">tipo</div>
          <div class="dl-col dl-col-val ">
            {{ CashTypeEnum.props[transaction.cash_type_id].name }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">chq. numero</div>
          <div class="dl-col dl-col-val" title="Número do cheque">
            {{ transaction.check_predated_number }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">chq. cliente</div>
          <div class="dl-col dl-col-val" title="Cliente responsável pelo cheque">
            {{ transaction.check_predated_client }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">chq. status</div>
          <div class="dl-col dl-col-val" title="Status do cheque">
            {{ transaction.check_predated_status }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">chq. dt. venc.</div>
          <div class="dl-col dl-col-val" title="Data vencimento do cheque">
            {{ transaction.check_predated_due_date | formatDate }}
          </div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field">chq. dt. emissão.</div>
          <div class="dl-col dl-col-val" title="Data de emissão do cheque">
            {{ transaction.check_predated_issue_date | formatDate }}
          </div>
        </div>
      </div>
      <div class="dl-item-group ">
        <div class="dl-title">Detalhes</div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Data da ocorrência da transação">data de ocorrência</div>
          <div class="dl-col dl-col-val destaque">{{ transaction.date | formatDate }}</div>
        </div>
        <div class="dl-item">
          <div class="dl-col dl-col-field" title="Origem">origem</div>
          <div class="dl-col dl-col-val destaque">Instituição</div>
        </div>
        <!--        <div class="dl-item">-->
        <!--          <div class="dl-col dl-col-field" title="Tipo de Valor">tipo de valor</div>-->
        <!--          <div class="dl-col dl-col-val">-->
        <!--            {{ CashTypeEnum.props[transaction.cash_type_id].name }}-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
      <div class="id" title="Identificador">#{{ transaction.id }}</div>
    </div>
  </div>
</template>

<script>
import AppConciliationPercentage from '/src/components/conciliation-percent'
import CashTypeEnum from '/src/enums/cash-type'

export default {
  props: ['transaction'],
  components: {
    AppConciliationPercentage
  },
  data() {
    return {
      CashTypeEnum: CashTypeEnum,
    }
  },
}
</script>
