import Index from './components/Index'
import Cmc7 from './components/Cmc7'
import BankCheckFile from './components/BankCheckFile'

export default [
  {
    path: '/bank-check/',
    name: 'bank-check-index',
    component: Index
  },
  {
    path: '/bank-check/file',
    name: 'bank-check-file',
    component: BankCheckFile
  },
  {
    path: '/bank-check/cmc7',
    name: 'bank-check-cmc7',
    component: Cmc7
  }
]
