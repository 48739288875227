<template>
  <app-layout-site>
    <div class="card5">
      <h1>Leitora cmc7</h1>
      <section>
        <div class="form-group form-group-cmc7">
          <label for="ipt-cmc7">CMC7</label>
          <div class="description">Digite o CMC7 ou passe o cheque na leitora
            <div>
              Exemplo:
              <div class="badge-code-container">
                <div class="badge-code">00131685 4613863485 900000909474</div>
              </div>
            </div>
          </div>
          <input id="ipt-cmc7" v-model="cmc7Input" name="q" type="text" class="form-control">
        </div>

        <div class="cmc7-values">
          <AppCmc7Item v-for="(item, itemIndex) in items" :key="itemIndex" :cmc7Value="item"></AppCmc7Item>
        </div>
      </section>
    </div>
  </app-layout-site>
</template>

<script>
import AppLayoutSite from '/src/components/layouts/site'
import AppCmc7Item from '/src/features/bankCheck/components/Cmc7Item'

const samples = [
  '00101555 4613863485 900000292788',
  '10433375 4613863485 900000006088',
  '00131685 4613863485 900000909474',
  '00100011 4614566465 900000000114'
];

export default {
  name: 'cmc7',
  data() {
    return {
      items: [],
      cmc7Input: null
    }
  },
  components: {
    AppLayoutSite,
    AppCmc7Item,
  },
  methods: {
    sampleCmc7() {
      let value = samples.pop()
      if (value !== undefined) {
        this.cmc7Input = value
      }
    },
    insertItem(cmc7) {
      this.items.push(cmc7)
    }
  },
  watch: {
    'cmc7Input': function (value) {
      let self = this
      if (value === null) {
        return
      }

      /**
       * <00100011<461279225>590000000011:
       * <10447268<0189001195>600102177748:
       * 104472680189001195600102177748
       */
      // if (!value.match(/<\d{8}<\d{10}>\d{12}:/)) {
      //   // self.cmc7Input = null
      // }

      // 00100011461279225590000000011

      value = value.replace(/\D/g, '')
      if (value.length !== 30) {
        return
      }

      setTimeout(function () {
        self.insertItem(value)
        self.cmc7Input = null
      }, 500)
    }
  }
}
</script>
