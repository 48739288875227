import storage from '/src/utils/storage'
import router from '/src/router'

/**
 * Check if the user already have an token
 * @returns {boolean}
 */

const isAuthenticated = () => {
  let token = storage.get('token')
  return token && token.access_token && token.expires_in && token.refresh_token && token.token_type
}

/**
 * Remove the stored token and do the logout
 */
const logout = () => {
  storage.forget('token')
  storage.forget('settings')
  router.push({name: 'auth-login'})
}

/**
 * Get the token object from storage
 * @returns {*}
 */
const getToken = () => {
  let token = storage.get('token')
  if (token != null) {
    return token.token_type + ' ' + token.access_token
  }
  return null
}

export default {
  isAuthenticated,
  logout,
  getToken
}
