<template>
  <app-layout-site>

    <div class="card5 header">
      <app-loader :loading="loading">
        <h1>Relatório - Formato 01</h1>

        <div class="header-destination">
          <div class="item">ERP</div>
          <div class="item">Cheque</div>
          <div class="item">Banco</div>
        </div>

        <div class="card-form">
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="submit">
              <div class="filter-select">
                <div class="group">
                  <div class="title">Data de ocorrência</div>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <app-datetime-picker id="ipt-created-at"
                                         v-model="form.date"

                                         output-format='YYYY-MM-DD'
                                         format="YYYY-MM-DD"
                                         formatted="YYYY-MM-DD"
                                         label="Selecione uma data"

                                         :min-date="datepicker.minDate"
                                         :max-date="datepicker.maxDate"
                                         :auto-close="true"
                                         :only-date="true"

                                         :custom-shortcuts="customShortcuts"
                    ></app-datetime-picker>
                    <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="group">
                  <div class="form-check">
                    <label class="form-check-label" for="checkXls">
                      <input type="radio" name="format" v-model="form.format" value="xls" checked="checked"
                             id="checkXls">
                      Xls
                    </label>
                  </div>
                </div>
                <button class="btn btn-primary btn-block" :disabled="invalid">aplicar</button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </app-loader>
    </div>
  </app-layout-site>
</template>

<script>
import AppLoader from '/src/components/loader'
import AppLayoutSite from '/src/components/layouts/site'
import AppDatetimePicker from '/src/components/date-time-picker'
import RelatorioProvider from '/src/features/transactions/providers/relatorios'
import moment from 'moment'

const minDate = moment('20180101', 'YYYYMMDD').format('YYYY-MM-DD')
const maxDate = moment().format('YYYY-MM-DD')

export default {
  components: {
    AppLayoutSite,
    AppLoader,
    AppDatetimePicker
  },
  data() {
    return {
      datepicker: {
        minDate: minDate,
        maxDate: maxDate
      },
      customShortcuts: [
        {key: 'thisWeek', label: 'Esta Semana', value: 'isoWeek'},
        {key: 'last5Days', label: 'Últimos 5 dias', value: 5},
        {key: 'thisMonth', label: 'Este Mês', value: 'month'},
        {key: 'lastMonth', label: 'Ultimo Mês', value: '-month'},
        {key: 'thisYear', label: 'Este Ano', value: 'year'}
      ],
      loading: false,
      form: {
        date: moment().format('YYYY-MM-DD'),
        format: 'xls'
      }
    }
  },
  methods: {
    submit() {

      this.loading = true
      let data = {
        date: this.form.date
      }

      this.loading = true

      RelatorioProvider.erpBankCheckBankXLS(data)
        .then(res => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'relatorio-erp-cheque-banco.xls');
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
