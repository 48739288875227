<template>
  <section>
    <div class="card5-item-header">
      <!--<div class="title"><span class="icon-account_balance"></span>Domicílio Bancário</div>-->
      <!--<div class="subtitle">informações da conta bancária</div>-->
      <div class="subtitle"><span class="icon-account_balance"></span> Domicílio Bancário</div>
    </div>
    <div class="card5-item-body">
      <div class="row">
        <div class="item col-sm-3">
          <div class="title">Banco</div>
          <div class="value">{{ bankValue() }}</div>
        </div>
        <div class="item col-sm-2">
          <div class="title">Agência</div>
          <div class="value">{{ agencyValue() }}</div>
        </div>
        <div class="item col-sm-2">
          <div class="title">Conta</div>
          <div class="value">{{ accountValue() }}</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['bankAccount'],
  methods: {
    bankValue() {
      let bankData = this.bankAccount.data.bank.data
      let code = this.$options.filters.bankCode(bankData.code)
      return code + '-' + bankData.name_short
    },
    agencyValue() {
      let agencyCheck = this.bankAccount.data.agency_check ? '-' + this.bankAccount.data.agency_check : ''
      return this.bankAccount.data.agency + agencyCheck
    },
    accountValue() {
      let accountCheck = this.bankAccount.data.account_check ? '-' + this.bankAccount.data.account_check : ''
      return this.bankAccount.data.account + accountCheck
    }
  }
}
</script>
