import Vue from 'vue'
import App from '/src/features/App'
import router from '/src/router'
import {store} from '/src/store/store'
import '/src/filters/index'
import '/src/validator/index'
import '/src/styles/global.scss'
import AuthInterceptor from '/src/features/auth/middlewares/interceptor'
import settings from '/src/utils/settings'
import '/src/utils/load/vue-sweetalert2'
import '/src/utils/load/bootstrap-vue'

Vue.config.productionTip = false
AuthInterceptor.interceptor(router)
if (!settings.isSet()) settings.setDefaultSettings()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
