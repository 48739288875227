<template>
  <header class="header-left">
    <div class="menu-container">
      <div class="menu">
        <!--        <div class="menu-1">-->
        <!--          <div class="menu-1-content">-->
        <!--            <ol class="menu-1-nav-top">-->
        <!--              <li class="nav-project">-->
        <!--                <router-link :to="{name: 'dashboard-index'}">-->
        <!--                  <div class="img">-->
        <!--                    <img src="@/assets/logo-project.png" alt="">-->
        <!--                  </div>-->
        <!--                </router-link>-->
        <!--              </li>-->

        <!--            </ol>-->
        <!--            <div class="menu-1-nav-bottom">-->
        <!--              <ol class="nav-user">-->
        <!--                <li>-->
        <!--                  <div class="nav-logout">-->
        <!--                    <a href="#" @click.prevent="logout" class="logout">-->
        <!--                      <span class="icon-exit_to_app" title="sair"></span>-->
        <!--                    </a>-->
        <!--                  </div>-->
        <!--                </li>-->
        <!--              </ol>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="menu-2">

          <!--          <div class="img">-->
          <!--            <img src="@/assets/logo-project.png" alt="">-->
          <!--          </div>-->

          <div class="menu-2-content">

            <div class="img">
              <img src="@/assets/logo-bbb.png" alt="">
            </div>

            <ol class="nav-2">
              <li :class="{'active':this.$route.path === '/dashboard'}">
                <div class="nav-item">
                  <router-link :to="{name: 'dashboard-index'}">
                    <span class="icon-dashboard"></span>Dashboard
                  </router-link>
                </div>
              </li>
              <li :class="{'active':this.$route.path.startsWith('/transactions') === true}">
                <div class="nav-item">
                  <router-link :to="{name: 'transactions-index'}">
                    <span class="icon-signpost"></span>Transações
                  </router-link>
                </div>
              </li>
              <li :class="{'active':this.$route.path.startsWith('/visions') === true}">
                <div class="nav-item">
                  <router-link :to="{name: 'visions-index'}">
                    <span class="icon-domain"></span>
                    Visões
                  </router-link>
                </div>
              </li>

              <li :class="{'active':this.$route.path.startsWith('/bank-check') === true}">
                <div class="nav-item">
                  <router-link :to="{name: 'bank-check-index'}">
                    <span class="icon-grain"></span>
                    Cheque Pré-Datado
                  </router-link>
                </div>
              </li>

              <li :class="{'active':this.$route.path.startsWith('/reports') === true}">
                <div class="nav-item">
                  <router-link :to="{name: 'reports-index'}">
                    <span class="icon-document-diagrams"></span>
                    Relatórios
                  </router-link>
                </div>
              </li>

              <li :class="{'active':this.$route.path.startsWith('/conciliations') === true}">
                <div class="nav-item">
                  <router-link :to="{name: 'visions-conciliations-manual'}">
                    <span class="icon-grain"></span>
                    Conciliação Manual
                  </router-link>
                </div>
              </li>

              <li :class="{'active':this.$route.path.startsWith('/upload') === true}">
                <div class="nav-item">
                  <router-link :to="{name: 'upload'}">
                    <span class="icon-grain"></span>
                    Upload
                  </router-link>
                </div>
              </li>
              <li :class="{'active':this.$route.path.startsWith('/visions/control/user-modified') === true}">
                <div class="nav-item">
                  <router-link :to="{name: 'visions-user-modified'}">
                    <span class="icon-grain"></span>
                    Transações modificadas por lojas
                  </router-link>
                </div>
              </li>

              <li class="separator"></li>
              <!--              <li :class="{'active':this.$route.path.startsWith('/settings') === true}">-->
              <!--                <div class="nav-item">-->
              <!--                  <router-link :to="{name: 'settings-general-transactions'}">-->
              <!--                    <span class="icon-cog"></span>-->
              <!--                    Configurações-->
              <!--                  </router-link>-->
              <!--                </div>-->
              <!--              </li>-->

              <li :class="{'active':this.$route.path.startsWith('/settings2') === true}">
                <div class="nav-item">
                  <router-link :to="{name: 'settings2-general'}">
                    <span class="icon-cog"></span>
                    Configurações
                  </router-link>
                </div>
              </li>


              <li>
                <div class="nav-item">
                  <a href="#" @click.prevent="logout" class="logout">
                    <span class="icon-exit_to_app" title="sair"></span>
                    Sair
                  </a>
                  <!--                  <router-link :to="{name: 'settings-general-transactions'}">-->
                  <!--                    <span class="icon-cog"></span>-->
                  <!--                    Configurações-->
                  <!--                  </router-link>-->
                </div>
              </li>


            </ol>
          </div>
        </div>
        <div class="menu-3">
          <div class="menu-3-content"></div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import authentication from '/src/features/auth/tasks/authentication'

export default {
  name: 'app-header',

  methods: {
    logout() {
      authentication.logout()
      this.$router.push({name: 'auth-login'})
    }
  }
}
</script>
