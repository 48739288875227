<template>
  <app-layout-simple>
    <div class="container">
      <div class="card-login">
        <b-alert variant="danger" dismissible :show="loginFail">Falha ao autenticar! Email ou senha inválido.
        </b-alert>
        <form @submit.prevent="submit">
          <app-loader :loading="loading">
            <div class="header">
              <h1>Bem vindo ao TeiaValues</h1>
              <!--<p>Acesse sua conta ou crie uma</p>-->
              <p>Acesse sua conta</p>
            </div>

            <div class="form-group">
              <label for="ipt-email">Email</label>
              <input id="ipt-email" type="text" name="email" v-model="user.email"
                     class="form-control form-control-lg">
            </div>
            <div class="form-group">
              <label for="ipt-password">Senha</label>
              <input id="ipt-password" type="password" v-model="user.password"
                     class="form-control form-control-lg">
            </div>
            <button class="btn btn-lg btn-primary btn-block btn-save">Acessar <span
              class="icon-keyboard_arrow_right"></span>
            </button>
          </app-loader>
        </form>
      </div>
    </div>
  </app-layout-simple>
</template>
<script>
import AppLayoutSimple from '/src/components/layouts/simple'
import authentication from '../tasks/authentication'
import token from '../tasks/token'
import AppLoader from '/src/components/loader'
import Settings from '/src/utils/settings'

export default {
  components: {
    AppLayoutSimple,
    AppLoader,
  },

  mounted() {
    if (authentication.isAuthenticated()) {
      this.dashboard()
    }
  },

  data() {
    return {
      user: {},
      loading: false,
      loginFail: false
    }
  },

  methods: {
    submit() {
      let self = this
      Settings.setDefaultSettings()
      if (!authentication.isAuthenticated()) {
        this.loading = true
        this.loginFail = false

        // try {

        token.getToken(this.user)
          .then(() => {
            self.dashboard()
          })
          .catch(() => {
            this.user.email = null
            this.user.password = null
            self.loginFail = true
          })
          .finally(() => {
            self.loading = false
          })

      } else {
        this.dashboard()
      }
    },

    dashboard() {
      this.$router.push({name: 'dashboard-index'})
    }
  }

}
</script>
