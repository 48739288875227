<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'layout-simple',
  beforeCreate: function () {
    document.body.className = 'bg-simple'
  }
}
</script>
