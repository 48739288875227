<template>
  <app-layout-site>

    <div class="card5 header">
      <app-loader :loading="loading">
        <h1>Relatório - Formato 02</h1>

        <div class="header-destination">
          <div class="item">ERP</div>
          <div class="item">Dinheiro</div>
          <div class="item">Banco</div>
        </div>

        <div class="card-form">

          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="submit">
              <div class="filter-select">
                <div class="group">
                  <div class="title">Período</div>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <!--                    <app-datetime-picker id="ipt-created-at"-->
                    <!--                                         v-model="form.date"-->

                    <!--                                         output-format='YYYY-MM'-->
                    <!--                                         format="YYYY-MM"-->
                    <!--                                         formatted="YYYY-MM-DD"-->
                    <!--                                         label="Selecione um mês"-->

                    <!--                                         :min-date="datepicker.minDate"-->
                    <!--                                         :max-date="datepicker.maxDate"-->
                    <!--                                         :auto-close="true"-->
                    <!--                                         :only-date="true"-->

                    <!--                    ></app-datetime-picker>-->


                    <app-datetime-picker
                      v-model="form.date"

                      output-format='YYYYMMDD'
                      format="YYYY-MM-DD"
                      formatted="YYYY-MM-DD"
                      label="Selecione um período"

                      :range="true"
                      :auto-close="true"
                      :only-date="true"
                      :min-date="datepicker.minDate"
                      :max-date="datepicker.maxDate"
                      :custom-shortcuts="customShortcuts"
                    ></app-datetime-picker>

                    <span class="msg-error" v-if="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="group">
                  <div class="title">Estabelecimento</div>
                  <app-multiselect v-model="multiselect.institutions.selected"
                                   :options="multiselect.institutions.options"
                                   :loading="multiselect.institutions.loading"
                                   @search-change="asyncFind"
                                   id="ajax"
                                   label="name"
                                   track-by="id"
                                   placeholder="Pesquise o estabelecimento"
                                   :multiple="true"
                                   :searchable="true"
                                   :clear-on-select="false"
                                   :close-on-select="false"
                                   :internal-search="true"
                                   class="slt-w-max">
                  </app-multiselect>

                </div>

                <div class="group">
                  <div class="form-check">
                    <label class="form-check-label" for="checkCsv">
                      <input type="radio" name="format" v-model="form.format" value="csv" checked="checked"
                             id="checkCsv">
                      Csv
                    </label>
                  </div>
                </div>
                <button class="btn btn-primary btn-block" :disabled="invalid">aplicar</button>

              </div>
            </form>
          </ValidationObserver>

          <div class="alert alert-warning" v-show="showLimitExceeded">
            <div>A quantidade de registros dessa solicitação é muito grande.</div>
            <div>Ajuste o filtro e tente novamente.</div>
          </div>

        </div>
      </app-loader>
    </div>
  </app-layout-site>
</template>

<script>
import AppLoader from '/src/components/loader'
import AppLayoutSite from '/src/components/layouts/site'
import AppDatetimePicker from '/src/components/date-time-picker'
import RelatorioProvider from '/src/features/transactions/providers/relatorios'
import moment from 'moment'
import InstitutionsProvider from "/src/features/settings/components/register/providers/institutions";
import AppMultiselect from '/src/components/multiselect'
import _ from "lodash";

const minDate = moment('20180101', 'YYYYMMDD').format('YYYY-MM-DD')
const maxDate = moment().format('YYYY-MM-DD')

export default {
  components: {
    AppLayoutSite,
    AppLoader,
    AppDatetimePicker,
    AppMultiselect,
  },
  data() {
    return {
      customShortcuts: [
        // {label: `Hoje`, value: 'day', isSelected: false},
        // {label: 'Ontem', value: '-day', isSelected: false},
        // {label: '5 dias atrás', value: 4, isSelected: false},
        // {label: 'Esta Semana', value: 'week', isSelected: true},
        // // {label: '1 Semana atrás', value: '-week', isSelected: false},
        // // { label: 'This iso Week', value: 'isoWeek', isSelected: true },
        // // { label: 'Last iso Week', value: '-isoWeek', isSelected: false },
        // {label: 'Este Mês', value: 'month', isSelected: false},
        // {label: '1 Mês atrás', value: '-month', isSelected: false},
        // {label: 'Este Ano', value: 'year', isSelected: false},
        // {label: 'Este Ano', value: '-year', isSelected: false},

        {key: 'thisWeek', label: 'Esta Semana', value: 'isoWeek'},
        // {key: 'lastWeek', label: 'Last week', value: '-isoWeek'},
        {key: 'last5Days', label: 'Últimos 5 dias', value: 5},
        // {key: 'last30Days', label: 'Last 30 days', value: 30},
        {key: 'thisMonth', label: 'Este Mês', value: 'month'},
        {key: 'lastMonth', label: 'Ultimo Mês', value: '-month'},
        {
          key: 'last3Month',
          label: 'Ultimo 3 Mês',
          value: () => {
            let start = moment().startOf('month').subtract(3, 'months');
            let end = start.clone().add(2, 'months').endOf('month');

            return {
              start: start,
              end: end
            }
          },
        },
        {key: 'thisYear', label: 'Este Ano', value: 'year'},
        {key: 'lastYear', label: 'Ultimo Ano', value: '-year'},
      ],
      datepicker: {
        minDate: minDate,
        maxDate: maxDate
      },
      showLimitExceeded: false,
      loading: false,
      form: {
        date: moment().format('YYYY-MM'),
        format: 'csv',
      },
      multiselect: {
        institutions: {
          options: [],
          selected: [],
          debouncing: null,
          loading: false
        }
      }
    }
  },
  methods: {
    async submit() {
      this.showLimitExceeded = false;
      this.loading = true
      let payload = {
        date_start: this.form.date.start,
        date_end: this.form.date.end === null ? this.form.date.start : this.form.date.end,
        institution_origin_ids: this.multiselect.institutions.selected.map(item => item.id),
      }

      this.loading = true

      try {
        const response = await RelatorioProvider.reportFormat02ErpMoneyBankCsv(payload);

        let fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'text/csv'}));
        let fileLink = document.createElement('a');

        let fileName = `relatorio-erp-dinheiro-banco-${payload.date_start}-${payload.date_end}.${this.form.format}`;

        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
        fileLink.remove();

      } catch (e) {
        const response = e.response
        // console.log(e.response.data);
        // console.log(e.response.status);
        // console.log(e.response.headers);

        if (response.status === 422) {
          const blob = e.response.data
          const data = JSON.parse(await blob.text());
          // console.log(JSON.stringify(data));
          // if (data.message === 'The given data was invalid.') {

          if (data.errors.count !== undefined) {
            // let message = data.errors.count[0];
            // if(message === )
            // let matches = message.match(/The count may not be greater than (\d+)\./)[1];
            // let limit = matches[1];

            this.showLimitExceeded = true;
            // alert('limite maximo atingido');
            // }

          }
        }

      } finally {
        this.loading = false
      }

      // RelatorioProvider.reportMonth(payload)
      //     .then(res => {
      //       let fileURL = window.URL.createObjectURL(new Blob([res.data], {type: 'text/csv'}));
      //       let fileLink = document.createElement('a');
      //
      //       let fileName = `relatorio-erp-dinheiro-banco-${payload.date_start}-${payload.date_end}.${this.form.format}`;
      //
      //       fileLink.href = fileURL;
      //       fileLink.setAttribute('download', fileName);
      //       document.body.appendChild(fileLink);
      //
      //       fileLink.click();
      //       fileLink.remove();
      //     })
      //     .finally(() => {
      //       this.loading = false
      //     })
    },
    multiselectInstitutionPreLoad() {
      let self = this

      InstitutionsProvider.get({pagination: {limit: 5, page: 1}})
        .then(res => {
          self.multiselect.institutions.options = res.data
        })
    },
    asyncFind: function (query) {
      let self = this
      clearTimeout(self.multiselect.institutions.debouncing)
      /** Prevent white space requests **/
      if (query.match(/^\s*$/)) {
        return
      }

      if (query === null || query === '') {
        return
      }

      let callback = () => {
        self.multiselect.institutions.loading = true
        InstitutionsProvider
          .getFiltered(query)
          .then(res => {
            let options = _.clone(self.multiselect.institutions.options)
            self.multiselect.institutions.options = _.uniqBy(options.concat(res.data), (e) => {
              return e.id
            })
          })
          .finally(() => {
            self.multiselect.institutions.loading = false
          })
      }

      self.multiselect.institutions.debouncing = setTimeout(callback, 500)
    },
  },
  mounted() {
    this.multiselectInstitutionPreLoad()
  }
}
</script>
