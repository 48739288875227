/* eslint-disable no-template-curly-in-string */
import {Provider} from '/src/parents/provider'

class InstitutionsProvider extends Provider {
  get(payload) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/institutions?limit=${payload.pagination.limit}&page=${payload.pagination.page}`)
  }

  get2(payload) {
    let url = `${process.env.VUE_APP_API_URL}/v1/institutions-v2/?limit=100&institution_type_id=${payload.institution_type_id}`

    // que porcaria
    if (payload.parent_id) {
      url += `&parent_id=${payload.parent_id}`
    }

    return this.doRequest('GET', url)
  }


  getFull() {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/institutions?limit=0`)
  }

  getByDate(payload) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/transactions-erps/vision-institutions/${payload.date}`)
  }

  getFiltered(query) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/institutions?limit=5&page=1&where=f:=name;t:=string;o:=like;v:=$${query}$`)
  }

  getStrongboxFiltered(payload) {
    let url = `${process.env.VUE_APP_API_URL}/v1/strongbox-combobox?limit=${payload.pagination.limit}&page=${payload.pagination.page}`

    if (payload.id) {
      url += `&id=${payload.id}`
    } else if (payload.search) {
      url += `&search=${payload.search}`
    }

    return this.doRequest('GET', url)
  }

  getStrongboxes(id) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/institutions/${id}/strongboxes`)
  }

  findById(id) {
    return this.doRequest('GET', `${process.env.VUE_APP_API_URL}/v1/institutions/${id}`)
  }

  create(data) {
    return this.doRequest('POST', `${process.env.VUE_APP_API_URL}/v1/institutions/create`, data);
  }

  update(data) {
    return this.doRequest('PUT', `${process.env.VUE_APP_API_URL}/v1/institutions`, data);
  }
}

export default new InstitutionsProvider()
