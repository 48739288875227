import view from './components/view'
import registerInstitution from './components/register/institution-components/Index'
import institutionList from './components/register/institution-components/list'
import InstitutionAdd from './components/register/institution-components/InstitutionAdd'
import InstitutionEdit from './components/register/institution-components/InstitutionEdit'
import others from './components/general/others'
import instVision from './components/general/institutions-vision'
import transactions from './components/general/transactions'

export default [
  {
    path: '/settings',
    name: 'settings',
    component: view,
    children: [
      {
        path: 'institution',
        // name: 'register-institution',
        component: registerInstitution,
        children: [
          {
            path: '',
            name: 'settings-institution-index',
            component: institutionList
          },
          {
            path: 'add',
            component: InstitutionAdd,
            name: 'settings-institution-add',
          },
          {
            path: ':id/edit',
            component: InstitutionEdit,
            name: 'settings-institution-edit',
          }
        ]
      },
      {
        path: 'general/others',
        name: 'general-others',
        component: others
      },
      {
        path: 'general/vision-store',
        name: 'general-vision-store',
        component: instVision
      },
      {
        path: 'general/transactions',
        name: 'settings-general-transactions',
        component: transactions
      }
    ]

  }
]
