export default {
  SET_INSTITUTIONS(state, payload) {
    state.institutions = payload
  },

  APPEND_INSTITUTIONS(state, payload) {
    state.institutions.data = state.institutions.data.concat(payload.data)
    state.institutions.meta = payload.meta
  },

  SET_BANKS(state, payload) {
    state.banks = payload
  },

  SET_TRANSPORTERS(state, payload) {
    state.transporters = payload
  }
}
